/*!
 * Start Bootstrap - Agency Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

//Importando Jquery
//import $ from 'jquery';

// jQuery for page scrolling feature - requires jQuery Easing plugin
/*$(function() {
    $('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);
		
		var res = $anchor.attr('href').replace(/\//g, "");

		console.log(res);
		
        $('html, body').stop().animate({
            scrollTop: $(res).offset().top
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });
});*/

// Highlight the top nav as scrolling occurs El Mio
let Dimension = (element) => {
    var elmHeight, 
		elmMargin, 
		elm = element;
    if(document.all) {// IE
        elmHeight = parseInt(elm.currentStyle.height, 10);
        elmMargin = parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10);
    } else {// Mozilla
        elmHeight = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('height'), 10);
        elmMargin = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top'), 10) + parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom'), 10);
    }
    return (elmHeight+elmMargin);
}

( () => {
	
	window.addEventListener('scroll', function() {
		
		var WindowTop = this.scrollY;
		
		document.querySelectorAll('.section').forEach( (This) => {
		  	if(WindowTop > This.offsetTop - 50&& 
               WindowTop < This.offsetTop + Dimension(This)
            ){
				
				var idSection = This.getAttribute('id');
				
				document.querySelectorAll('a.nav-link').forEach( (This) => {
				  if(`/#${idSection}` === This.getAttribute('href')){
						This.classList.add('active')
					}else{
						This.classList.remove('active')
					}
				});
			}
		});
	})
	
})();

/*$(function() {
	// Closes the Responsive Menu on Menu Item Click
	$('.navbar-nav>li>a').on('click', function(){
		$('.navbar-collapse').collapse('hide');
	});
	
	// get current URL path and assign 'active' class
	/*var pathname = window.location.pathname;
	$('.nav > li > a[href="'+pathname+'"]').parent().addClass('active');*/
	
//});