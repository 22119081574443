import React from 'react';

import LeftSideNavbar from './containers/LeftSideNavbar.jsx';
import LeftSideToggler from './containers/LeftSideToggler.jsx';
import TopNavbar from './containers/TopNavbar.jsx';

import { NavLink } from 'react-router-dom';

import './css/Navbar-colors.min.css';
import './css/Navbar-fixed.min.css';
import './css/Navbar-global.min.css';
import './css/Navbar-static.min.css';
import './css/Navbar-toggle.min.css';
import './font-awesome/css/font-awesome.min.css'
//import '../../js/sb-admin.js'

let Navbar = props => {
  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top" id="mainNav">
      {/* Navigation*/}
      <NavLink exact to="/dashboard" activeClassName="active" className="nav-link active">
        <img src="/img/svg/Logo.svg" style={{height: '2.5em', paddingLeft: '25px'}} alt="logoimg"/>
      </NavLink>
      <button className="navbar-toggler x collapsed" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="sr-only">Toggle navigation</span>
      </button>
      {/*<button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>*/}
      <div className="collapse navbar-collapse justify-content-center justify-content-md-end" id="navbarResponsive">

        <LeftSideNavbar />

        <LeftSideToggler />

        <TopNavbar />

      </div>
    </nav>
  )
}

export default Navbar
