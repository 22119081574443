let consulta = (usuarioOEmail, password) => {
  let string = `{ auth{
    logOut {
      conexion
      usuario {
        id
      }
    }
 } }`

  return {
    "query": string
  }

}

export default consulta;
