let consulta = (data) => {

    let respuestas = JSON.stringify(data.respuestas).replace(/{"/g, '{').replace(/":/g, ':').replace(/,"/g, ',')

    let string = `mutation{
      insertRespuestasEncuesta(idClima: "${data.idClima}", token: "${data.token}", respuestas: ${respuestas}){
        id
        idPregunta
        respuesta
      }
    }`
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;