import React from 'react';

import './css/QuienesSomos.css';

let QuienesSomos = props => (
	<div id="quienes-somos" className="col-12 section">
		<h2 className="text-center">Quienes Somos</h2>
		<p className="text-justify">
			BL Consultores es una firma de asesoría y consultoría, que desde 1993 se especializa en Desarrollo Organizacional promoviendo: el desarrollo de talento y el cambio cultural de las actitudes y los valores, mediante la sistematización de los procesos de las empresas nacionales e internacionales, públicas y privadas de los sectores agro/industrial, comercial y de servicios. Los equipos multidisciplinarios de BL Consultores, aseguran la satisfacción de los clientes con métodos orientados a resultados y atención personalizada.
			<br/><br/>
			BL Consultores orienta sus servicios a la alta dirección de las empresas, para lograr el éxito y la efectividad organizacional. con la implantación de prácticas, metodologías, herramientas innovadoras y estrategias de profesionalización y tecnificación del capital humano que contribuyan a la formación de las ventajas competitivas de las empresas.
			<br/><br/>
			En los procesos de asesoría y consultoría, BL Consultores proporciona seguimiento continuo con enfoque multicultural, mediante la prestación de sus servicios con confidencialidad, profesionalidad, honestidad, imparcialidad e integridad.
		</p>
	</div>
)

export default QuienesSomos