import React, { Component } from 'react';

//Impotando Component
import ServiciosLayOut from '../Components/ServiciosLayOut.jsx';

//importando Librerías Javascript
import { jarallax, jarallaxElement, jarallaxVideo } from '../../../../MyModules/jarallax-1.10.4'; //Pasar Jarallax a MyModules

import ScrollReveal from 'scrollreveal';

import { NavLink } from 'react-router-dom';

//import withScrollReveal from 'react-scrollreveal/dist-es6/index.js';

//Importando Librerías CSS
import 'animate.css';
import '../../../../MyModules/anicollection.css';

class Servicios extends Component {

	componentDidMount() {
		jarallaxVideo();
		jarallaxElement();

		jarallax(document.querySelectorAll('#servicios'), {
			speed: 0.2
		});

		ScrollReveal().reveal('.animate-service', {
			distance: '150px',
			origin: 'bottom',
			reset: true,
			delay: 1000,
			duration: 1000
		});
	}

	render(){
		return(
			<ServiciosLayOut>
				<div className="row">
					<div className="col-7 col-sm-4 col-md-3 col-lg-2 mx-auto animate-service">
						<img src="img/svg/servicios/enterprise.svg" className="img-responsive col-11 col-offset-1 col-md-12" alt="Desarrollo Organizacional"/>
						<h3 className="text-center">Desarrollo Organizacional</h3>
						<div className="col-12">
							<NavLink to="/desarrollo-organizacional" className="btn btn-outline-light text center col-12">Ver Más</NavLink>
						</div>
					</div>
					<div className="col-7 col-sm-4 col-md-3 col-lg-2 mx-auto animate-service">
						<img src="img/svg/servicios/presentation.svg" className="img-responsive col-11 col-offset-1 col-md-12" alt="Desarrollo Organizacional"/>
						{/*<h3 className="text-center">Capacitación</h3>*/}
						<h3 className="text-center">Formación, Capacitación y Adiestramiento</h3>
						<div className="col-12">
							<NavLink to="/formacion-capacitacion-y-adiestramiento" className="btn btn-outline-light text center col-12">Ver Más</NavLink>
						</div>
					</div>
					<div className="col-7 col-sm-4 col-md-3 col-lg-2 mx-auto animate-service">
						<img src="img/svg/servicios/recruitment.svg" className="img-responsive col-11 col-offset-1 col-md-12" alt="Desarrollo Organizacional"/>
						<h3 className="text-center">Proceso de Selección</h3>
						<div className="col-12">
							<NavLink to="/proceso-de-seleccion" className="btn btn-outline-light text center col-12">Ver Más</NavLink>
						</div>
					</div>
				</div>
				<div className="overlay"></div>
			</ServiciosLayOut>
		)
	}
}

export default Servicios
