// JavaScript Document
import GraphQL from './ConsultaGraphQLGetIndicadoresDeMedicionSatisfaccionGeneral';

import url from '../../../../../ApiService/Url';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchClimas = (setIndicadoresDeMedicionConRespuestasYPorentaje, idClima, loading, setLoading) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(idClima)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		setIndicadoresDeMedicionConRespuestasYPorentaje(res.data.appInfo.getIndicadoresDeMedicionSatisfaccionGeneral)
		if(loading){
			setLoading(false)
		}

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchClimas;