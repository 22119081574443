import React, { useState } from 'react';

import Toggle from 'react-toggle';
import "react-toggle/style.css";

import FetchGraphQLChangeEncuestaActivaClima from  '../Containers/FetchChangeEncuestaActivaClima/FetchGraphQLChangeEncuestaActivaClima';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let EditarClima = props => {

    let [ encuestaActiva, setEncuestaActiva ] = useState(props.climaOrganizacional.encuestaActiva)

    let [ mensajeEncuestaActiva, setMensajeEncuestaActiva ] = useState(null)

    let handleChangeEncuestaActiva = () => {

        let data = {
            idClima: props.climaOrganizacional.id,
            encuestaActiva: !encuestaActiva
        }

        FetchGraphQLChangeEncuestaActivaClima(setEncuestaActiva, setMensajeEncuestaActiva, data)

    }

    return(
        <div className="col-12 text-center">
            <div className="row">
                <button style={{backgroundColor: "transparent", border: 'none'}} onClick={ () => {
                    props.changeDisplay("TableClima")
                }}>
                    <FontAwesomeIcon icon="arrow-left" style={{color: '#EABF2D', fontSize: '18px'}} />
                </button>
            </div>

            <div className="row">
                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                    props.changeDisplay("InvitacionDeEncuesta", props.climaOrganizacional)
                }}>Enviar Invitacion de Encuesta <FontAwesomeIcon icon="envelope-open-text"/></button>

                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                    props.changeDisplay("TableEncuestados", props.climaOrganizacional)
                }}>Ver Las Encuestas del Clima <FontAwesomeIcon icon="list"/></button>

                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                    props.changeDisplay("GraficasClima", props.climaOrganizacional)
                }}>Ver Graficas del Clima <FontAwesomeIcon icon="chart-bar"/></button>
            </div>
            
            <div className="row mt-3">
                <div className="col-12">
                    <label htmlFor='encuestaActiva'>Encuesta Activa </label> <Toggle
                    id='encuestaActiva'
                    checked={encuestaActiva}
                    value={encuestaActiva === true ? 'true' : 'false'}
                    onChange={handleChangeEncuestaActiva} />
                </div>
                {
                    ((mensajeEncuestaActiva !== null) && (mensajeEncuestaActiva.response === 'success')) ? (
                        <div className="alert alert-success text-center mx-auto p-2 mt-2" role="alert">
                            {mensajeEncuestaActiva.mensaje}
                        </div>
                    ) : null
                }

                {
                    ((mensajeEncuestaActiva !== null) && (mensajeEncuestaActiva.response === 'failure')) ? (
                        <div className="alert alert-danger text-center mx-auto p-2 mt-2" role="alert">
                            {mensajeEncuestaActiva.mensaje}
                        </div>
                    ) : null
                }

            </div>

        </div>
    )
}

export default EditarClima