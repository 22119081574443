import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Popup from 'react-popup';

import 'react-popup/style.css';

let EncuestaSmile = props => {

    let [respuestas, setRespuestas] = useState([])

    useEffect( () => {

        let respuestas = props.preguntas.map( pregunta => ({
            idPregunta: pregunta.id,
            respuesta: ""
            //respuesta: pregunta.positiva === true ? "0" : "3"
        }))

        setRespuestas(respuestas)

    }, [ props.preguntas ])

    let onInputChange = (idPregunta, value) => {

        let newRespuestas = [
            ...respuestas
        ]

        let respuestaFiltered = newRespuestas.filter( respuesta => respuesta.idPregunta === idPregunta )[0]

        respuestaFiltered.respuesta = value

        newRespuestas.find(o => o.id === respuestaFiltered.id)

        setRespuestas(newRespuestas)

    }

    return(
        <div className="row mb-5">
            <div className="col-12">
                <p className="text-justify">A continuación, encontrará una serie de frases. Lea cada una de ellas con atención y seleccione la carita que mejor refleje su forma de pensar.<br/><br/>
                Si la frase es totalmente cierta, está totalmente de acuerdo con ella, SELECCIONE <FontAwesomeIcon icon="grin" style={{color: '#2CDEA3', fontSize: '25px'}} /><br/>
                Si la frase es frecuentemente cierta, está de acuerdo con ella, SELECCIONE <FontAwesomeIcon icon="smile" style={{color: '#F5E53E', fontSize: '25px'}} /><br/>
                Si la frase es frecuentemente falsa, está en desacuerdo con ella, SELECCIONE <FontAwesomeIcon icon="meh" style={{color: '#FAC132', fontSize: '25px'}} /><br/>
                Si la frase es completamente falsa en su caso, está totalmente en desacuerdo con ella, SELECCIONE <FontAwesomeIcon icon="frown" style={{color: '#DE3626', fontSize: '25px'}} /><br/>
                </p>
            </div>

            {
                props.preguntas.length > 0 ? (
                    <div className="table-responsive text-center">
                        <table className="table text-center table-bordered table-hover table-sm">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="d-none d-md-table-cell">No.</th>
                                    <th scope="col">Pregunta</th>
                                    <th scope="col">Respuestas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.preguntas.map( pregunta => (
                                        <tr key={pregunta.id}>
                                            <td className="d-none d-md-table-cell">{pregunta.orden}</td>
                                            <td>{pregunta.pregunta}</td>
                                            {
                                                pregunta.positiva === true ? (
                                                    <td>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`TotalmenteDeAcuerdo-${pregunta.id}`} value="0" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`TotalmenteDeAcuerdo-${pregunta.id}`}><FontAwesomeIcon icon="grin" style={{color: '#2CDEA3', fontSize: '20px'}} /></label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`DeAcuerdo-${pregunta.id}`} value="1" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`DeAcuerdo-${pregunta.id}`}><FontAwesomeIcon icon="smile" style={{color: '#F5E53E', fontSize: '20px'}} /></label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`EnDesacuerdo-${pregunta.id}`} value="2" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`EnDesacuerdo-${pregunta.id}`}><FontAwesomeIcon icon="meh" style={{color: '#FAC132', fontSize: '20px'}} /></label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`TotalmenteEnDesacuerdo-${pregunta.id}`} value="3" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`TotalmenteEnDesacuerdo-${pregunta.id}`}><FontAwesomeIcon icon="frown" style={{color: '#DE3626', fontSize: '20px'}} /></label>
                                                        </div>
                                                        {
                                                            ((pregunta.NoAplica !== null) && (pregunta.NoAplica === true)) ? (
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`TotalmenteEnDesacuerdo-${pregunta.id}`} value="4" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                                    <label className="form-check-label" htmlFor={`TotalmenteEnDesacuerdo-${pregunta.id}`}>No Aplica</label>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`TotalmenteEnDesacuerdo-${pregunta.id}`} value="0" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                                <label className="form-check-label" htmlFor={`TotalmenteEnDesacuerdo-${pregunta.id}`}><FontAwesomeIcon icon="frown" style={{color: '#DE3626', fontSize: '20px'}} /></label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`EnDesacuerdo-${pregunta.id}`} value="1" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`EnDesacuerdo-${pregunta.id}`}><FontAwesomeIcon icon="meh" style={{color: '#FAC132', fontSize: '20px'}} /></label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`DeAcuerdo-${pregunta.id}`} value="2" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`DeAcuerdo-${pregunta.id}`}><FontAwesomeIcon icon="smile" style={{color: '#F5E53E', fontSize: '20px'}} /></label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`TotalmenteDeAcuerdo-${pregunta.id}`} value="3" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`TotalmenteDeAcuerdo-${pregunta.id}`}><FontAwesomeIcon icon="grin" style={{color: '#2CDEA3', fontSize: '20px'}} /></label>
                                                        </div>
                                                        {
                                                            ((pregunta.NoAplica !== null) && (pregunta.NoAplica === true)) ? (
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`TotalmenteEnDesacuerdo-${pregunta.id}`} value="4" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                                    <label className="form-check-label" htmlFor={`TotalmenteEnDesacuerdo-${pregunta.id}`}>No Aplica</label>
                                                                </div>
                                                            ) : null
                                                        }
                                                        {/*<div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`TotalmenteDeAcuerdo-${pregunta.id}`} value="3" defaultChecked onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`TotalmenteDeAcuerdo-${pregunta.id}`}><FontAwesomeIcon icon="grin" style={{color: '#2CDEA3', fontSize: '20px'}} /></label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`DeAcuerdo-${pregunta.id}`} value="2" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`DeAcuerdo-${pregunta.id}`}><FontAwesomeIcon icon="smile" style={{color: '#F5E53E', fontSize: '20px'}} /></label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`EnDesacuerdo-${pregunta.id}`} value="1" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`EnDesacuerdo-${pregunta.id}`}><FontAwesomeIcon icon="meh" style={{color: '#FAC132', fontSize: '20px'}} /></label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`TotalmenteEnDesacuerdo-${pregunta.id}`} value="0" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                                <label className="form-check-label" htmlFor={`TotalmenteEnDesacuerdo-${pregunta.id}`}><FontAwesomeIcon icon="frown" style={{color: '#DE3626', fontSize: '20px'}} /></label>
                                                        </div>*/}
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <button className="btn btn-outline-info mb-2" onClick={() => {
                            Popup.create({
                                title: 'Guardar Respuestas',
                                content: '¿Desea Guardar las Respuestas?',
                                buttons: {
                                    left: [{
                                        text: 'Cancelar',
                                        className: 'danger',
                                        action: function () {
                                            /** Close this popup. Close will always close the current visible one, if one is visible */
                                            Popup.close();
                                        }
                                    }],
                                    right: [{
                                        text: 'Guardar',
                                        className: 'success',
                                        action: function () {
                                            props.onSaveRespuestas(respuestas, 'EncuestaSmile', () => {

                                                document.querySelector("#alert-smile").classList.remove("d-none")
                    
                                                setTimeout( () => { 
                                                    document.querySelector("#alert-smile").classList.add("d-none") 
                                                }, 3000);
                    
                                            })
                                            /** Close this popup. Close will always close the current visible one, if one is visible */
                                            Popup.close();
                                        }
                                    }]
                                }
                            })
                        }}>Guardar Respuestas</button>
                        <Popup />
                    </div>
                ) : null
            }

            <div className="col-12 mt-2">
                <div id="alert-smile" className="alert alert-info text-center d-none" role="alert">
                    Tienes que contestar todas las preguntas.
                </div>
            </div>

            <div className="col-12">
                <p className="text-center">Página {props.objectPagination.currentPage} de {props.objectPagination.totalPages}</p>
            </div>

        </div>
    )

}

export default EncuestaSmile