//AnimatedHeader

let AnimatedHeader = (id, class1, class2, class3, class4, class5)=> {

	let scroll = window.scrollY,
		height = window.innerHeight
	
	if(scroll >= height-76){
		
		//Agregar y qutar classes para Navbar Menu
		document.querySelector(`#${id}`).classList.add(class2);
		document.querySelector(`#${id}`).classList.remove(class1, class3);
		
		//Agregar y qutar classes para Navbar SubMenu
		document.querySelector(`.${class4}`).classList.remove(class5);
		
	}else{
		
		//Agregar y qutar classes para Navbar Menu
		document.querySelector(`#${id}`).classList.add(class1, class3);
		document.querySelector(`#${id}`).classList.remove(class2);
		
		//Agregar y qutar classes para Navbar SubMenu
		document.querySelector(`.${class4}`).classList.add(class5);
		
	}	

}

export default AnimatedHeader