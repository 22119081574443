let consulta = (idClima) => {
    let string = `{
      appInfo{
        climaOrganizacional(idClima: "${idClima}"){
          dataProcesada{
            porcentajeSatisfaccionGeneral
            satisfaccionGeneralGeneral{
              data
              nombre
              labels
            }
            satisfaccionGeneral{
              data
              nombre
              labels
            }
            satisfaccionPorAreasDeAnalisis{
              data
              nombre
              labels
            }
            satisfaccionGeneralPorAreasDeTrabajo{
              nombre
              porcentaje
            }
            satisfaccionPorAreasDeTrabajo{
              data
              nombre
              labels
            }
            satisfaccionGeneralPorGruposDeOpinion{
              nombre
              porcentaje
            }
            satisfaccionPorGruposDeOpinion{
              data
              nombre
              labels
            }
            cantidadDeEncuestadosPorAntiguedad{
              data
              nombre
              labels
            }
            satisfaccionGeneralAntiguedad{
              data
              nombre
              labels
            }
            satisfaccionGeneralGeneralAntiguedad{
              data
              nombre
              labels
            }
          }
        }
      }
    }`
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;