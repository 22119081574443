const cuestionarioInitialState = {
    activeParteCuestionario: null,
    preguntasActivas: []
},
cuestionarioReducer = (state, action) => {
    
    switch(action.type){
        case 'SET_ACTIVE_PARTE_CUESTIONARIO': {

            let activeParteCuestionario = action.payload.activeParteCuestionario,
                newState = {
                    ...state,
                    activeParteCuestionario
                }

            return newState

        }
        case 'SET_PREGUNTAS_ACTIVAS': {

            let preguntasActivas = action.payload.preguntasActivas,
                newState = {
                    ...state,
                    preguntasActivas
                }

            return newState

        }
        //break;
        default:
            return state

    }
}

export { cuestionarioInitialState, cuestionarioReducer }