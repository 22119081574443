import React from 'react';
import './css/Modal.css';

let Modal = props => (

	props.data.map( item => (
			<div key={item.id} className="modal fade" id={item.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
			  <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
				<div className="modal-content">
				  <div className="modal-header">
					{/*<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>*/}
					<button type="button" className="close" data-dismiss="modal" aria-label="Close">
					  <span aria-hidden="true">&times;</span>
					</button>
				  </div>
				  <div className="modal-body">
				  	<div className="container-fluid">
				  		<div className="row">
							<div className="col-12">
								<h1 className="text-center">{item.titulo}</h1>
							</div>
							<div className="col-12">
								<p dangerouslySetInnerHTML={{ __html: item.texto }} className="text-justify"/>
							</div>
						</div>
				  	</div>
				  </div>
				  <div className="modal-footer">
					<button type="button" className="btn mx-auto" data-dismiss="modal">Cerrar</button>
				  </div>
				</div>
			  </div>
			</div>
		)
	)
	
)

export default Modal