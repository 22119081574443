import React from 'react';

import { Bar } from 'chartjs-2-react';

let SatisfaccionGeneralGruposDeOpinion = props => {

    const dataClimaActual = props.dataProcesadaActual ? {
        labels: [...props.dataProcesadaActual.satisfaccionGeneralPorAreasDeTrabajo.map( areaDeTrabajo => areaDeTrabajo.nombre )],
        data: [...props.dataProcesadaActual.satisfaccionGeneralPorAreasDeTrabajo.map( areaDeTrabajo => areaDeTrabajo.porcentaje )]
    } : {
        labels: [...props.areasDeTrabajoConRespuestasYPorentajeActual.map( areaDeTrabajo => areaDeTrabajo.nombre )],
        data: [...props.areasDeTrabajoConRespuestasYPorentajeActual.map(areaDeTrabajo => (areaDeTrabajo.porcentajeSatisfaccionAreaDeTrabajo * 100).toFixed(2))]
    }

    const dataClimaComparado = props.dataProcesadaComparado ? {
        labels: [...props.dataProcesadaComparado.satisfaccionGeneralPorAreasDeTrabajo.map( areaDeTrabajo => areaDeTrabajo.nombre )],
        data: props.dataProcesadaActual ? [...props.dataProcesadaActual.satisfaccionGeneralPorAreasDeTrabajo.map(area => {
            const areaDeTrabajo = props.dataProcesadaComparado.satisfaccionGeneralPorAreasDeTrabajo.find( areaDeTrabajo => areaDeTrabajo.nombre === area.nombre)
            return areaDeTrabajo ? areaDeTrabajo.porcentaje : null
        })] : [...props.areasDeTrabajoConRespuestasYPorentajeActual.map(area => {
            const areaDeTrabajo = props.dataProcesadaComparado.satisfaccionGeneralPorAreasDeTrabajo.find( areaDeTrabajo => areaDeTrabajo.nombre === area.nombre)
            return areaDeTrabajo ? areaDeTrabajo.porcentaje : null
        })]
    } : {
        labels: [...props.areasDeTrabajoConRespuestasYPorentajeComparado.map( areaDeTrabajo => areaDeTrabajo.nombre )],
        data1: [...props.areasDeTrabajoConRespuestasYPorentajeComparado.map(areaDeTrabajo => (areaDeTrabajo.porcentajeSatisfaccionAreaDeTrabajo * 100).toFixed(2))],
        data: props.dataProcesadaActual ? [...props.dataProcesadaActual.satisfaccionGeneralPorAreasDeTrabajo.map(area => {
            const areaDeTrabajo = props.areasDeTrabajoConRespuestasYPorentajeComparado.find( areaDeTrabajo => areaDeTrabajo.nombre === area.nombre)
            return areaDeTrabajo ? (areaDeTrabajo.porcentajeSatisfaccionAreaDeTrabajo * 100).toFixed(2) : null
        })] : [...props.areasDeTrabajoConRespuestasYPorentajeActual.map(area => {
            const areaDeTrabajo = props.areasDeTrabajoConRespuestasYPorentajeComparado.find( areaDeTrabajo => areaDeTrabajo.nombre === area.nombre)
            return areaDeTrabajo ? (areaDeTrabajo.porcentajeSatisfaccionAreaDeTrabajo * 100).toFixed(2) : null
        })]
    }

    return(
        <div className="col-12">
            <h2 className="text-center">Gráfica General de Resultados de Satisfacción Por Áreas de Trabajo Comparativa</h2>
            <Bar config={{
                data: {
                    labels: dataClimaActual.labels,
                    datasets: [{
                        label: props.nombreClimaActual,
                        data: dataClimaActual.data,
                        backgroundColor: "#EABF2D",
                        borderColor: 'none'
                    },{
                        label: props.nombreClimaComparado,
                        data: dataClimaComparado.data,
                        backgroundColor: "#E88D46",
                        borderColor: 'none'
                    }],
                },
                options: {
                    title: {
                        display: true,
                        text: "Porcentajes de Satisfacción %"
                    },
                    responsive: true,
                    legend: {
                        display: true
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                callback: (value, index, values) => {
                                    return value + '%'
                                },
                                beginAtZero: true,
                                max: 100
                            }
                        }]
                    },
                    animation: {
                        duration: 1,
                        onComplete: function() {
                            var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';
            
                            this.data.datasets.forEach(function(dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function(bar, index) {
                                var data = dataset.data[index];
                                ctx.fillText(data + '%', bar._model.x, bar._model.y - 5);
                            });
                        });
                        }
                    }
                }
            }}/>
        </div>
    )
}

export default SatisfaccionGeneralGruposDeOpinion