import React, { useState, useEffect } from 'react';

import FetchGraphQLGetPreguntasAbiertas from '../../../Containers/FetchGetPreguntasAbiertas/FetchGraphQLGetPreguntasAbiertas';

import Loader from '../../../../../Components/LoaderSinLogo';

import PreguntasAbiertas from './PreguntasAbiertas';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let AreasDeAnalisisComponent = props => {

    let [preguntasAbiertasPorGrupoDeOpinion, setpreguntasAbiertasPorGrupoDeOpinion] = useState([])

    useEffect( ()=> {
        FetchGraphQLGetPreguntasAbiertas(setpreguntasAbiertasPorGrupoDeOpinion, props.climaOrganizacional.id)
    }, [props])

    if(preguntasAbiertasPorGrupoDeOpinion.length > 0){
        return (
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-outline-warning mx-auto mb-5" onClick={ () => {
                        props.changeDisplay("SelectGraficas")
                    }}>Regresar al Menu de Graficas <FontAwesomeIcon icon="chart-bar"/></button>
                </div>
                <PreguntasAbiertas climaOrganizacional={props.climaOrganizacional} preguntasAbiertasPorGrupoDeOpinion={preguntasAbiertasPorGrupoDeOpinion}/>
            </div>
        )
    }else{
        return (
            <div className="row">
                <Loader />
                <div className="col-12">
                    <h3 className="text-center">Espera a que se calculen los datos.</h3>
                </div>
            </div>
        )
    }

}

export default AreasDeAnalisisComponent