const initialState = {
    dataInfo: {
      etiquetasPuestos: []
    } 
}
  
  let AppInfo = (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_DATA_INFO': {

          let dataInfo = action.payload.dataInfo
      
          let newState = {
            ...state,
            dataInfo
          }
      
          return newState
        }

        case 'INSERT_ETIQUETA_PUESTO': {

          let newPuesto = action.payload.etiquetaPuesto

          let etiquetasPuestos = state.dataInfo.etiquetasPuestos

          etiquetasPuestos.push(newPuesto)
      
          let newState = {
            ...state,
            dataInfo: {
              etiquetasPuestos
            }
          }
      
          return newState
          //return state
        }
        //break;
        default:
            return state
    }
  }
  
  export default AppInfo