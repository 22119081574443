import GraphQL from './ConsultaGraphQL';

import url from '../../../../../ApiService/Url.js';

// JavaScript Document
function handleError(err){
	console.log(`Request failed: ${err}`);
}

const updateUser = (This, NuevaEtiqueta) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(NuevaEtiqueta)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

        //console.log(res)
        if(res.data.insertEtiquetaPuesto !== null){
            This.props.dispatch({
                type: 'INSERT_ETIQUETA_PUESTO',
                payload: {
                    etiquetaPuesto: {
                        id: res.data.insertEtiquetaPuesto.id,
                        puesto: NuevaEtiqueta
                    }
                }
            })
        }
		
	})
	.catch(err => handleError(err));

	//termina fetch
}

export default updateUser;