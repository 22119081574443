import React, { Component } from 'react';

import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GraphQLFetchCVs from './FetchCVs/FetchCVsPromise.js';

import AdminComponent from '../../Components/AdminComponent.jsx';

import GraphQLFetchDeleteCV from './FetchDeleteCV/FetchDeleteCVPromise.js';

class BuscarCV extends Component {

    constructor(props) {
        super(props);
        // No llames this.setState() aquí!
        this.state = { 
            filter: "",
            limit: 25,
            cursor: 0,
            pag: 1,
            blockNext: false,
            deleteCVMensaje: null
        };

        this.handleInputBusquedaChange = this.handleInputBusquedaChange.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handlePrevPage = this.handlePrevPage.bind(this);
        this.handleDeleteCV = this.handleDeleteCV.bind(this);
    }

    async componentDidMount(){

        await GraphQLFetchCVs(this)

    }

    handleInputBusquedaChange(event) {
        this.setState({
            filter: event.target.value
        });
        GraphQLFetchCVs(this)
    }

    handleDeleteCV(CV) {
        GraphQLFetchDeleteCV(this, CV)
    }

    async handleNextPage(){

        let cursor

        let cursorFunction = () => {
            cursor = this.state.cursor + this.state.limit
            return cursor
        }

        await cursorFunction()

        this.setState({
            cursor,
            pag: this.state.pag + 1
        });

        GraphQLFetchCVs(this)

    }

    async handlePrevPage(){

        let cursor

        let cursorFunction = () => {
            cursor = this.state.cursor - this.state.limit
            return cursor
        }

        await cursorFunction()

        if(cursor < 0){
            this.setState({
                cursor: 0,
                pag: this.state.pag
            });
        }else{
            this.setState({
                cursor,
                pag: this.state.pag - 1
            });
        }
        

        await GraphQLFetchCVs(this)
        
    }

    render(){
        //console.log(this.state)
        return(
            <AdminComponent>
                <div className="container-fluid" style={{minHeight: '80vh', marginTop: '25px', marginBottom: '50px'}}>
                <div className="row">
                    <form className="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto">
                        <div className="form-group">
                            <label htmlFor="Buscador">Buscador</label>
                            <input type="text" className="form-control" id="Buscador" aria-describedby="buscadorHelp" placeholder="Ingresa un texto" value={this.state.filter} onChange={this.handleInputBusquedaChange} />
                            <small id="buscadorHelp" className="form-text text-muted">Un campo para buscar CVs, puedes buscar por nombres, apellidos, puestos o email.</small>
                        </div>
                    </form>
                </div>
                {
                    this.props.CVs === null ? (
                        <div className="row">
                            <div className="mx-auto col-12 col-md-8 col-lg-6">
                                <h2>No Hay Registros</h2>
                            </div>
                        </div>
                    ):(  
                        <div className="table-responsive">
                            <table className="table text-center table-bordered table-hover table-sm">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" className="d-none d-md-table-cell" >Id de CV</th>
                                        <th scope="col">Nombres de Persona</th>
                                        <th scope="col" >Apellidos de Persona</th>
                                        <th scope="col" className="d-none d-lg-table-cell" >Puesto</th>
                                        <th scope="col" className="d-none d-sm-table-cell">Estado</th>
                                        <th scope="col">CV</th>
                                        {/*<th scope="col">Acciones</th>*/}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.CVs.map( CV => {
                                            //let cvUrl = `http://p-lao.com:4000/api/cvs/${CV.cv}`
                                            let cvUrl = `https://blconsultores.com.gt/api/cvs/${CV.cv}`
                                            return(
                                                <tr key={CV.id}>
                                                    <td className="d-none d-md-table-cell">{CV.id}</td>
                                                    <td>{CV.primerNombre} {CV.segundoNombre}</td>
                                                    <td>{CV.primerApellido} {CV.segundoApellido}</td>
                                                    <td className="d-none d-lg-table-cell">{CV.etiquetasPuestos.toString()}</td>
                                                    <td className="d-none d-sm-table-cell">{CV.estado}</td>
                                                    <td>
                                                        <button onClick={() => {
                                                            // const downloadLink = document.createElement("a");
                                                            // const fileName = `CV_${CV.primerNombre}_${CV.primerApellido}.pdf`;
                                                            // downloadLink.href = CV.cv;
                                                            // downloadLink.download = fileName;
                                                            // downloadLink.click();
                                                            window.open(CV.cv);
                                                        }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#EABF2D'}}><FontAwesomeIcon icon="eye" /></button>
                                                        <button onClick={ () => { this.handleDeleteCV(CV) } } type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#EABF2D'}}>
                                                                <FontAwesomeIcon icon="trash-alt" />
                                                        </button>
                                                    </td>
                                                    {/*<td>
    
                                                        <button type="button" onClick={() => {
                                                            let typeModal = 'ordenMensariaModalView';
                                                            props.handleOpenModal(solicitudMensajeria, typeModal)
                                                        }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                                                            <FontAwesomeIcon icon="eye" />
                                                        </button> {/*ref={props.setButtonViewUser}*/}

                                                        {/*<button type="button" onClick={() => {
                                                            props.handleChangeEstadoSolicitud(solicitudMensajeria)
                                                        }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                                                            <FontAwesomeIcon icon="sync-alt" />
                                                        </button>
                                        
                                                        {
                                                            (((props.usuario.tipo === 'colaborador') && (props.usuario.colaborador.puesto === 'Mensajero')) || (props.usuario.tipo === 'cliente')) ? null : (
                                                            <button type="button" onClick={() => {
                                                                let typeModal = 'ordenMensjeriaModalEdit';
                                                                props.handleOpenModal(solicitudMensajeria, typeModal)
                                                            }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#2984be'}}>
                                                                <FontAwesomeIcon icon="edit" />
                                                            </button>
                                                            )
                                                        }
                                        
                                        
                                                        {
                                                            ( props.usuario.tipo === 'progra' || ( (props.usuario.tipo === 'colaborador') && (props.usuario.colaborador.puesto === 'Director Administrativo') ) || ( (props.usuario.tipo === 'colaborador') && (props.usuario.colaborador.puesto === 'Director de Laboratorio') ) ) ? (
                                                            <button onClick={ () => {
                                                                props.handleDeleteOrdenMensajeria(solicitudMensajeria)
                                                            }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#2984be'}}>
                                                                <FontAwesomeIcon icon="trash-alt" />
                                                            </button>
                                                            ) : null
                                                        }
                                        
                                        
                                                    </td>*/}
                                                </tr> 
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    )
                }
                    <div className="row">
                        <div className="mx-auto">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                    {
                                        this.state.cursor === 0 ? (
                                            <button onClick={this.handlePrevPage} className="page-link" disabled>
                                                <span aria-hidden="true">&laquo;</span>
                                            </button>
                                        ) : (
                                            <button onClick={this.handlePrevPage} className="page-link" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </button>  
                                        )
                                    }
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#pag">{this.state.pag}</a></li>
                                    <li className="page-item">
                                    
                                    {
                                        this.state.blockNext === false ? (
                                            <button onClick={this.handleNextPage} className="page-link" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </button>
                                        ) : (
                                            <button onClick={this.handleNextPage} className="page-link" disabled>
                                                <span aria-hidden="true">&raquo;</span>
                                            </button>
                                        )
                                    }

                                    
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </AdminComponent>
        )
    }
}

let mapStateToProps = (state, props) => {
    return {
      conexion: state.logIn.conexion,
      usuario: state.logIn.usuario,
      CVs: state.CVs,
      etiquetasPuestos: state.AppInfo.dataInfo.etiquetasPuestos
    }
  }
  
  export default connect(mapStateToProps)(BuscarCV)

