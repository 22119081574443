import React, { Component } from 'react';

//Impotando Component
import ProcesoDeSeleccionLayOut from '../Components/ProcesoDeSeleccionLayOut.jsx';
import InfoCard from '../../InfoCard/InfoCard.jsx';
import Card from '../../Card/Card.jsx';
import Modal from '../../Modal/Modal.jsx';

import Json from './ProcesoDeSeleccion.json';

//Importando Librerías CSS
import 'animate.css';
import '../../../../../MyModules/anicollection.css';

let InfoCardText = {
	'titulo': 'PROCESO DE SELECCIÓN',
	'texto': 'La selección de personal representa un proceso clave e imprescindible dentro de las organizaciones, ya que los colaboradores de las organizaciones son su mejor diferenciador, en la gestión del negocio. Las estrategias de negocios las implementan las personas. Sin el talento adecuado difícilmente se puede liderar una organización hacia la misión pretendida de mantener una organización productiva con colaboradores de alta calidad.'
}

class DesarrolloOrganizacional extends Component {

	componentDidMount() {

		window.scrollTo(0, 0)
	}

	render(){
		return(
			<ProcesoDeSeleccionLayOut path={this.props.path}>
				<InfoCard data={InfoCardText} />
				<Card data={Json}/>
				<Modal data={Json}/>
			</ProcesoDeSeleccionLayOut>
		)
	}
}

export default DesarrolloOrganizacional
