let consulta = (idClima) => {
  
    let string = `{
      appInfo{
        getPreguntasAbiertas(idClima: "${idClima}"){
          id
          orden
          nombre
          encuestadosPlaneados
          preguntasAbiertas{
            id
            orden
            pregunta
            respuestas{
              id
              idPregunta
              respuesta
            }
          }
        }
      }
    }`
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;