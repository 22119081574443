import React, { useState, useEffect } from 'react';

import Popup from 'react-popup';

import 'react-popup/style.css';

let PreguntasAbiertas = props => {

    let [respuestas, setRespuestas] = useState([])

    useEffect( ()=> {

        let respuestas = props.preguntas.map( pregunta => {

            return {
                idPregunta: pregunta.id,
                respuesta: ""
            }

        })

        setRespuestas(respuestas)

    }, [props.preguntas, props.preguntaAbierta])

    let onInputChange = (idPregunta, value) => {

        let newRespuestas = [
            ...respuestas
        ]

        let respuestaFiltered = newRespuestas.filter( respuesta => respuesta.idPregunta === idPregunta )[0]

        respuestaFiltered.respuesta = value

        newRespuestas.find(o => o.id === respuestaFiltered.id)

        setRespuestas(newRespuestas)

    }

    //console.log(props.preguntas)

    return(
        <div className="row">
            <div className="col-12">
                <p className="text-justify">
                    {props.preguntaGeneral}
                </p>
            </div>

            {
                props.preguntas.length > 0 ? (
                    <div className="table-responsive text-center">
                        <table className="table text-center table-bordered table-hover table-sm">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="d-none d-md-table-cell">No.</th>
                                    <th scope="col">Pregunta</th>
                                    <th scope="col">Respuestas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.preguntas.map( pregunta => (
                                        <tr key={pregunta.id}>
                                            <td className="d-none d-md-table-cell">{pregunta.orden}</td>
                                            <td>{pregunta.pregunta}</td>
                                            <td>
                                                {
                                                    (respuestas.filter( respuesta => respuesta.idPregunta === pregunta.id)[0] !== undefined) ? (
                                                        <textarea className="form-control" value={respuestas.filter( respuesta => respuesta.idPregunta === pregunta.id)[0].respuesta} onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } placeholder="Escribe tu respuesta" />
                                                    ) : null
                                                } 
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <button className="btn btn-outline-info mb-5" onClick={() => {

                            Popup.create({
                                title: 'Guardar Respuestas',
                                content: '¿Desea Guardar las Respuestas?',
                                buttons: {
                                    left: [{
                                        text: 'Cancelar',
                                        className: 'danger',
                                        action: function () {
                                            /** Close this popup. Close will always close the current visible one, if one is visible */
                                            Popup.close();
                                        }
                                    }],
                                    right: [{
                                        text: 'Guardar',
                                        className: 'success',
                                        action: function () {
                                            props.onSaveRespuestas(respuestas)
                                            /** Close this popup. Close will always close the current visible one, if one is visible */
                                            Popup.close();
                                        }
                                    }]
                                }
                            })

                        }}>Guardar Respuestas</button>
                        <Popup />
                    </div>
                ) : null
            }

            <div className="col-12">
                <p className="text-center">Página {props.objectPagination.currentPage} de {props.objectPagination.totalPages}</p>
            </div>

        </div>
    )

}

export default PreguntasAbiertas