import React, { Component } from 'react';

import Loader from '../../../Components/LoaderSinLogo';

import FetchGraphQLInsertEncuesta from './FetchNewEncuesta/FetchGraphQLInsertEncuesta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "react-toggle/style.css"; // for ES6 modules

import Toggle from 'react-toggle';

class InvitacionEncuesta extends Component {

    constructor(props) {
        super(props);

        // No llames this.setState() aquí!
        this.state = { 
            idGrupoDeOpinion: this.props.climaOrganizacional.gruposDeOpinion[0].id,
            correos: "",
            loading: false,
            insertInvitacion: null,
            sendCorreo: true
        };
        
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChangeSendCorreo =  this.handleChangeSendCorreo.bind(this)
    }

    onInputChange(event){
        this.setState({
            [event.target.id]: event.target.value.split(' ').join('')
        })
    }

    handleChangeSendCorreo(){

        if(this.state.sendCorreo === false){
            this.setState({
                sendCorreo: true
            })
        }else{
            this.setState({
                sendCorreo: false,
                correos: ""
            })
        }

    }

    onSubmit(event){

        event.preventDefault()

        this.setState({
            loading: true
        })

        let data = {
            idClima: this.props.climaOrganizacional.id,
            idGrupoDeOpinion: this.state.idGrupoDeOpinion,
            correos: this.state.correos
        }

        FetchGraphQLInsertEncuesta(this, data)

    }

    render(){
        return(
            <div className="col-12 text-center">
                <div className="row">
                    <button style={{backgroundColor: "transparent", border: 'none'}} onClick={ () => {
                        this.props.changeDisplay("EditarClima", this.props.climaOrganizacional)
                    }}>
                        <FontAwesomeIcon icon="arrow-left" style={{color: '#EABF2D', fontSize: '18px'}} />
                    </button>
                </div>
                <h1>Enviar Invitacion para Realizar Encuesta</h1>
                <form className="form-row mx-auto text-center" onSubmit={this.onSubmit}>
                    
                    <div className="form-group col-12 col-md-6 col-lg-5 mx-auto">
                        <label htmlFor="idGrupoDeOpinion">Grupo de Opinión</label>
                        <select className="form-control" id="idGrupoDeOpinion" onChange={this.onInputChange} value={this.state.idGrupoDeOpinion}>
                            {
                                this.props.climaOrganizacional.gruposDeOpinion.map( grupoDeOpinion => <option key={grupoDeOpinion.id} value={grupoDeOpinion.id}>{grupoDeOpinion.nombre}</option>)
                            }
                        </select>
                        <small id="correoHelp" className="form-text text-muted">Selecciona el Grupo de Opinion</small>
                    </div>

                    <div className="form-group col-12 col-md-6 col-lg-5 mx-auto mt-4">
                        <Toggle
                        id='SendCorreo'
                        defaultChecked={this.state.sendCorreo}
                        onChange={this.handleChangeSendCorreo} />
                        <label htmlFor='cheese-status'> Enviar Correos</label>
                    </div>

                    {
                        this.state.sendCorreo === true ? (
                            <div className="form-group col-12 col-md-6 col-lg-5 mx-auto">
                                <label htmlFor="correos">Correos Electronicos</label>
                                <textarea className="form-control" id="correos" aria-describedby="correoHelp" onChange={this.onInputChange} placeholder="Escribe una lista de correos electrónicos separados por ;" value={this.state.correos}/>
                                <small id="correoHelp" className="form-text text-muted">Escribe una lista de correos electrónicos separados por ;</small>
                            </div>
                        ) : null
                    }  

                    {
                        this.state.loading === true ? (
                            <div className="col-12">
                                <Loader />
                            </div>
                        ) : null
                    }
                    {
                        this.state.loading === false ? (
                            <div className="col-12">
                                <button type="submit" className="btn btn-outline-warning">Enviar la Invitación</button>
                            </div>
                        ) : null
                    }
                    {
                        this.state.insertInvitacion !== null ? (
                            <div className="col-12">
                                {
                                (this.state.insertInvitacion.response === 'success') ? (
                                    <div className="alert alert-success mx-auto mt-3" role="alert">{this.state.insertInvitacion.mensaje}</div>
                                ) : (
                                    <div className="alert alert-danger mx-auto mt-3" role="alert">{this.state.insertInvitacion.mensaje}</div>
                                )
                                }
                            </div>
                        ) : null
                    }
                </form>
            </div>
        )
    }

}


export default InvitacionEncuesta