import React, { useState, useEffect } from 'react';

import FetchEncuestados from '../../../Containers/FetchGetAllEncuestados/FetchGraphQLEncuestados';

import FetchGraphQLGetAreasDeTrabajoSatisfaccionGeneral from '../../../Containers/FetchGetAreasDeTrabajoSatisfaccionGeneral/FetchGraphQLGetAreasDeTrabajoSatisfaccionGeneral';

import Loader from '../../../../../Components/LoaderSinLogo';

import AreasDeTrabajo from "./AreasDeTrabajo";
import SatisfaccionGeneral from './SatisfaccionGeneralAreasDeTrabajo';
import EncuestadosAreaDeTrabajo from './EncuestadosAreasDeTrabajo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let AreasDeAnalisisComponent = props => {

    let [encuestados, setEncuestados] = useState([])

    let [areasDeTrabajoConRespuestasYPorentaje, setAreasDeTrabajoConRespuestasYPorentaje] = useState([])

    useEffect( ()=> {
        FetchEncuestados(setEncuestados, props.climaOrganizacional.id)
        FetchGraphQLGetAreasDeTrabajoSatisfaccionGeneral(setAreasDeTrabajoConRespuestasYPorentaje, props.climaOrganizacional.id)
    }, [props])

    if(areasDeTrabajoConRespuestasYPorentaje.length > 0){
        return (
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-outline-warning mx-auto mb-5" onClick={ () => {
                        props.changeDisplay("SelectGraficas")
                    }}>Regresar al Menu de Graficas <FontAwesomeIcon icon="chart-bar"/></button>
                </div>
                <EncuestadosAreaDeTrabajo areasDeTrabajoConRespuestasYPorentaje={areasDeTrabajoConRespuestasYPorentaje} encuestados={encuestados} />
                <SatisfaccionGeneral areasDeTrabajoConRespuestasYPorentaje={areasDeTrabajoConRespuestasYPorentaje} />
                <AreasDeTrabajo areasDeTrabajoConRespuestasYPorentaje={areasDeTrabajoConRespuestasYPorentaje} />
            </div>
        )
    }else{
        return (
            <div className="row">
                <Loader />
                <div className="col-12">
                    <h3 className="text-center">Espera a que se calculen los datos.</h3>
                </div>
            </div>
        )
    }

}

export default AreasDeAnalisisComponent