import React, { useState, useEffect } from 'react';

import fetchEncuestados from '../Containers/FetchGetAllEncuestados/FetchGraphQLEncuestados';

import Loader from '../../../Components/LoaderSinLogo';

import fetchGraphQLSendCorreoEncuesta from '../Containers/FetchSendCorreoEncuesta/FetchGraphQLSendCorreoEncuesta';

import fetchGraphQLDeleteEncuesta from '../Containers/FetchChangeDeleteEncuesta/FetchGraphQLDeleteEncuesta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Popup from 'react-popup';

import 'react-popup/style.css';

let TableEncuestados = props => {

    let [encuestados, setEncuestados] = useState([])

    let [loading, setLoading] = useState(false)

    let [mensajeSendCorreo, setMensajeSendCorreo] = useState(null)

    let [idGrupoDeOpinion, setIdGrupoDeOpinion] = useState(props.climaOrganizacional.gruposDeOpinion[0].id)

    let handleSendCorreo = (idEncuesta, correo) => {

        let data = {
            idClima: props.climaOrganizacional.id,
            idEncuesta,
            correo
        }

        setLoading(true)

        fetchGraphQLSendCorreoEncuesta(setMensajeSendCorreo, setLoading, data)

    }

    useEffect( () => {

        fetchEncuestados(setEncuestados, props.climaOrganizacional.id)

    }, [props.climaOrganizacional.id ])

    if(encuestados.length > 0){

        let encuestadosFilter = encuestados.filter( encuestado => encuestado.idGrupoDeOpinion === idGrupoDeOpinion)

        let preguntas = props.climaOrganizacional.cuestionario.partesCuestionario.map( parte => {
            
            let preguntas = [...parte.preguntas]

            if(parte.preguntaAbierta !== null){
                preguntas.push(parte.preguntaAbierta)
            }

            return preguntas

        }).flat()

        return(
            <div className="table-responsive">
    
                <button style={{backgroundColor: "transparent", border: 'none'}} onClick={ () => {
                        props.changeDisplay("EditarClima")
                    }}>
                    <FontAwesomeIcon icon="arrow-left" style={{color: '#EABF2D', fontSize: '18px'}} />
                </button>
    
                <div className="form-group col-12 col-md-6 col-lg-5 mx-auto">
                    <label htmlFor="idGrupoDeOpinion">Grupo de Opinión</label>
                    <select className="form-control" id="idGrupoDeOpinion" onChange={(event) => setIdGrupoDeOpinion(event.target.value)} value={idGrupoDeOpinion}>
                        {
                            props.climaOrganizacional.gruposDeOpinion.map( grupoDeOpinion => <option key={grupoDeOpinion.id} value={grupoDeOpinion.id}>{grupoDeOpinion.nombre}</option>)
                        }
                    </select>
                    <small id="correoHelp" className="form-text text-muted">Selecciona el Grupo de Opinion</small>
                </div>

                <div className="col-12">
                    <h2 className="text-center">Id Grupo de Opinion: {idGrupoDeOpinion}</h2>
                </div>

                <div className="col-12">
                    <h4 className="text-center">URL de EncuestaClima: <a href={`https://blconsultores.com.gt/admin/clima-organizacional/encuesta/${props.climaOrganizacional.id}`} target="_blank" rel="noopener noreferrer">https://blconsultores.com.gt/admin/clima-organizacional/encuesta/{props.climaOrganizacional.id}</a></h4>
                </div>
    
                <table className="table text-center table-bordered table-hover table-sm">
                     <thead className="thead-light">
                        <tr>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Id de Encuestado</th>
                            <th scope="col">Correo</th>
                            <th scope="col">Envío de Correo</th>
                            <th scope="col">Encuesta Finalizada o Reenviar correo</th>
                            <th scope="col">Acciones</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                            encuestadosFilter.map( (encuestado, i) => (
                                <tr key={encuestado.id}>
                                    <td>{i + 1}</td>
                                    <td>{encuestado.id}</td>
                                    <td>{encuestado.correo !== null ? encuestado.correo.correo : "No Se envió por correo"}</td>
                                    <td>{encuestado.correo !== null ? (encuestado.correo.sendMail === true ? "Se Envió" : "Error al envío") : "No Se envió por correo"}</td>
                                    {
                                        encuestado.respuestas.length === preguntas.length ? (
                                            <td>
                                                <p style={{color: '#36EB32', fontSize: '25px'}}>
                                                    <FontAwesomeIcon icon="check" />
                                                </p>
                                            </td>
                                        ) : (
                                            <td>{encuestado.correo !== null ? (
                                                <button type="button" onClick={() => {
                                                    handleSendCorreo(encuestado.id, encuestado.correo.correo)
                                                }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#eabf2d'}}>
                                                <FontAwesomeIcon icon="envelope-open-text" />
                                                </button>
                                            ) : "No Se envió por correo"}
                                            {
                                                ((encuestado.respuestas.length > 0) && (encuestado.respuestas.length !== preguntas.length)) ? (
                                                    <p style={{color: '#eabf2d', fontSize: '25px'}}>
                                                        <FontAwesomeIcon icon="history" />
                                                    </p>
                                                ) : null
                                            }
                                            </td>
                                        )
                                    }
                                    <td>
                                        <button type="button" onClick={() => {
                                                    Popup.create({
                                                        title: 'Eliminar Encuesta',
                                                        content: '¿Desea Eliminar la Encuesta?',
                                                        buttons: {
                                                            left: [{
                                                                text: 'Cancelar',
                                                                className: 'danger',
                                                                action: function () {
                                                                    /** Close this popup. Close will always close the current visible one, if one is visible */
                                                                    Popup.close();
                                                                }
                                                            }],
                                                            right: [{
                                                                text: 'Eliminar',
                                                                className: 'success',
                                                                action: function () {
                                                                    fetchGraphQLDeleteEncuesta(setEncuestados, encuestados, {
                                                                        idClima: props.climaOrganizacional.id,
                                                                        idEncuesta: encuestado.id
                                                                    })
                                                                    /** Close this popup. Close will always close the current visible one, if one is visible */
                                                                    Popup.close();
                                                                }
                                                            }]
                                                        }
                                                    })
                                                }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color' : '#eabf2d'}}>
                                                <FontAwesomeIcon icon="trash" />
                                        </button>
                                        <Popup />
                                    </td>
                                </tr>
                            ))
                        }
                     </tbody>
                </table>

                {
                    mensajeSendCorreo !== null ? (
                        <div className="col-12">
                            <div class="alert alert-primary text-center" role="alert">
                                {mensajeSendCorreo}
                            </div>
                        </div>
                    ) : null
                }

                {
                    loading !== false ? <Loader /> : null
                }


            </div>
        )
    }else{
        return null
    }

}

export default TableEncuestados