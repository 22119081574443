const initialState = []
  
  let CVs = (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_CVS': {

          let dataCVs = action.payload.dataCVs
      
          let newState = dataCVs
      
          return newState
        }

        case 'DELETE_CV': {

          let cv = action.payload.dataCV
    
          let newCVs = state.filter( cvFilter => cvFilter.id !== cv.id)
    
          let newState = newCVs
    
          return newState
    
        }

        //break;
        default:
            return state
    }
  }
  
  export default CVs