// JavaScript Document
import GraphQL from './ConsultaGraphQLDataProcesada';
import url from '../../../../../ApiService/Url';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchClimas = (idClima) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(idClima)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	return fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		return res.data.appInfo.climaOrganizacional.dataProcesada
	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchClimas;