import React, { Component } from 'react';
import './css/InfoCard.css';

import ScrollReveal from 'scrollreveal';

class InfoCard extends Component {
	
	componentDidMount() {
		
		ScrollReveal().reveal('.tituloSubSer', {
			distance: '150px',
			origin: 'left',
			reset: true,
			delay: 800
		});
		
	}
	
	render(){
		return(
			<div className="col-lg-6 col-12 tituloSubSer">
				<div className="col-12 fondo-opacidad-negro" style={{marginTop: '15px'}}>
					<h1 className="text-center">{this.props.data.titulo}</h1>
					<p className="text-justify">{this.props.data.texto}</p>
				</div>
			</div>
		)
	}
}

export default InfoCard