import React from 'react';

import { Bar } from 'chartjs-2-react';

import { setColor, LabelsColorsGraph } from '../utils';

let AreasDeAnalisis = props => {

    let variablesArr = props.grupoDeOpinion.indicadorDeMedicion.map( indicador => {

        let variables = indicador.variables.map( variable => variable)

        return variables
    }),
        variables = Array.prototype.concat.apply([], variablesArr)

    return(
        <div className="col-12">
            <h1 className="text-center">
                {props.grupoDeOpinion.nombre}
            </h1>
            {
               variables.map( (variable, i) => (
                    <div key={i}>
                        <h2 className="text-center">{variable.nombre}</h2>
                        <Bar config={{
                            data: {
                                labels: [...variable.preguntas.map( porcentaje => porcentaje.factorEvaluado )],
                                datasets: [{
                                    data: [...variable.preguntas.map(porcentaje => (porcentaje.porcentajePregunta * 100).toFixed(2))],
                                    backgroundColor: [...variable.preguntas.map( porcentaje => setColor(porcentaje.porcentajePregunta * 100) )],
                                    borderColor: 'none'
                                }],
                            },
                            options: {
                                title: {
                                    display: true,
                                    text: "Porcentajes de Satisfacción %"
                                },
                                responsive: true,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            callback: (value, index, values) => {
                                                return value + '%'
                                            },
                                            beginAtZero: true,
                                            max: 100
                                        }
                                    }]
                                },
                                animation: {
                                    duration: 1,
                                    onComplete: function() {
                                        var chartInstance = this.chart,
                                        ctx = chartInstance.ctx;
                                        ctx.textAlign = 'center';
                                        ctx.textBaseline = 'bottom';
                        
                                        this.data.datasets.forEach(function(dataset, i) {
                                        var meta = chartInstance.controller.getDatasetMeta(i);
                                        meta.data.forEach(function(bar, index) {
                                            var data = dataset.data[index];
                                            ctx.fillText(data + '%', bar._model.x, bar._model.y - 5);
                                        });
                                    });
                                    }
                                }
                            }
                        }}/>
                        <LabelsColorsGraph />
                    </div> 
                ))
            }
        </div>
    )
}

export default AreasDeAnalisis