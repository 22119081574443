let consulta = (CV) => {
  
    const string =
      `mutation deleteCV {
        deleteCV(idCV: "${CV.id}", cv: "${CV.cv}"){
          id
          mensaje
        }
      }`
  
      return {
        "query": string
      }
  
  }
  
  export default consulta