// JavaScript Document
import GraphQL from './ConsultaGraphQLDeleteEncuesta';

import url from '../../../../../ApiService/Url';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const deleteEncuesta = (setEncuestados, encuestados, data) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		
		let idEncuesta = res.data.deleteEncuesta.idEncuesta

		let newEncuestas = encuestados.filter( encuestado => encuestado.id !== idEncuesta)

		setEncuestados(newEncuestas)

	})
	.catch( err => handleError(err) )
	//termina fetch
	
}

export default deleteEncuesta;