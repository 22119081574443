import React, { Component } from 'react';
import './css/Card.css';

import ScrollReveal from 'scrollreveal';

class Card extends Component {
	
	componentDidMount() {
		
		ScrollReveal().reveal('.subSer', {
			distance: '150px',
			origin: 'bottom',
			reset: true,
			delay: 1300
		});
		
	}
	
	render(){
		return(
			<div className="col-lg-6 col-12 align-self-center">
				<div className="row">
					{
						this.props.data.map( item => {
							return (
								<div key={item.id} className="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-3 mx-auto subSer">
									<div className="fondo-opacidad-negro">
										<div className="col-6 col-offset-3 col-offset-sm-0 col-sm-12 mx-auto">
											<img className="img-responsive" src={item.img} alt={item.img}/>
										</div>
										<div className="col-12">
											<h2 className="text-center">{item.tituloCorto}</h2>
											<button className="btn btn-outline-dark text center col-12" data-toggle="modal" data-target={`#${item.id}`} >Ver Más</button>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>	
		)
	}
}

export default Card