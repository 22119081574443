// JavaScript Document
import GraphQL from './ConsultaGraphQLGetEncuestado';

import url from '../../../../../ApiService/Url';

function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		error: true
	})
}

const fetchEncuestado = (This, data) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		if(res.data.appInfo.getEncuestado !== null){

			This.setState({
				validToken: true,
				token: data.token,
				loading: false,
				encuestaActiva: res.data.appInfo.getEncuestado.encuestaActiva,
				cuestionario: res.data.appInfo.getEncuestado.cuestionario,
				respuestas: res.data.appInfo.getEncuestado.respuestas
			})

		}

	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default fetchEncuestado;