import React, { Component } from 'react';

import AdminComponent from '../../Components/AdminComponent.jsx';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import AsyncCreatable  from 'react-select/creatable';

import GraphQL from '../../../../ApiService/FetchGraphQLDataInfo.js';

import GraphQLInsertEtiquetaPuesto from './NewEtiquetaPuesto/FetchNewEtiquetaPuestoPromise.js';

import GraphQLInsertCV from './NewCV/FetchNewCVPromise.js';

import LoaderSinLogo from '../../../Components/LoaderSinLogo.jsx';

class IngresarCV extends Component {

    constructor(props) {
        super(props);
        // No llames this.setState() aquí!

        this.state = {
            isLoading: false,
            isLoadingCV: false,
            value: [],
            cv: {
                primerNombre: "",
                segundoNombre: "",
                primerApellido: "",
                segundoApellido: "",
                telefono: "",
                celular: "",
                correo: "",
                cv: "",
                estado: "Validado",
                etiquetasPuesto: []
            },
            insertCVMensaje: null
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputFileChange = this.handleInputFileChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
      }

    componentDidMount() {

        if(this.props.conexion === false){
           return <Redirect to='/login' />
            //console.log(this.state.conexion)
        }else {
            //console.log(this.usuario.conexion)
        }

        GraphQL(this)

    }

    handleFormSubmit(event){
        event.preventDefault();
        GraphQLInsertCV(this, this.state.cv)
        this.setState({
            isLoadingCV: true
        })
    }

    handleInputChange(event, type){
        if(type === 'primerNombre'){
            this.setState({
                cv: {
                   ...this.state.cv,
                   primerNombre: event.target.value
                }
            })
        }else if(type === 'segundoNombre'){
            this.setState({
                cv: {
                   ...this.state.cv,
                   segundoNombre: event.target.value
                }
            })
        }else if(type === 'primerApellido'){
            this.setState({
                cv: {
                   ...this.state.cv,
                   primerApellido: event.target.value
                }
            })
        }else if(type === 'segundoApellido'){
            this.setState({
                cv: {
                   ...this.state.cv,
                   segundoApellido: event.target.value
                }
            })
        }else if(type === 'telefono'){
            this.setState({
                cv: {
                   ...this.state.cv,
                   telefono: event.target.value
                }
            })
        }else if(type === 'celular'){
            this.setState({
                cv: {
                   ...this.state.cv,
                   celular: event.target.value
                }
            })
        }else if(type === 'correo'){
            this.setState({
                cv: {
                   ...this.state.cv,
                   correo: event.target.value
                }
            })
        }
    }

    handleInputFileChange(){
        let This = this
        function convertToBase64() {
            //Read File
            var selectedFile = document.getElementById("archivo").files;
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //console.re.log(base64);
                    //console.log(base64);
                    This.setState({
                        cv: {
                           ...This.state.cv,
                           cv: base64
                        }
                    })
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
            }
        }
        convertToBase64()
    }

    handleChange = (newValue, actionMeta) => {

      if(newValue !== null){
        let etiquetasPuesto = newValue.map( value => {
            return value.value
        })
        this.setState({ 
            value: newValue,
            cv: {
                ...this.state.cv,
                etiquetasPuesto
            }
        });
      }else{
        this.setState({ 
            value: newValue,
            cv: {
                ...this.state.cv,
                etiquetasPuesto: []
            }
         });
      }

    };

    handleCreate = (inputValue) => {

      let createOption = (label) => ({
          label,
          value: label
      });

      let value = this.state.value

      this.setState({ isLoading: true });

      setTimeout(() => {

        GraphQLInsertEtiquetaPuesto(this, inputValue)

        const newOption = createOption(inputValue);

        let etiquetasPuesto = this.state.cv.etiquetasPuesto
            etiquetasPuesto.push(inputValue)

        this.setState({
          isLoading: false,
          value: [...value, newOption],
          cv: {
            ...this.state.cv,
            etiquetasPuesto
          }
        });

      }, 1000);

    };

    render(){
        if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else{

            const { isLoading, value } = this.state;

            let createOption = (label) => ({
                label,
                value: label
            });

            let options = this.props.etiquetasPuestos.map( etiqueta => {
                return createOption(etiqueta.puesto)
            })

            return(
                <AdminComponent>
                    <div className="container-fluid" style={{minHeight: '80vh', marginTop: '25px', marginBottom: '50px'}}>
                        <form onSubmit={this.handleFormSubmit}>
                            <div className="form-row">
                                <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                    <label htmlFor="primerNombreInput">Primer Nombre: </label>
                                    <input value={this.state.cv.primerNombre} type="text" className="form-control" id="primerNombreInput" aria-describedby="primerNombre" placeholder="Ingresa el Primer Nombre" required onChange={(event)=>{
                                        let type = "primerNombre";
                                        this.handleInputChange(event, type)
                                    }} />
                                    <small id="primerNombre" className="form-text text-muted">Primer Nombre.</small>
                                </div>
                                <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                    <label htmlFor="segundoNombreInput">Segundo Nombre: </label>
                                    <input value={this.state.cv.segundoNombre} type="text" className="form-control" id="segundoNombreInput" aria-describedby="segundoNombre" placeholder="Ingresa el Segundo Nombre" onChange={(event)=>{
                                        let type = "segundoNombre";
                                        this.handleInputChange(event, type)
                                    }} />
                                    <small id="segundoNombre" className="form-text text-muted">Segundo Nombre.</small>
                                </div>
                                <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                    <label htmlFor="primerApellidoInput">Primer Apellido: </label>
                                    <input value={this.state.cv.primerApellido} type="text" className="form-control" id="primerApellidoInput" aria-describedby="primerApellido" placeholder="Ingresa el Primer Apellido" required onChange={(event)=>{
                                        let type = "primerApellido";
                                        this.handleInputChange(event, type)
                                    }} />
                                    <small id="primerApellido" className="form-text text-muted">Primer Apellido.</small>
                                </div>
                                <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                    <label htmlFor="segundoApellidoInput">Segundo Apellido: </label>
                                    <input value={this.state.cv.segundoApellido} type="text" className="form-control" id="segundoApellidoInput" aria-describedby="segundoApellido" placeholder="Ingresa el Segundo Apellido" required onChange={(event)=>{
                                        let type = "segundoApellido";
                                        this.handleInputChange(event, type)
                                    }} />
                                    <small id="segundoApellido" className="form-text text-muted">Segundo Apellido</small>
                                </div>
                                <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                    <label htmlFor="telefonoInput">Teléfono: </label>
                                    <input value={this.state.cv.telefono}  type="text" className="form-control" id="telefonoInput" aria-describedby="telefono" placeholder="Ingresa el teléfono" onChange={(event)=>{
                                        let type = "telefono";
                                        this.handleInputChange(event, type)
                                    }} />
                                    <small id="telefono" className="form-text text-muted">El teléfono de la persona.</small>
                                </div>
                                <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                    <label htmlFor="celularInput">Celular: </label>
                                    <input value={this.state.cv.celular}  type="text" className="form-control" id="celular" aria-describedby="celularInput" placeholder="Ingresa el celular" required onChange={(event)=>{
                                        let type = "celular";
                                        this.handleInputChange(event, type)
                                    }} />
                                    <small id="celular" className="form-text text-muted">El celular de la persona.</small>
                                </div>
                                <div className="form-group col-12 col-md-4 col-lg-3 mx-auto">
                                    <label htmlFor="correoInput">Correo: </label>
                                    <input value={this.state.cv.correo} type="email" className="form-control" id="correo" aria-describedby="correoInput" placeholder="Ingresa el correo" required onChange={(event)=>{
                                        let type = "correo";
                                        this.handleInputChange(event, type)
                                    }} />
                                    <small id="correo" className="form-text text-muted">El correo de la persona.</small>
                                </div>
								<div className="form-group col-12 col-md-4 col-lg-3 mx-auto custom-file">
                                    <label htmlFor="archivo">Archivo: </label><br/>
									<input type="file" aria-describedby="correo" className="archivo1" id="archivo" name="archivo" required style={{width: '1px', height: '1px', opacity: '0', overflow: 'hidden', position: 'absolute', zIndex: '-1'}} onChange={this.handleInputFileChange}/>
									<label className="btn btn btn-outline-warning col-12" htmlFor="archivo"><span>Seleccionar CV</span></label>
                                    <small id="archivo" className="form-text text-muted">El CV de la persona.</small>
								</div>
                                <div className="form-group col-12 col-md-8 col-lg-5 mx-auto">
                                    <label htmlFor="etiquetasInput">Etiquetas: </label>
                                    <AsyncCreatable
                                        isClearable
                                        isDisabled={isLoading}
                                        isLoading={isLoading}
                                        onChange={this.handleChange}
                                        onCreateOption={this.handleCreate}
                                        options={options}
                                        value={value}
                                        isMulti={true} 
                                        id="etiquetasInput"
                                        placeholder="Crea una Etiqueta"/>
                                    <small id="etiquetas" className="form-text text-muted">Etiquetas de puestos persona.</small>
                                </div>
                            </div>
                            {
                                this.state.isLoadingCV === true ? (
                                    <LoaderSinLogo />
                                ) : null
                            }
                            {
                                this.state.insertCVMensaje !== null ? (
                                <div className="row">
                                    {
                                    (this.state.insertCVMensaje.response === 'success') ? (
                                        <div className="alert alert-success col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertCVMensaje.mensaje}</div>
                                    ) : (
                                        <div className="alert alert-danger col-10 col-sm-8 col-md-6 mx-auto text-center" role="alert">{this.state.insertCVMensaje.mensaje}</div>
                                    )
                                    }
                                </div>
                                ) : null
                            }
                            <div className="form-row">
                                <button type="submit" className="btn btn-xl btn btn-outline-warning mx-auto text-center" style={{marginTop: '15px'}}>Guardar Persona</button>
                            </div>
                        </form>
                    </div>
                </AdminComponent>
            )
        }
    }
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario,
    etiquetasPuestos: state.AppInfo.dataInfo.etiquetasPuestos
  }
}

export default connect(mapStateToProps)(IngresarCV)