import React from 'react';

import { RadialGauge, Bar } from 'chartjs-2-react';

import { setColor, LabelsColorsGraph } from '../utils';

let PorcentajeSatisfaccionGeneral = props => {

    let satisfaccionGeneralActual = 0

    let porcentajeTextoActual  =  `0 %`

    if(props.dataProcesadaActual){
        satisfaccionGeneralActual = props.dataProcesadaActual.porcentajeSatisfaccionGeneral
        porcentajeTextoActual  =  `${satisfaccionGeneralActual} %`
    }else{
        props.indicadoresDeMedicionConRespuestasYPorentajeActual.forEach (function(indicador){
            satisfaccionGeneralActual += indicador.porcentajeIndicador;
        });
    
        satisfaccionGeneralActual = ((satisfaccionGeneralActual/props.indicadoresDeMedicionConRespuestasYPorentajeActual.length) * 100).toFixed(2)
        porcentajeTextoActual  =  `${satisfaccionGeneralActual} %`
    }

    let satisfaccionGeneralComparado = 0
    let porcentajeTextoComparado  =  `0 %`

    if(props.dataProcesadaComparado){
        satisfaccionGeneralComparado = props.dataProcesadaComparado.porcentajeSatisfaccionGeneral
        porcentajeTextoComparado  =  `${satisfaccionGeneralComparado} %`
    }else{
        props.indicadoresDeMedicionConRespuestasYPorentajeComparado.forEach (function(indicador){
            satisfaccionGeneralComparado += indicador.porcentajeIndicador;
        });
    
        satisfaccionGeneralComparado = ((satisfaccionGeneralComparado/props.indicadoresDeMedicionConRespuestasYPorentajeComparado.length) * 100).toFixed(2)
        porcentajeTextoComparado  =  `${satisfaccionGeneralComparado} %`
    }

    return(
        <div className="col-12 col-md-8 col-lg-6 mx-auto mb-5">
            <h2>Gráficas General de Satisfacción Comparativas</h2>
                <div className="col-12 my-3">
                    <h3>{props.nombreClimaActual}</h3>
                    <RadialGauge config={{
                        data: {
                            datasets: {
                                data: satisfaccionGeneralActual,
                                backgroundColor: setColor(satisfaccionGeneralActual)
                            }
                        },
                        options: {
                            centerArea: {
                                text: porcentajeTextoActual
                            }
                        }
                    }}/>
                    <LabelsColorsGraph />
                </div>    
                {
                    (props.indicadoresDeMedicionConRespuestasYPorentajeComparado.length > 0 || props.dataProcesadaComparado) && (
                        <div className="col-12 my-3">
                            <h3>{props.nombreClimaComparado}</h3>
                            <RadialGauge config={{
                                data: {
                                    datasets: {
                                        data: satisfaccionGeneralComparado,
                                        backgroundColor: setColor(satisfaccionGeneralComparado)
                                    }
                                },
                                options: {
                                    centerArea: {
                                        text: porcentajeTextoComparado
                                    }
                                }
                            }}/>
                            <LabelsColorsGraph />
                        </div>
                    )
                }
                <Bar config={{
                    data: {
                        labels: ['Satisfacción General'],
                        datasets: [{
                            label: props.nombreClimaActual,
                            data: [props.dataProcesadaActual ? props.dataProcesadaActual.porcentajeSatisfaccionGeneral : satisfaccionGeneralActual],
                            backgroundColor: "#EABF2D",
                            borderColor: 'none'
                        },
                        {
                            label: props.nombreClimaComparado,
                            data: [satisfaccionGeneralComparado],
                            backgroundColor: "#E88D46",
                            borderColor: 'none'
                        }],
                    },
                    options: {
                        title: {
                            display: true,
                            text: "Porcentajes de Satisfacción %"
                        },
                        responsive: true,
                        legend: {
                            display: true
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    callback: (value, index, values) => {
                                        return value + '%'
                                    },
                                    beginAtZero: true,
                                    max: 100
                                }
                            }]
                        },
                        animation: {
                            duration: 1,
                            onComplete: function() {
                                var chartInstance = this.chart,
                                ctx = chartInstance.ctx;
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'bottom';
                
                                this.data.datasets.forEach(function(dataset, i) {
                                var meta = chartInstance.controller.getDatasetMeta(i);
                                meta.data.forEach(function(bar, index) {
                                    var data = dataset.data[index];
                                    ctx.fillText(data + '%', bar._model.x, bar._model.y - 5);
                                });
                            });
                            }
                        }
                    }
                }}/>
        </div>
    )
}

export default PorcentajeSatisfaccionGeneral