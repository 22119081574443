let consulta = () => {
    let string = `{
      appInfo{
        empresas{
          id
          nombre_comercial
          nombre_fiscal
          telefono
          correo
          NIT
          direccion
          contacto_directo{
            nombre
            apellidos
            correo
            telefono
            celular
            direccion
            sexo
          }
        }
      }
    }`
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;