const returnActiveParteCuestionario = (respuestas, cuestionario) => {

        let respuestasIdsPreguntas = respuestas.map( respuesta => respuesta.idPregunta )

        let partes = cuestionario.partesCuestionario.map( parte => {

            let preguntas = [...parte.preguntas]

            if(parte.preguntaAbierta !== null){
                preguntas.push(parte.preguntaAbierta)
            }

            let lengthPreguntasOfParte = preguntas.length,
                lengthPreguntasContestadasOfParte = preguntas.filter( pregunta => {
                    return respuestasIdsPreguntas.includes(pregunta.id)
                }).length,
                todasContestadas = false

            //console.log(lengthPreguntasOfParte)
            //console.log(lengthPreguntasContestadasOfParte)

            if(lengthPreguntasOfParte === lengthPreguntasContestadasOfParte){
                todasContestadas = true
            }else{
                todasContestadas = false
            }

            parte.todasContestadas = todasContestadas

            return parte


        }).sort(function(a, b) {
            return a.orden - b.orden;
        }),
        partesNoContestadas = partes.filter( parte => parte.todasContestadas !== true).sort(function(a, b) {
            return a.orden - b.orden;
        }),
        activeParteCuestionario = partesNoContestadas[0]

        return activeParteCuestionario

    },
    returnPreguntasParte = (preguntasParte, preguntaAbierta) => {

        let preguntas = [...preguntasParte]

        if(preguntaAbierta !== null){
            preguntaAbierta.tipo = "preguntaAbierta"
            preguntas.push(preguntaAbierta)
        }

        return preguntas

    },
    returnActivePreguntas = (respuestas, preguntas, cuestionario) => {

        let respuestasIdsPreguntas = respuestas.map( respuesta => respuesta.idPregunta ),
            preguntasNoRespondidas = preguntas.filter( pregunta => (respuestasIdsPreguntas.includes(pregunta.id)) === false)
            .sort(function(a, b) {
                return a.orden - b.orden;
            }),
            returnLength = ((cuestionario.tipo === "seleccionMultiple") || (cuestionario.tipo === "preguntasAbiertas")) ? preguntas.length : 5,
            preguntasReturn = preguntasNoRespondidas.slice(0, returnLength).sort(function(a, b) {
                return a.orden - b.orden;
            })

        return preguntasReturn

    }

export { returnActiveParteCuestionario, returnPreguntasParte, returnActivePreguntas }