let consulta = (newEncuesta) => {

    let correos = newEncuesta.correos === "" ? null : JSON.stringify(newEncuesta.correos.split(";"))

    let string = `mutation{
      insertNewEncuesta(idClima: "${newEncuesta.idClima}", idGrupoDeOpinion:"${newEncuesta.idGrupoDeOpinion}", correos: ${correos})
    }`

    return {
      "query": string
    }
  
  }
  
  export default consulta;