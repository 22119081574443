const initialState = []
  
  let Empresas = (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_EMPRESAS': {

          let dataEmpresas = action.payload.dataEmpresas
      
          let newState = dataEmpresas
      
          return newState
        }

        //break;
        default:
            return state
    }
  }
  
  export default Empresas