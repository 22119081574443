import React from 'react';

//importando Estilos
import './css/Contacto.css'

let ContactoLayOut = props => (
	<div id="contacto" className="jarallax col-12 section">
		<img src="img/jpg/contactofondo.jpg" alt="Imagen de Fondo" className="img-responsive jarallax-img"/>
		<h2 className="col-12 text-center">Contacto</h2>
		<div className="container">
			{props.children}
		</div>
	</div>
)

export default ContactoLayOut