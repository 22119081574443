import React from 'react';

//importando Estilos
import './css/Portada.css'

let PortadaLayOut = props => (
	<div id="inicio" className="jarallax col-12 section">
		{props.children}
	</div>
)

export default PortadaLayOut