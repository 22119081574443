import React, { Component } from 'react';

//Impotando Component
import EnviaCVLayOut from '../Components/EnviaCVLayOut.jsx';

//Importando Librerías CSS
import 'animate.css';
import '../../../../MyModules/anicollection.css';

class EnviaCV extends Component {

	/*constructor(props) {
    super(props);
  }*/

	componentDidMount() {

		window.scrollTo(0, 0)

		var input = document.querySelector( '.archivo1' );

		var label	 = input.nextElementSibling,
			labelVal = label.innerHTML;

		input.addEventListener( 'change', function( e )
		{
			var fileName = '';
			if( this.files && this.files.length > 1 ){
				fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
			} else {
				fileName = e.target.value.split( '\\' ).pop();
			}

			if( fileName ){
				label.querySelector( 'span' ).innerHTML = fileName;
			}else{
				label.innerHTML = labelVal;
			}
		});

	}

	FetchEnviaCV(e) {

		function handleError(err){
			console.log(`Request failed: ${err}`);
		}

		e.preventDefault();

		var formData = new FormData(document.getElementById("enviarcurri"));

		const options = {
			method: 'POST',
			body: formData,
			credentials: 'include'
		}

			document.querySelector(".sending").style.display = 'block';

		//llamada de datos con Fetch
		//fetch('http://blconsultores.com.gt/mail/enviarcurri.php', options)
		fetch('https://blconsultores.com.gt/mail/enviarcurri', options)
		//fetch('https://dev-back-end.p-lao.com/mail/enviarcurri', options)
		.then( res => {
			if(res.status === 200){
				document.querySelector(".success").style.display = 'block';
				document.querySelector(".sending").style.display = 'none';
				document.querySelector(".error").style.display = 'none';
			}else{
				document.querySelector(".error").style.display = 'block';
				document.querySelector(".sending").style.display = 'none';
				document.querySelector(".success").style.display = 'none';
			}
		})
		.catch(err => handleError(err));

	}

	render(){
		return(
			<EnviaCVLayOut path={this.props.path}>
				<div className="row d-flex">
					<div className="col-lg-12 text-center">
						<h2 className="section-heading">Envíe Su CV</h2>
					</div>
					<div className="col-lg-12">
						<form name="curriculumForm" className="curriculumForm" id="enviarcurri" acceptCharset="utf-8"  encType="multipart/form-data" onSubmit={this.FetchEnviaCV}>
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<input type="text" name="nombre" className="form-control nombre" placeholder="Nombre *" required />
									</div>
									<div className="form-group">
										<input type="text" name="plaza" className="form-control plaza" placeholder="Plaza a Aplicar *" required />
									</div>
									<div className="form-group">
										<input type="email" name="correo" className="form-control correo" placeholder="Correo *" required />
									</div>
									<div className="form-group">
										<input type="tel" name="tel" className="form-control tel" placeholder="Teléfono Para Contacto *" required />
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<textarea className="form-control mensaje" name="mensaje" placeholder="Mensaje *" required ></textarea>
									</div>
								</div>
								<div className="clearfix"></div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12 custom-file">
									<input type="file" className="archivo1" id="archivo" name="archivo" required />
									<label className="btn btn-outline-light" htmlFor="archivo"><span>Seleccionar CV</span></label>
								</div>
								<button type="submit" className="btn btn-xl btn btn-outline-light mx-auto text-center" style={{marginTop: '15px'}}>Enviar</button>
							</div>
							<div className="row" style={{marginTop: '25px', padding: '5px'}}>
								<div className="success alert alert-success text-center mx-auto" role="alert">Tu curriculum ha sido Enviado</div>
								<div className="sending alert alert-info text-center mx-auto" role="alert">Tu curriculum se está enviando, por favor espera un momento</div>
								<div className="error alert alert-danger text-center mx-auto" role="alert">Ha habido un error de envío. Tu curriculum no ha sido Enviado. Por favor prueba nuevamente</div>
							</div>
						</form>
					</div>
				</div>
			</EnviaCVLayOut>
		)
	}
}

export default EnviaCV
