let consulta = () => {
    let string = `{
      appInfo{
        etiquetasPuestos{
            id
            puesto
        }
      }
    }`
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;