let consulta = (idClima) => {
    let string = `{
      appInfo{
        getAreasDeTrabajoSatisfaccionGeneral(idClima: "${idClima}"){
          id
          nombre
          cantidadEncuestadosAreaDeTrabajo
          gruposDeOpinion{
            id
            orden
            nombre
            cantidadEncuestadosGrupoDeOpinion
            encuestadosPlaneados
            porcentajeSatisfaccionGrupoDeOpinion
            variables{
              nombre
              descripcion
              VariableNoAplica
              porcentajeOfVariable
              factoresEvaluados{
                nombre
                porcentajeOfFactorEvaluado
                positiva
                factorEvaluadoNoAplica
                respuestas{
                  id
                  factorEvaluado
                  idGrupoDeOpinion
                  idPregunta
                  respuesta
                }
                respuestasNoAplica{
                  id
                  factorEvaluado
                  idGrupoDeOpinion
                  idPregunta
                  respuesta
                }
              }
            }
          }
          idsGruposDeOpinion
          porcentajeSatisfaccionAreaDeTrabajo
          variables{
            nombre
            descripcion
            porcentajeOfVariable
            factoresEvaluados{
              nombre
              porcentajeOfFactorEvaluado
              positiva
              respuestas{
                id
                factorEvaluado
                idGrupoDeOpinion
                idPregunta
                respuesta
              }
            }
          }
        }
      }
    }`
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;