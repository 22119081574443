let consulta = () => {
    let string = `{
      appInfo{
        climasOrganizacionales{
          id
          encuestaActiva
          idEmpresa
          fechaInicio
          indicadoresDeMedicion{
            nombre
            variables{
              nombre
              descripcion
              factoresEvaluados{
                nombre
                positiva
              }
            }
          }
          gruposDeOpinion{
            id
            nombre
            encuestadosPlaneados
          }
          areasDeTrabajo{
            id
            nombre
            idsGruposDeOpinion
          }
          cuestionario{
            partesCuestionario{
              id
              orden
              tipo
              variable
              preguntaGeneral
              preguntaAbierta{
                id
                orden
                pregunta
              }
              cantidadDeOpciones
              preguntas{
                id
                orden
                pregunta
                factorEvaluado
              }
            }
          }
        }
      }
    }`
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;