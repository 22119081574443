// JavaScript Document
import GraphQL from './ConsultaGraphQLSendCorreoEncuesta';

import url from '../../../../../ApiService/Url';

function handleError(err, setMensajeSendCorreo, setLoading){
	console.log(`Request failed: ${err}`);

	setMensajeSendCorreo(`Hubo un error, el error fué: ${err}, intenta de nuevo`)
	setLoading(false)

}

const fetchInsertNewEncuesta = (setMensajeSendCorreo, setLoading, data) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		setMensajeSendCorreo(res.data.sendCorreoToEncuesta)
		setLoading(false)

	})
	.then( () =>{
		setTimeout( () => {
			setMensajeSendCorreo(null)
		}, 3000);
	})
	.catch(err => handleError(err, setMensajeSendCorreo, setLoading))
	.then(() =>{
		setTimeout( () => {
			setMensajeSendCorreo(null)
		}, 3000);
	});

	//termina fetch
}

export default fetchInsertNewEncuesta;