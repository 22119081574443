import React from 'react';

//Components
import AppLayout from './AppLayout.jsx';

import Inicio from '../Inicio/Components/Inicio.jsx';
import DesarrolloOrganizacional from '../Inicio/Servicios/DesarrolloOrganizacional/Containers/DesarrolloOrganizacional.jsx';
import FormacionCapacitacionYAdiestramiento from '../Inicio/Servicios/FormacionCapacitacionYAdiestramiento/Containers/FormacionCapacitacionYAdiestramiento.jsx';
import ProcesoDeSeleccion from '../Inicio/Servicios/ProcesoDeSeleccion/Containers/ProcesoDeSeleccion.jsx';
import EnviaCV from '../Inicio/EnviaCV/Containers/EnviaCV.jsx';

import LogIn from '../admin/login/containers/LogIn.jsx';
import Dashboard from '../admin/dashboard/containers/Dashboard.jsx';

import VerClimas from '../admin/adminClima/Containers/VerClimas';
import ResponderEncuesta from '../admin/adminClima/Containers/ResponderEncuesta';

import AdminCV from '../admin/adminCV/Containers/AdminCV.jsx';
import IngresarCV from '../admin/adminCV/Containers/IngresarCV.jsx';
import BuscarCV from '../admin/adminCV/Containers/BuscarCV.jsx';

//Router
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom'; //Redirect: agregarlo después

const PrincipalRoutes = props => (
	<AppLayout>
		<Router>
            <Switch>
            {/*Route Inicio*/}
            <Route path="/" exact render={
                ({ location }) => <Inicio path={location.pathname} />
                }/>
            {/*Route Desarrollo Organizacional*/}
            <Route path="/desarrollo-organizacional" exact render={
                ({ location }) => <DesarrolloOrganizacional path={location.pathname} />
                }/>
            {/*Route Capacitación*/}
            <Route path="/formacion-capacitacion-y-adiestramiento" exact render={
                ({ location }) => <FormacionCapacitacionYAdiestramiento path={location.pathname} />
                }/>
            {/*Route Capacitación*/}
            <Route path="/proceso-de-seleccion" exact render={
                ({ location }) => <ProcesoDeSeleccion path={location.pathname} />
                }/>
            {/*Route Envía CV*/}
            <Route path="/envia-cv" exact render={
                ({ location }) => <EnviaCV path={location.pathname} />
                }/>
            {/*Route LogIn*/}
            <Route path="/login" exact render={
                () => <LogIn />
            }/>
            {/*Route Dashboard*/}
            <Route path="/dashboard" exact render={
                () => <Dashboard />
            }/>

            {/*Route AdminCV*/}
            <Route path="/admin/cv" exact render={
                () => <AdminCV />
            }/>
            {/*Route IngresarCV*/}
            <Route path="/admin/cv/ingresar" exact render={
                () => <IngresarCV />
            }/>
            {/*Route BuscarCV*/}
            <Route path="/admin/cv/buscar" exact render={
                () => <BuscarCV />
            }/>

            {/*Route admin Climas*/}
            <Route path="/admin/clima-organizacional/ver-climas" exact render={
                () => <VerClimas />
            }/>

            {/*Route Encuesta climas*/}
            <Route path="/admin/clima-organizacional/encuesta/:idClima" exact render={
                ({ match }) => <ResponderEncuesta params={match.params}/>
            }/>

            </Switch>
        </Router>
	</AppLayout>
)

export default PrincipalRoutes;