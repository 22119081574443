import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

import SatisfaccionGeneral from '../Components/Graficas/SatisfaccionGeneral';
import SatisfaccionGeneralComparativas from '../Components/Graficas/SatisfaccionGeneralComparativas';
import AreasDeAnalisis from '../Components/Graficas/AreasDeAnalisis';
import AreasDeAnalisisComparativas from '../Components/Graficas/AreasDeAnalisisComparativas';
import GruposDeOpinion from '../Components/Graficas/GruposDeOpinion';
import GruposDeOpinionComparativas from '../Components/Graficas/GruposDeOpinionComparativas';
import AreasDeTrabajo from '../Components/Graficas/AreasDeTrabajo';
import AreasDeTrabajoComparativas from '../Components/Graficas/AreasDeTrabajoComparativas';
import GraficasComplementarias from '../Components/Graficas/GraficasComplementarias';
import AreasDeAnalisisPorGrupoDeOpinion from '../Components/Graficas/AreasDeAnalisisPorGrupoDeOpinion';
import AreasDeAnalisisPorAreaDeTrabajo from '../Components/Graficas/AreasDeAnalisisPorAreasDeTrabajo';
import Antiguedad from '../Components/Graficas/Antiguedad';
import PelotasGruposDeOpinion from '../Components/Graficas/PelotasGruposDeOpinion';
import PelotasAreasDeTrabajo from '../Components/Graficas/PelotasAreasDeTrabajo';
import AntiguedadComparativas from '../Components/Graficas/AntiguedadComparativas';
import PreguntasAbiertas from '../Components/Graficas/RespuestasPreguntasAbiertas';
import Loader from '../../../Components/LoaderSinLogo';
import url from '../../../../ApiService/Url';

class GraficasClima extends Component {

    constructor(props) {
        super(props);

        // No llames this.setState() aquí!
        this.state = { 
            display: "SelectGraficas",
            loading: false
        };
        
        this.changeDisplay = this.changeDisplay.bind(this);
        this.onDownloadExcel = this.onDownloadExcel.bind(this);
    }

    changeDisplay(display){
        this.setState({
            ...this.state,
            display
        })
    }

    onDownloadExcel(idClima){
        this.setState({
            ...this.state,
            loading: true
        })
        const options = {
            method: 'post',
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                query: `
                {
                    appInfo{
                    getSatisfaccionPorGrupoDeOpinonYFactoresEvaluados(idClima: "${idClima}")
                    }
                }
                `
            }),
            credentials: 'include'
        }

        fetch(url, options)
        .then(res => res.json())
        .then( (res) => {
            this.setState({
                ...this.state,
                loading: false
            })
            let base64Excel = res.data.appInfo.getSatisfaccionPorGrupoDeOpinonYFactoresEvaluados;

            // Crear un enlace para la descarga
            let link = document.createElement('a');
            link.href = base64Excel;

            // Definir el nombre del archivo para la descarga
            link.download = `Clima-${idClima}.xlsx`;

            // Simular un clic en el enlace para iniciar la descarga
            link.click();
        })
    }

    render(){

        if(this.props.conexion === false){
            return <Redirect to='/login' />
        }else{
            return(
                <div className="col-12 text-center">
                    <div className="row">
                        <button style={{backgroundColor: "transparent", border: 'none'}} onClick={ () => {
                            this.props.changeDisplay("EditarClima")
                        }}>
                            <FontAwesomeIcon icon="arrow-left" style={{color: '#EABF2D', fontSize: '18px'}} />
                        </button>
                    </div>
                    {
                       this.state.display === "SelectGraficas" ? (
                            <div className="row">
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("SatisfaccionGeneral")
                                }}>Ver Gráficas de Satisfacción General <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("SatisfaccionGeneralComparativas")
                                }}>Ver Gráficas de Satisfacción General Comparativas <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("AreasDeAnalisis")
                                }}>Ver Gráficas de Satisfacción por Áreas de Análisis <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("AreasDeAnalisisComparativas")
                                }}>Ver Gráficas de Satisfacción por Áreas de Análisis Comparativas <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("AreasDeTrabajo")
                                }}>Ver Gráficas de Satisfacción por Áreas de Trabajo <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("AreasDeTrabajoComparativas")
                                }}>Ver Gráficas de Satisfacción por Áreas de Trabajo Comparativas <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("GruposDeOpinion")
                                }}>Ver Gráficas de Satisfacción por Grupos de Opinión <FontAwesomeIcon icon="chart-bar"/></button>
                                 <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("GruposDeOpinionComparativas")
                                }}>Ver Gráficas de Satisfacción por Grupos de Opinión Comparativas <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("GraficasComplementarias")
                                }}>Ver Gráficas Complementarias <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("AreasDeAnalisisPorGrupoDeOpinion")
                                }}>Ver Gráficas de Satisfaccion por Áreas de Análisis y Grupos de Opinión <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("AreasDeAnalisisPorAreaDeTrabajo")
                                }}>Ver Gráficas de Satisfaccion por Áreas de Análisis y Áreas De Trabajo <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("Antiguedad")
                                }}>Ver Gráficas de Satisfaccion por Antiguedad <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("PelotasGruposDeOpinion")
                                }}>Ver Gráficas de Pelotas de Grupo de Opinion <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("PelotasAreasDeTrabajo")
                                }}>Ver Gráficas de Pelotas de Áreas De Trabajo <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("AntiguedadComparativas")
                                }}>Ver Gráficas de Satisfaccion por Antiguedad Comparativas <FontAwesomeIcon icon="chart-bar"/></button>
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.changeDisplay("PreguntasAbiertas")
                                }}>Ver Preguntas Abiertas <FontAwesomeIcon icon="chart-bar"/></button>
                                {/**/}
                                <button type="button" className="btn btn-outline-warning mx-auto my-3" onClick={ () => {
                                    this.onDownloadExcel(this.props.climaOrganizacional.id)
                                }}>Descargar Excel de Datos <FontAwesomeIcon icon="chart-bar"/></button>
                            </div>
                       ) : null
                    }
                    {
                        this.state.display === "SatisfaccionGeneral" ? (
                            <SatisfaccionGeneral changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "SatisfaccionGeneralComparativas" ? (
                            <SatisfaccionGeneralComparativas changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "AreasDeAnalisis" ? (
                            <AreasDeAnalisis changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "AreasDeAnalisisComparativas" ? (
                            <AreasDeAnalisisComparativas changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "AreasDeTrabajo" ? (
                            <AreasDeTrabajo changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "AreasDeTrabajoComparativas" ? (
                            <AreasDeTrabajoComparativas changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "GruposDeOpinion" ? (
                            <GruposDeOpinion changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "GruposDeOpinionComparativas" ? (
                            <GruposDeOpinionComparativas changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "GraficasComplementarias" ? (
                            <GraficasComplementarias changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "AreasDeAnalisisPorGrupoDeOpinion" ? (
                            <AreasDeAnalisisPorGrupoDeOpinion changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "AreasDeAnalisisPorAreaDeTrabajo" ? (
                            <AreasDeAnalisisPorAreaDeTrabajo changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "Antiguedad" ? (
                            <Antiguedad changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "PelotasGruposDeOpinion" ? (
                            <PelotasGruposDeOpinion changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "PelotasAreasDeTrabajo" ? (
                            <PelotasAreasDeTrabajo changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "AntiguedadComparativas" ? (
                            <AntiguedadComparativas changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.display === "PreguntasAbiertas" ? (
                            <PreguntasAbiertas changeDisplay={this.changeDisplay} climaOrganizacional={this.props.climaOrganizacional} />
                        ) : null
                    }
                    {
                        this.state.loading === true ? (
                            <Loader />
                        ) : null
                    }
                </div>
            )
        }
    }
}

let mapStateToProps = (state, props) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario
    }
}
  
export default connect(mapStateToProps)(GraficasClima)