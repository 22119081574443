import React from 'react';

//Importando Componentes
import Navbar from '../Navbar/Containers/Navbar.jsx';
import Portada from '../Portada/Containers/Portada.jsx';
import QuienesSomos from '../QuienesSomos/Components/QuienesSomos.jsx';
import Servicios from '../Servicios/Containers/Servicios.jsx';
import Contacto from '../Contacto/Containers/Contacto.jsx';
import Footer from '../Footer/Components/Footer.jsx';

//Importando Estilos CSS
import './css/Inicio.css';

//Import Jquery
//import $ from 'jquery';

let Inicio = props => {
	//console.log(props.path)
	return(
		<div id="inicio-container" className="container-fluid">
			<div className="row">
				<Navbar path={props.path}/>
				<Portada />
				<QuienesSomos />
				<Servicios />
				<Contacto />
				<Footer />
			</div>
		</div>
	)
}

export default Inicio
