import React, { useState, useEffect } from 'react';

import FetchEncuestados from '../../../Containers/FetchGetAllEncuestados/FetchGraphQLEncuestados';

import Loader from '../../../../../Components/LoaderSinLogo';

import MaloToExelente from './MaloToExelente';

import SeleccionMultiple from './SeleccionMultiple';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//Falta Pasar Logica al Back-End
let BenificiosDeLaEmpresa = props => {

    let [encuestados, setEncuestados] = useState([])

    useEffect( ()=> {
        FetchEncuestados(setEncuestados, props.climaOrganizacional.id)
    }, [props])

    let partesCuestionarioMaloToExelente = props.climaOrganizacional.cuestionario.partesCuestionario.filter( parteCuestionario => {

        let preguntas = parteCuestionario.preguntas,
        idsPreguntas = preguntas.map( pregunta => pregunta.id),
        respuestasArray = encuestados.map( encuestado => encuestado.respuestas ),
        respuestas = Array.prototype.concat.apply([], respuestasArray),
        respuestasParteCuestionario = respuestas.filter( respuesta => idsPreguntas.includes(respuesta.idPregunta) )

        parteCuestionario.preguntas.map( pregunta => {

            let respuestasParteCuestionarioOfRespuesta = respuestasParteCuestionario.filter( respuesta => respuesta.idPregunta === pregunta.id),
                respuestasExelente = respuestasParteCuestionarioOfRespuesta.filter( respuesta => respuesta.respuesta === "0"),
                respuestasBueno = respuestasParteCuestionarioOfRespuesta.filter( respuesta => respuesta.respuesta === "1"),
                respuestasRegular = respuestasParteCuestionarioOfRespuesta.filter( respuesta => respuesta.respuesta === "2"),
                respuestasMalo = respuestasParteCuestionarioOfRespuesta.filter( respuesta => respuesta.respuesta === "3")

            pregunta.respuestas = respuestasParteCuestionarioOfRespuesta
            pregunta.Exelente = respuestasExelente
            pregunta.Bueno = respuestasBueno
            pregunta.Regular = respuestasRegular
            pregunta.Malo = respuestasMalo

            return pregunta

        })

        return parteCuestionario.tipo === 'maloToExelente';

    }),

    partesCuestionarioSeleccionMultiple = props.climaOrganizacional.cuestionario.partesCuestionario.filter(parteCuestionario => {

        let preguntas = parteCuestionario.preguntas,
        idsPreguntas = preguntas.map( pregunta => pregunta.id),
        respuestasArray = encuestados.map( encuestado => encuestado.respuestas ),
        respuestas = Array.prototype.concat.apply([], respuestasArray),
        respuestasParteCuestionario = respuestas.filter( respuesta => idsPreguntas.includes(respuesta.idPregunta) )

        parteCuestionario.preguntas.map( pregunta => {

            let respuestasParteCuestionarioOfRespuesta = respuestasParteCuestionario.filter( respuesta => {
                return respuesta.idPregunta === (pregunta.id)
            }),
                respuestasParteCuestionarioTrue = respuestasParteCuestionarioOfRespuesta.filter( respuesta => respuesta.respuesta === "true"),
                respuestasParteCuestionarioFalse = respuestasParteCuestionarioOfRespuesta.filter( respuesta => respuesta.respuesta === "false")

            pregunta.respuestasTrue = respuestasParteCuestionarioTrue
            pregunta.respuestasFalse = respuestasParteCuestionarioFalse
            pregunta.respuestas = respuestasParteCuestionarioOfRespuesta

            return pregunta

        })

        return parteCuestionario.tipo === 'seleccionMultiple';
    })

    if(encuestados.length > 0){
        return (
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-outline-warning mx-auto mb-5" onClick={ () => {
                        props.changeDisplay("SelectGraficas")
                    }}>Regresar al Menu de Graficas <FontAwesomeIcon icon="chart-bar"/></button>
                    <MaloToExelente partesCuestionarioMaloToExelente={partesCuestionarioMaloToExelente}/>
                    <SeleccionMultiple partesCuestionarioSeleccionMultiple={partesCuestionarioSeleccionMultiple}/>
                </div>
            </div>
        )
    }else{
        return (
            <div className="row">
                <Loader />
                <div className="col-12">
                    <h3 className="text-center">Espera a que se calculen los datos.</h3>
                </div>
            </div>
        )
    }

}

export default BenificiosDeLaEmpresa