let consulta = (NuevaEtiqueta) => {
  
    const string =
      `mutation insertEtiquetaPuesto {
        insertEtiquetaPuesto(etiqueta: "${NuevaEtiqueta}"){
          id
          mensaje
        }
      }`
  
      return {
        "query": string
      }
  
  }
  
  export default consulta