let consulta = (idClima) => {
    let string = `{
      appInfo{
        getIndicadoresDeMedicionSatisfaccionGeneral(idClima: "${idClima}"){
          nombre
          porcentajeIndicador
          variables{
            nombre
            descripcion
            factoresEvaluados{
              nombre
              positiva
            }
            porcentajeVariable
            preguntas{
              id
              factorEvaluado
              orden
              porcentajePregunta
              pregunta
              respuestas{
                id
                idPregunta
                respuesta
              }
            }
          }
        }
      }
    }`
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;