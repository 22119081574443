import React, { useState, useEffect } from 'react';

import FetchGraphQLGetIndicadoresDeMedicionSatisfaccionGeneral from '../../../Containers/FetchGetIndicadoresDeMedicionSatisfaccionGeneral/FetchGraphQLGetIndicadoresDeMedicionSatisfaccionGeneral';

import Loader from '../../../../../Components/LoaderSinLogo';

import AreasDeAnalisis from "./AreasDeAnalisis";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let AreasDeAnalisisComponent = props => {

    let [indicadoresDeMedicionConRespuestasYPorentaje, setIndicadoresDeMedicionConRespuestasYPorentaje] = useState([])

    useEffect( ()=> {
        FetchGraphQLGetIndicadoresDeMedicionSatisfaccionGeneral(setIndicadoresDeMedicionConRespuestasYPorentaje,  props.climaOrganizacional.id)
    }, [props])

    if(indicadoresDeMedicionConRespuestasYPorentaje.length > 0){
        return (
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-outline-warning mx-auto mb-5" onClick={ () => {
                        props.changeDisplay("SelectGraficas")
                    }}>Regresar al Menu de Graficas <FontAwesomeIcon icon="chart-bar"/></button>
                </div>
                <AreasDeAnalisis indicadoresDeMedicionConRespuestasYPorentaje={indicadoresDeMedicionConRespuestasYPorentaje} />
            </div>
        )
    }else{
        return (
            <div className="row">
                <Loader />
                <div className="col-12">
                    <h3 className="text-center">Espera a que se calculen los datos.</h3>
                </div>
            </div>
        )
    }

}

export default AreasDeAnalisisComponent