let consulta = (data) => {

    let string = `mutation{
      deleteEncuesta(idClima: "${data.idClima}", idEncuesta: "${data.idEncuesta}"){
        idEncuesta
        mensaje
      }
    }`

    return {
      "query": string
    }
  
  }
  
  export default consulta;