import React, { useState, useEffect } from 'react';

import Popup from 'react-popup';

import 'react-popup/style.css';

let MaloToExelente = props => {

    let [respuestas, setRespuestas] = useState([])

    useEffect( ()=> {

        let respuestas = props.preguntas.map( pregunta => {

            let data

            if((pregunta.tipo !== undefined) && (pregunta.tipo === "preguntaAbierta")){
                data = {
                    idPregunta: pregunta.id,
                    respuesta: "",
                    tipo: "preguntaAbierta"
                }
            }else{
                data = {
                    idPregunta: pregunta.id,
                    respuesta: ""
                }
            }

            return data

        })

        setRespuestas(respuestas)

    }, [props.preguntas, props.preguntaAbierta])

    let onInputChange = (idPregunta, value) => {

        let newRespuestas = [
            ...respuestas
        ]

        let respuestaFiltered = newRespuestas.filter( respuesta => respuesta.idPregunta === idPregunta )[0]

        respuestaFiltered.respuesta = value

        newRespuestas.find(o => o.id === respuestaFiltered.id)

        setRespuestas(newRespuestas)

    }

    //console.log(props.preguntas)

    return(
        <div className="row">
            <div className="col-12">
                <p className="text-justify">
                    {props.preguntaGeneral}
                </p>
            </div>

            {
                props.preguntas.length > 0 ? (
                    <div className="table-responsive text-center">
                        <table className="table text-center table-bordered table-hover table-sm">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="d-none d-md-table-cell">No.</th>
                                    <th scope="col">Pregunta</th>
                                    <th scope="col">Respuestas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.preguntas.map( pregunta => (
                                        <tr key={pregunta.id}>
                                            <td className="d-none d-md-table-cell">{pregunta.orden}</td>
                                            <td>{pregunta.pregunta}</td>
                                            {
                                                pregunta.tipo === "preguntaAbierta" ? (
                                                    <td>
                                                       {
                                                          (respuestas.filter( respuesta => respuesta.idPregunta === pregunta.id)[0] !== undefined) ? (
                                                                <input className="form-control" value={respuestas.filter( respuesta => respuesta.idPregunta === pregunta.id)[0].respuesta} onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } placeholder="Escribe tu respuesta" />
                                                           ) : null
                                                        } 
                                                    </td>
                                                    ) : (
                                                    <td>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`Exelente-${pregunta.id}`} value="0" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`Exelente-${pregunta.id}`}> Exelente</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`Bueno-${pregunta.id}`} value="1" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`Bueno-${pregunta.id}`}> Bueno</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`Regular-${pregunta.id}`} value="2" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`Regular-${pregunta.id}`}> Regular</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name={`pregunta-${pregunta.id}`} id={`Malo-${pregunta.id}`} value="3" onChange={ (event)=> onInputChange(pregunta.id, event.target.value) } /><br/>
                                                            <label className="form-check-label" htmlFor={`Malo-${pregunta.id}`}> Malo</label>
                                                        </div>
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        
                        <button className="btn btn-outline-info mb-5" onClick={() => {

                            Popup.create({
                                title: 'Guardar Respuestas',
                                content: '¿Desea Guardar las Respuestas?',
                                buttons: {
                                    left: [{
                                        text: 'Cancelar',
                                        className: 'danger',
                                        action: function () {
                                            /** Close this popup. Close will always close the current visible one, if one is visible */
                                            Popup.close();
                                        }
                                    }],
                                    right: [{
                                        text: 'Guardar',
                                        className: 'success',
                                        action: function () {
                                            props.onSaveRespuestas(respuestas, 'MaloToExelente', () => {

                                                document.querySelector("#alert-smile").classList.remove("d-none")
                
                                                setTimeout( () => { 
                                                    document.querySelector("#alert-smile").classList.add("d-none") 
                                                }, 3000);
                
                                            })
                                            /** Close this popup. Close will always close the current visible one, if one is visible */
                                            Popup.close();
                                        }
                                    }]
                                }
                            })

                        }}>Guardar Respuestas</button>
                        <Popup />
                    </div>

                ) : null
            }

            <div className="col-12 mt-2">
                <div id="alert-smile" className="alert alert-info text-center d-none" role="alert">
                    Tienes que contestar todas las preguntas.
                </div>
            </div>

            <div className="col-12">
                <p className="text-center">Página {props.objectPagination.currentPage} de {props.objectPagination.totalPages}</p>
            </div>

        </div>
    )

}

export default MaloToExelente