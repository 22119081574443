import React, { Component } from 'react';

//Impotando Component
import FormacionCapacitacionYAdiestramientoLayOut from '../Components/FormacionCapacitacionYAdiestramientoLayOut.jsx';
import InfoCard from '../../InfoCard/InfoCard.jsx';
import Card from '../../Card/Card.jsx';
import Modal from '../../Modal/Modal.jsx';

import Json from './FormacionCapacitacionYAdiestramiento.json';

//Importando Librerías CSS
import 'animate.css';
import '../../../../../MyModules/anicollection.css';

let InfoCardText = {
	'titulo': 'FORMACIÓN, CAPACITACIÓN Y ADIESTRAMIENTO',
	'texto': 'La formación profesional, la capacitación y el adiestramiento técnico de los colaboradores son vitales, especialmente si los objetivos y la estrategia de las organizaciones están enfocados en el crecimiento. La competitividad y el desempeño de la empresa puede potenciarse, de forma significativa, mediante procesos de formación, capacitación y adiestramiento diseñados para maximizar el potencial de desempeño del personal, los equipos de trabajo y la organización en su conjunto.'
}

class FormacionCapacitacionYAdiestramiento extends Component {

	componentDidMount() {

		window.scrollTo(0, 0)
	}

	render(){
		return(
			<FormacionCapacitacionYAdiestramientoLayOut path={this.props.path}>
				<InfoCard data={InfoCardText} />
				<Card data={Json}/>
				<Modal data={Json}/>
			</FormacionCapacitacionYAdiestramientoLayOut>
		)
	}
}

export default FormacionCapacitacionYAdiestramiento
