import React from 'react';

import { Bar } from 'chartjs-2-react';

import { LabelsColorsGraph } from '../utils';

let SatisfaccionGeneralGruposDeOpinion = props => {

    //Actual sin Procesar
    let gruposDeOpinionArr = props.areasDeTrabajoConRespuestasYPorentajeActual.map( areaDeTrabajo => areaDeTrabajo.gruposDeOpinion)
    let gruposDeOpinion = Array.prototype.concat.apply([], gruposDeOpinionArr)
    /*gruposDeOpinion = gruposDeOpinion.map( grupo => ({
        ...grupo,
        porcentaje: grupo.porcentaje ? grupo.porcentaje : grupo.porcentajeSatisfaccionGrupoDeOpinion,
    }))*/

    //Comparado sin Procesar
    let gruposDeOpinionComparadoArr = props.areasDeTrabajoConRespuestasYPorentajeComparado.map( areaDeTrabajo => areaDeTrabajo.gruposDeOpinion)
    let gruposDeOpinionComparado = Array.prototype.concat.apply([], gruposDeOpinionComparadoArr) 
    /*gruposDeOpinionComparado = gruposDeOpinionComparado.map( grupo => ({
       ...grupo,
        porcentaje: grupo.porcentaje ? grupo.porcentaje : grupo.porcentajeSatisfaccionGrupoDeOpinion
    }))*/

    const dataClimaActual = props.dataProcesadaActual ? {
        labels: [...props.dataProcesadaActual.satisfaccionGeneralPorGruposDeOpinion.map(grupoDeOpinion => grupoDeOpinion.nombre)],
        data: [...props.dataProcesadaActual.satisfaccionGeneralPorGruposDeOpinion.map(grupoDeOpinion => grupoDeOpinion.porcentaje)]
    } : {
        labels: [...gruposDeOpinion.map( grupoDeOpinion => grupoDeOpinion.nombre )],
        data: [...gruposDeOpinion.map(grupoDeOpinion => (grupoDeOpinion.porcentajeSatisfaccionGrupoDeOpinion * 100).toFixed(2))]
    }

    const dataClimaComparado = props.dataProcesadaComparado ? {
        labels: [...props.dataProcesadaComparado.satisfaccionGeneralPorGruposDeOpinion.map(grupoDeOpinion => grupoDeOpinion.nombre)],
        data: props.dataProcesadaActual ? [...props.dataProcesadaActual.satisfaccionGeneralPorGruposDeOpinion.map(grupo => {
            const grupoDeOpinion = props.dataProcesadaComparado.satisfaccionGeneralPorGruposDeOpinion.find( grupoDeOpinion => grupoDeOpinion.nombre === grupo.nombre)
            return grupoDeOpinion ? grupoDeOpinion.porcentaje : null
        })] : [...gruposDeOpinion.map(grupo => {
            const grupoDeOpinion = props.dataProcesadaComparado.satisfaccionGeneralPorGruposDeOpinion.find( grupoDeOpinion => grupoDeOpinion.nombre === grupo.nombre)
            return grupoDeOpinion ? grupoDeOpinion.porcentaje : null
        })]
    } : {
        labels: [...gruposDeOpinionComparado.map( grupoDeOpinion => grupoDeOpinion.nombre )],
        data: props.dataProcesadaActual ? [...props.dataProcesadaActual.satisfaccionGeneralPorGruposDeOpinion.map(grupo => {
            const grupoDeOpinion = gruposDeOpinionComparado.find( grupoDeOpinion => grupoDeOpinion.nombre === grupo.nombre)
            return grupoDeOpinion ? (grupoDeOpinion.porcentajeSatisfaccionGrupoDeOpinion * 100).toFixed(2) : null
        })] : [...gruposDeOpinion.map(grupo => {
            const grupoDeOpinion = gruposDeOpinionComparado.find( grupoDeOpinion => grupoDeOpinion.nombre === grupo.nombre)
            return grupoDeOpinion ? (grupoDeOpinion.porcentajeSatisfaccionGrupoDeOpinion * 100).toFixed(2) : null
        })]
    }

    return(
        <div className="col-12">
            <h2 className="text-center">Gráfica General de Resultados de Satisfacción Por Grupos de Opinión Comparativa</h2>
            <Bar config={{
                data: {
                    labels: [...dataClimaActual.labels],
                    datasets: [{
                        label: props.nombreClimaActual,
                        data: [...dataClimaActual.data],
                        backgroundColor: "#EABF2D",
                        borderColor: 'none'
                    },{
                        label: props.nombreClimaComparado,
                        data: dataClimaComparado.data,
                        backgroundColor: "#E88D46",
                        borderColor: 'none'
                    }],
                },
                options: {
                    title: {
                        display: true,
                        text: "Porcentajes de Satisfacción %"
                    },
                    responsive: true,
                    legend: {
                        display: true
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                callback: (value, index, values) => {
                                    return value + '%'
                                },
                                beginAtZero: true,
                                max: 100
                            }
                        }]
                    },
                    animation: {
                        duration: 1,
                        onComplete: function() {
                            var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';
            
                            this.data.datasets.forEach(function(dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function(bar, index) {
                                var data = dataset.data[index];
                                ctx.fillText(data + '%', bar._model.x, bar._model.y - 5);
                            });
                        });
                        }
                    }
                }
            }}/>
            <LabelsColorsGraph />
        </div>
    )
}

export default SatisfaccionGeneralGruposDeOpinion