import React, { Component } from 'react';

import FetchGetEncuestado from './FetchGetEncuestado/FetchGraphQLGetEncuestado';
import FetchInsertRespuestas from './FetchNewRespuestas/FetchGraphQLInsertRespuestas';

import Loader from '../../../Components/LoaderSinLogo';

import Cuestionario from '../Components/Cuestionario';
import Footer from '../../../Inicio/Footer/Components/Footer';

class ResponderEncuesta extends Component {

    constructor(props) {
        super(props);

        // No llames this.setState() aquí!
        this.state = { 
            validToken: false,
            token: "",
            error: false,
            loading: false,
            encuestaActiva: null,
            cuestionario: null,
            respuestas: []
        };
        
        this.handleSubmitGetToken = this.handleSubmitGetToken.bind(this);
        this.onInputTokenChange = this.onInputTokenChange.bind(this);
        this.onSaveRespuestas = this.onSaveRespuestas.bind(this);
    }

    handleSubmitGetToken(event){

        event.preventDefault()

        let data = {
            idClima: this.props.params.idClima,
            token: this.state.token
        }

        this.setState({loading: true})

        FetchGetEncuestado(this, data)

    }

    onInputTokenChange(event){
        this.setState({
            [event.target.id]: event.target.value.split(' ').join('')
        })
    }

    async onSaveRespuestas(respuestas, type, cb){

        let data = null

        this.setState({loading: true})

        if((type === 'EncuestaSmile') || (type === 'MaloToExelente')){

            let respuestasAllRespondidas = respuestas.filter( respuesta => {

                let returnRespuestas

                if(respuesta.tipo !== 'preguntaAbierta'){
                    returnRespuestas = respuesta.respuesta === ''
                }

                return returnRespuestas

            }).length === 0

            if(respuestasAllRespondidas === true){

                data = {
                    idClima: this.props.params.idClima,
                    token: this.state.token,
                    respuestas: respuestas.map( respuesta => ({
                        idPregunta: respuesta.idPregunta,
                        respuesta: respuesta.respuesta
                    }))
                }
        
                FetchInsertRespuestas(this, data)
                
            }else{

                this.setState({loading: false})
                cb()

            }

        }else if(type === 'TiempoDeLaborarEnLaEmpresa'){

            let respuestaRespondida = respuestas.filter( respuesta => respuesta.respuesta === true)

            if(respuestaRespondida.length === 1){

                await respuestas.forEach(respuesta => {
                    if(respuesta.respuesta === false){
                        respuesta.respuesta = "false"
                    }else if(respuesta.respuesta === true){
                        respuesta.respuesta = "true"
                    }
                });

                data = {
                    idClima: this.props.params.idClima,
                    token: this.state.token,
                    respuestas: respuestas.map( respuesta => ({
                        idPregunta: respuesta.idPregunta,
                        respuesta: respuesta.respuesta
                    }))
                }
        
                FetchInsertRespuestas(this, data)

            }else{

                this.setState({loading: false})
                cb()

            }

        }else{

            data = {
                idClima: this.props.params.idClima,
                token: this.state.token,
                respuestas
            }
    
            FetchInsertRespuestas(this, data)

        }



    }

    render(){

        if(this.state.cuestionario === null){
            return(
                <div className="container">
                    <div className="col-12 col-md-10 col-lg-7 mx-auto mt-5">
                        <img className="img-fluid" src="/img/svg/Logo.svg" alt="logoImg"/>
                    </div>
                    {
                        <form className="form-row mx-auto text-center mt-5" onSubmit={this.handleSubmitGetToken}>
                            <div className="form-group col-12 col-md-6 col-lg-5 mx-auto">
                                <label htmlFor="token">Token: </label>
                                <input type="text" className="form-control" id="token" aria-describedby="tokenHelp" onChange={this.onInputTokenChange} placeholder="Escribe el Token" value={this.state.token}/>
                                <small id="tokenHelp" className="form-text text-muted">Escribe el token que se te ha mandado por correo</small>
                            </div>
                            {
                                this.state.loading === false ? (
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-outline-warning">Empezar</button>
                                    </div>
                                ) : null
                            }

                            {
                                this.state.loading === true ? (
                                    <div className="col-12">
                                        <Loader />
                                    </div>
                                ) : null
                            }

                            {
                                this.state.error === true ? (
                                    <div className="alert alert-danger mx-auto" role="alert">
                                        Hubo un error. Prueba Nuevamente
                                    </div>
                                ) : null
                            }
                        </form>
                    }
                    <Footer />
                </div>
            )
        }else if((this.state.cuestionario !== null) && (this.state.encuestaActiva !== null) ){
            if(this.state.encuestaActiva === true){
                return <Cuestionario cuestionario={this.state.cuestionario} respuestas={this.state.respuestas} onSaveRespuestas={this.onSaveRespuestas} loading={this.state.loading}/>
            }else{
                return <div className="container">
                    <div className="col-12 col-md-10 col-lg-7 mx-auto mt-5">
                        <img className="img-fluid" src="/img/svg/Logo.svg" alt="logoImg"/>
                    </div>
                    <div className="col-12 mt-5">
                        <h1 className="text-center">La encuesta no está activa aún</h1>
                    </div>
                </div>
            }
        }else{
            return null
        }

    }

}

export default ResponderEncuesta