import React from 'react';

let setColor = value => {

    let color

    if(value >= 90){
        color = "#066A08"
    }else if((value >= 80) && (value < 90)){
        color = "#09A30D"
    }else if((value >= 75) && (value < 80)){
        color = "#5CC422"
    }else if((value >= 70) && (value < 75)){
        color = "#DBDF35"
    }else if((value >= 68) && (value < 70)){
        color = "#FFFF00"
    }else if((value >= 65) && (value < 68)){
        color = "#FFC000"
    }else if((value >= 61) && (value < 65)){
        color = "#E46C0A"
    }else if((value >= 0) && (value < 61)){
        color = "#FF0000"
    }

    return color

}

let LabelsColorsGraph = props => (
    <ul className="list-inline mt-3">
        <li className="list-inline-item"><div style={{width: '15px', height: '15px', backgroundColor: '#066A08', float: 'left', marginRight: '5px', marginTop: '5px'}} /> (90%-100%)</li>
        <li className="list-inline-item"><div style={{width: '15px', height: '15px', backgroundColor: '#09A30D', float: 'left', marginRight: '5px', marginTop: '5px'}} /> (80%-89%)</li>
        <li className="list-inline-item"><div style={{width: '15px', height: '15px', backgroundColor: '#5CC422', float: 'left', marginRight: '5px', marginTop: '5px'}} /> (75%-79%)</li>
        <li className="list-inline-item"><div style={{width: '15px', height: '15px', backgroundColor: '#DBDF35', float: 'left', marginRight: '5px', marginTop: '5px'}} /> (70%-74%)</li>
        <li className="list-inline-item"><div style={{width: '15px', height: '15px', backgroundColor: '#FFFF00', float: 'left', marginRight: '5px', marginTop: '5px'}} /> (68%-69%)</li>
        <li className="list-inline-item"><div style={{width: '15px', height: '15px', backgroundColor: '#FFC000', float: 'left', marginRight: '5px', marginTop: '5px'}} /> (65%-67%)</li>
        <li className="list-inline-item"><div style={{width: '15px', height: '15px', backgroundColor: '#E46C0A', float: 'left', marginRight: '5px', marginTop: '5px'}} /> (61%-64%)</li>
        <li className="list-inline-item"><div style={{width: '15px', height: '15px', backgroundColor: '#FF0000', float: 'left', marginRight: '5px', marginTop: '5px'}} /> (50%-60%)</li>
    </ul>
)

let setRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

let setRandomColorFromListOfColors = (list) => {
    let colors = list
    var random_color = colors[Math.floor(Math.random() * colors.length)];
    return random_color
}

export { setColor, LabelsColorsGraph, setRandomColor, setRandomColorFromListOfColors }