let consulta = (data) => {

    let string = `mutation{
      changeEncuestaActivaClima(idClima: "${data.idClima}", encuestaActiva: ${data.encuestaActiva}){
        encuestaActiva
      }
    }`

    return {
      "query": string
    }
  
  }
  
  export default consulta;