import React, { Component } from 'react';

//Impotando Component
import ContactoLayOut from '../Components/ContactoLayOut.jsx';

//importando Librerías Javascript
import { jarallax, jarallaxElement, jarallaxVideo } from '../../../../MyModules/jarallax-1.10.4'; //Pasar Jarallax a MyModules

//import { NavLink } from 'react-router-dom';

//import withScrollReveal from 'react-scrollreveal/dist-es6/index.js';

//Importando Librerías CSS
import 'animate.css';
import '../../../../MyModules/anicollection.css';

class Contacto extends Component {

	componentDidMount() {
		jarallaxVideo();
		jarallaxElement();

		jarallax(document.querySelectorAll('#contacto'), {
			speed: 0.2
		});
	}

	FetchEnviaMensaje(e) {

		function handleError(err){
			console.log(`Request failed: ${err}`);
		}

		e.preventDefault();

		let nombre = document.querySelector('#nombreContacto').value,
			correo = document.querySelector('#correoContacto').value,
			tel = document.querySelector('#telContacto').value,
			mensaje = document.querySelector('#mensajeContacto').value,
			serializeinfo = `nombre=${nombre}&correo=${correo}&tel=${tel}&mensaje=${mensaje}`;

		const options = {
			method: 'POST',
			headers: {
				"Content-type": "application/x-www-form-urlencoded"
			},
			body: serializeinfo,
			credentials: 'include'
		}

		document.querySelector(".sending").style.display = 'block'

		//llamada de datos con Fetch
		fetch('https://blconsultores.com.gt/mail/contacto', options)//http://localhost:8080/mail/contacto
		//fetch('https://dev-back-end.p-lao.com/mail/contacto', options)
		.then( res => {
			if(res.status === 200){
				document.querySelector(".successContact").style.display = 'block';
				document.querySelector(".sending").style.display = 'none';
				document.querySelector(".errorContact").style.display = 'none';
			}else{
				document.querySelector(".errorContact").style.display = 'block';
				document.querySelector(".sending").style.display = 'none';
				document.querySelector(".successContact").style.display = 'none';
			}
		})
		.catch(err => handleError(err));

	}

	render(){
		return(
			<ContactoLayOut>
				<div id="container-contacto">
					<div className="col-lg-12">
					   <form name="sentMessage" id="contactForm" acceptCharset="utf-8" onSubmit={this.FetchEnviaMensaje}>
							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<input type="text" className="form-control" placeholder="Nombre *" id="nombreContacto" required />
									</div>
									<div className="form-group">
										<input type="email" className="form-control" placeholder="Correo *" id="correoContacto" required />
									</div>
									<div className="form-group">
										<input type="tel" className="form-control" placeholder="Teléfono Para Contacto *" id="telContacto" required />
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<textarea className="form-control" placeholder="Mensaje *" id="mensajeContacto" required></textarea>
									</div>
								</div>
								<div className="clearfix"></div>
								<div className="col-lg-12 text-center">
									<button type="submit" className="btn btn-xl btn-outline-light">Enviar </button>
									<div className="successContact alert alert-success text-center mx-auto" role="alert">Tu mensaje ha sido Enviado</div>
									<div className="sending alert alert-info text-center mx-auto" role="alert">Tu mensaje se está enviando, por favor espera un momento</div>
									<div className="errorContact alert alert-danger text-center mx-auto" role="alert">Ha habido un error de envío. Tu mensaje no ha sido Enviado. Por favor prueba nuevamente</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className="row">
					<iframe title="MapaBL" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1930.4858363805358!2d-90.51584581349178!3d14.600689576419656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a3cfe50851dd%3A0x88feb91d04fba381!2sReforma+Mont%C3%BAfar%2C+12+Calle+1201%2C+Guatemala+01010%2C+Guatemala!5e0!3m2!1ses-419!2s!4v1438549242980" width="#" height="#" frameBorder="0" style={{border: '0px'}} allowFullScreen className="mapa col-sm-12"></iframe>
				</div>
				<p className="text-center">
					<br/>Av. Reforma 12-01, Z. 10 Edif. Reforma Montufar Of. 607<br/><br/>
					e-mail: beatrizlembke@blconsultores.com.gt<br/>
					e-mail: blcurricula@blconsultores.com.gt<br/><br/>
					Teléfonos:<a href="tel:50223318411">+(502)2331-8411</a>/<a href="tel:50250176584">+(502)5017-6584</a>
				</p>
				<div className="overlay"></div>
			</ContactoLayOut>
		)
	}
}

export default Contacto
