// JavaScript Document
import GraphQL from './ConsultaGraphQLDataInfo';

import url from './Url.js';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchUsers = (This) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL()),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.props.dispatch({
			type: 'FETCH_DATA_INFO',
			payload: {
				dataInfo: res.data.appInfo
			}
		})

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchUsers;