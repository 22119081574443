import React from 'react';

import { Bar } from 'chartjs-2-react';

let MaloToExelente = props => {
    return (
        <div className="col-12">
            {
               props.partesCuestionarioSeleccionMultiple.map( (parteCuestionario, i) => (
                    <div key={i} className="my-3">
                        <h2 className="text-center">{parteCuestionario.variable}</h2>
                        <Bar config={{
                            data: {
                                labels: [...parteCuestionario.preguntas.map( pregunta => pregunta.pregunta )],
                                datasets: [
                                    {
                                        //label: "",
                                        data: [...parteCuestionario.preguntas.map(pregunta => ((pregunta.respuestasTrue.length / pregunta.respuestas.length) * 100).toFixed(2))],
                                        backgroundColor: "#EABF2D",
                                        borderColor: 'none'
                                    }
                                ],
                            },
                            options: {
                                title: {
                                    display: false,
                                    //text: "Porcentajes de Satisfacción %"
                                },
                                responsive: true,
                                legend: {
                                    display: false
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            callback: (value, index, values) => {
                                                return value + '%'
                                            },
                                            beginAtZero: true,
                                            //max: 100
                                        }
                                    }]
                                },
                                animation: {
                                    duration: 1,
                                    onComplete: function() {
                                        var chartInstance = this.chart,
                                        ctx = chartInstance.ctx;
                                        ctx.textAlign = 'center';
                                        ctx.textBaseline = 'bottom';
                        
                                        this.data.datasets.forEach(function(dataset, i) {
                                        var meta = chartInstance.controller.getDatasetMeta(i);
                                        meta.data.forEach(function(bar, index) {
                                            var data = dataset.data[index];
                                            ctx.fillText(data + '%', bar._model.x, bar._model.y - 5);
                                        });
                                    });
                                    }
                                }
                            }
                        }}/>
                    </div> 
                )) 
            }
        </div>
    )
}

export default MaloToExelente