import config from './config';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/Containers/App.jsx';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { createStore } from 'redux'; //, applyMiddleware //Agregar compose con typescript
import { composeWithDevTools as composeWithDevToolsWeb } from 'redux-devtools-extension';
import { composeWithDevTools as composeWithDevToolsVSCode } from 'remote-redux-devtools';

import { Provider } from 'react-redux';

import reducer from './reducers/index';

//Redux DevTools
/*declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}*/

let store

if(((config.envType) && (config.envType === 'dev')) && (config.redux.remoteDevTools === true)){

  const composeEnhancers = composeWithDevToolsVSCode({ realtime: true, port: config.redux.remotePort, hostname: config.redux.remoteHostname, secure: true });

  store = createStore(
    reducer,//(state) => state,
    {},
    composeEnhancers()
  )

}else if( (config.envType) && (config.envType === 'dev') ){

  const composeEnhancers = composeWithDevToolsWeb({
    // Specify here name, actionsBlacklist, actionsCreators and other options
  });

  store = createStore(
    reducer,//(state) => state,
    {},
    composeEnhancers()
  )

}else{
  store = createStore(
    reducer,//(state) => state,
    {}
  );
}

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
