import React from 'react';

//importando Estilos
import './css/FormacionCapacitacionYAdiestramiento.css';

import Navbar from '../../../Navbar/Containers/Navbar.jsx';
import Footer from '../../../Footer/Components/Footer.jsx';

let FormacionCapacitacionYAdiestramientoLayOut = props => (
	<div>
		<div id="formacion-capacitacion-y-adiestramiento" className="container-fluid servicio-section">
			<Navbar path={props.path}/>
			<div id="CapacitacionContainer" className="row d-flex servicio-container">
				{props.children}
			</div>
		</div>
		<Footer />
	</div>
)

export default FormacionCapacitacionYAdiestramientoLayOut