import React, { Component } from 'react';

//Impotando Component
import PortadaLayOut from '../Components/PortadaLayOut.jsx';

import { NavHashLink } from 'react-router-hash-link';

//importando Librerías Javascript
import { jarallax, jarallaxElement, jarallaxVideo } from '../../../../MyModules/jarallax-1.10.4'; //comentar $parent.parentNode.removeChild($parent); en archivo Pasar Jarallax a MyModules node_modules/jarallax/src/jarallax-video.esm.js: Linea38

import ScrollReveal from 'scrollreveal';

//Importando Jquery
import $ from 'jquery';

//Importando Librerías CSS
import 'animate.css';
import '../../../../MyModules/anicollection.css';


//Importando Assets
import Video from '../../../../assets/video/videofondo.mp4';

class Portada extends Component {

	componentDidMount() {

		jarallaxVideo();
		jarallaxElement();

		jarallax(document.querySelectorAll('#inicio'), {
			speed: 0.2,
			videoSrc: `mp4:${Video}`
		});

		ScrollReveal().reveal('#logoPortada', {
			distance: '80px',
			origin: 'bottom',
			reset: true,
			delay: 300
		});

		/*ScrollReveal().reveal('#tituloPortada', {
			distance: '80px',
			origin: 'left',
			reset: true,
			delay: 800
		});*/

		ScrollReveal().reveal('#portada-link', {
			distance: '120px',
			origin: 'top',
			reset: true,
			delay: 1000
		});

	}

	toScroll(el) {
	  //console.log(el.id);
  	  $('html, body').stop().animate({
  	  	scrollTop: $(`#${el.id}`).offset().top
  	  }, 1500, 'easeInOutExpo');
    }

	render(){
		return(
			<PortadaLayOut>
				<div className="mx-auto col-10 col-md-8 col-lg-6 col-xl-5" style={{padding: '0px'}}>
					<div id="logoPortadaContainer" className="mx-auto col-12">
						{/*<img id="logoPortada" src={Logo} className="img-responsive mx-auto" alt="Logo-BL-Consultores-Blanco"/>*/}
						<img id="logoPortada" src="img/svg/logoBlanco.svg" className="img-responsive mx-auto" alt="Logo-BL-Consultores-Blanco"/>
					</div>
					{/*<h1 id="tituloPortada" className="text-center col-12">Desarrollando Talento</h1>*/}
					<NavHashLink id="portada-link" to="#servicios" className="text-center col-12" scroll={this.toScroll}><button className="btn btn-outline-light rounded-circle"><span>&#8595;</span></button></NavHashLink>
				</div>
				<div className="overlay"></div>
			</PortadaLayOut>
		)
	}
}

export default Portada
