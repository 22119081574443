import React, { useState, useEffect } from 'react';

import Popup from 'react-popup';

import 'react-popup/style.css';

let TiempoDeLaborarEnLaEmpresa = props => {

    let [respuestas, setRespuestas] = useState([])

    useEffect( ()=> {

        let respuestas = props.preguntas.map( pregunta => {

            let data = {
                idPregunta: pregunta.id,
                respuesta: false
            }

            return data

        })

        setRespuestas(respuestas)

    }, [props.preguntas, props.preguntaAbierta])

    let onInputChange = (idPregunta, event) => {

        let newRespuestas = [
            ...respuestas
        ]

        let newRespuestasAllToFalse = newRespuestas.map( respuesta => {
            return {
                idPregunta: respuesta.idPregunta,
                respuesta: false
            }
        })

        let respuestaFiltered = newRespuestasAllToFalse.filter( respuesta => respuesta.idPregunta === idPregunta )[0]

        respuestaFiltered.respuesta = true

        newRespuestasAllToFalse.find(o => o.id === respuestaFiltered.id)

        setRespuestas(newRespuestasAllToFalse)

    }

    //console.log(respuestas)

    return(
        <div className="row">
            <div className="col-12">
                <p className="text-justify">
                    {props.preguntaGeneral}
                </p>
            </div>

            {
                props.preguntas.length > 0 ? (
                    <div className="table-responsive text-center">
                        <table className="table text-center table-bordered table-hover table-sm">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="d-none d-md-table-cell">No.</th>
                                    <th scope="col">Selección</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.preguntas.map( pregunta => (
                                        <tr key={pregunta.id}>
                                            <td className="d-none d-md-table-cell">{pregunta.orden}</td>
                                            <td>

                                                {
                                                    (respuestas.filter( respuesta => respuesta.idPregunta === pregunta.id)[0] !== undefined) ? (
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" checked={respuestas.filter(respuesta => respuesta.idPregunta === pregunta.id)[0].respuesta} id={`${pregunta.id}-checkbox`} onChange={ (event)=> onInputChange(pregunta.id, event) }  />
                                                            <label className="form-check-label" htmlFor={`${pregunta.id}-checkbox`}>
                                                                {pregunta.pregunta}
                                                            </label>
                                                        </div>
                                                    ) : null
                                                }

                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <button className="btn btn-outline-info mb-5" onClick={ async () => {

                            Popup.create({
                                title: 'Guardar Respuestas',
                                content: '¿Desea Guardar las Respuestas?',
                                buttons: {
                                    left: [{
                                        text: 'Cancelar',
                                        className: 'danger',
                                        action: function () {
                                            /** Close this popup. Close will always close the current visible one, if one is visible */
                                            Popup.close();
                                        }
                                    }],
                                    right: [{
                                        text: 'Guardar',
                                        className: 'success',
                                        action: async function () {

                                            props.onSaveRespuestas(respuestas, 'TiempoDeLaborarEnLaEmpresa', () => {

                                                document.querySelector("#alert-smile").classList.remove("d-none")
                    
                                                setTimeout( () => { 
                                                    document.querySelector("#alert-smile").classList.add("d-none") 
                                                }, 3000);
                    
                                            })

                                            /** Close this popup. Close will always close the current visible one, if one is visible */
                                            Popup.close();
                                        }
                                    }]
                                }
                            })

                        }}>Guardar Respuestas</button>
                        <Popup />
                    </div>
                ) : null
            }

            <div className="col-12 mt-2">
                <div id="alert-smile" className="alert alert-info text-center d-none" role="alert">
                    Tienes que Escoger alguna.
                </div>
            </div>

            <div className="col-12">
                <p className="text-center">Página {props.objectPagination.currentPage} de {props.objectPagination.totalPages}</p>
            </div>

        </div>
    )

}

export default TiempoDeLaborarEnLaEmpresa