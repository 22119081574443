//funciones de React
import React, { Component } from 'react';

import { connect } from 'react-redux';

import sessionService from '../../sessionService/sessionService';

import url from '../../ApiService/Url.js';

import Loader from '../Components/Loader.jsx';

//Importando Jquery
//import $ from 'jquery';
import '../../MyModules/jquery.easing.1.3.js';

//Importando Bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//Importando MyModules
//import './MyModules/scrollspy.js';
import '../../MyModules/anchor-ease.js';
//import './MyModules/agency.js';

//Router
import { BrowserRouter as Router } from 'react-router-dom';

//Components
import PrincipalRoutes from '../Components/PrincipalRoutes.jsx';

//importando fontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTachometerAlt, faIdCard, faEye, faTrashAlt, faEdit, faPeopleArrows, faEnvelopeOpenText, faGrin, faSmile, faMeh, faFrown, faArrowLeft, faList, faChartBar, faCheck, faTrash, faHistory } from '@fortawesome/free-solid-svg-icons';

import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(faSignOutAlt, faTachometerAlt, faIdCard, fab, faEye, faTrashAlt, faEdit, faPeopleArrows, faEnvelopeOpenText, faGrin, faSmile, faMeh, faFrown, faArrowLeft, faList, faChartBar, faCheck, faTrash, faHistory);

class App extends Component{

	componentDidMount(){

		sessionService.getLogIn(url, this)

	}

	render(){
		if(this.props.dataUI === null){
			return <Loader />
		}else{
			//console.re.log(this.props.dataUI);
			return(
				<Router>
					<PrincipalRoutes />
				</Router>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
		conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(App);