import React, { useState } from 'react';

import './css/Pelotas.css';

import Select from 'react-select';

let Pelotas = props => {
    //console.log(props.areasDeTrabajoConRespuestasYPorentaje)
    //console.log(props.climaOrganizacional)

    const [ state, setState ] = useState({
        areasDeTrabajo: []
    })
    

    let variablesArr =  props.climaOrganizacional.indicadoresDeMedicion.map( indicador => indicador.variables )

    let variables = Array.prototype.concat.apply([], variablesArr)

    let variablesConData = variables.map( variable => {

        areasDeTrabajo = props.areasDeTrabajoConRespuestasYPorentaje

        let variablesDeAreasDeTrabajo = areasDeTrabajo.map( area => {

            let variableOfArea = area.variables.filter( variableOfArea => variableOfArea.nombre === variable.nombre )[0]

            return {
                id: area.id,
                nombre: area.nombre,
                variable: variableOfArea
            }

        })

        variable.areasDeTrabajo = variablesDeAreasDeTrabajo

        return variable

    })

    let areasDeTrabajo = props.climaOrganizacional.areasDeTrabajo.map( area => {

        return ({
            id: area.id,
            nombre: area.nombre,
            })
        }
        ),
        optionsAreasDeTrabajo = areasDeTrabajo.map( area => ({
            value: area,
            label: area.nombre
        }))

    let handleChangeReactSelect = (newValue, type) => {

        let newState = newValue === null ? [] : newValue.map( obj =>  obj.value )

        setState({
            ...state,
            [type]: newState
        })

    }

    let createValReactSelect = arr => {
        let newArr = arr.map( el => ({
            value: el,
            label: el.nombre
        }))
        return newArr
    }

    let idsOfAreasDeTrabajoSelected = state.areasDeTrabajo.map( area => area.id)

    return (
        <div id="pelotasContainer" className="col-12">
            <div className="form-group col-12 col-sm-6 mx-auto">
                <label>Áreas de Trabajo:</label>
                <Select 
                    options={optionsAreasDeTrabajo}
                    isMulti={true}
                    onChange={ newValue => handleChangeReactSelect(newValue, "areasDeTrabajo") }
                    placeholder="Filtra por Áreas de Trabajo"
                    value={createValReactSelect(state.areasDeTrabajo)}
                />
            </div>
            {
                variablesConData.map( (variable, i) => {
                    return(
                        <div key={i} className="mt-4">
                            <h2 className="text-center">{variable.nombre}</h2>
                            <table  className="table text-center table-bordered table-hover table-sm">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="align-middle backgroundRed" scope="col">Insatisfaccion %</th>
                                        <th className="align-middle backgroundYellow" scope="col">Factor Evaluado</th>
                                        <th className="align-middle backgroundGreen" scope="col">Satisfacción %</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <div className="mt-3" />
                                    {
                                        variable.factoresEvaluados.map( (factor, i) => {

                                            return (
                                                <tr key={i}>
                                                    <td className="align-middle backgroundRed">{
                                                       variable.areasDeTrabajo.map( area => {
                                                            let factorEvaluado

                                                            let stringReturn

                                                            if(idsOfAreasDeTrabajoSelected.length > 0){

                                                                if(idsOfAreasDeTrabajoSelected.includes(area.id)){

                                                                    if(area.variable !== undefined){
                                                                        factorEvaluado = area.variable.factoresEvaluados.filter( factorArea => factorArea.nombre === factor.nombre)[0]
                                                                    }
        
                                                                    if(factorEvaluado !== undefined && (factorEvaluado.porcentajeOfFactorEvaluado * 100) < 75){
                                                                        //stringReturn = `${grupo.nombre} (${100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2)}), `
                                                                        /*stringReturn = (
                                                                                <p>{grupo.nombre} ({(100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2))})</p>
                                                                        )*/
                                                                        stringReturn = `${area.nombre} (${parseFloat(100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100)).toFixed(0)}), `
                                                                    }

                                                                }

                                                            }else{

                                                                if(area.variable !== undefined){
                                                                    factorEvaluado = area.variable.factoresEvaluados.filter( factorArea => factorArea.nombre === factor.nombre)[0]
                                                                }
    
                                                                if(factorEvaluado !== undefined && (factorEvaluado.porcentajeOfFactorEvaluado * 100) < 75){
                                                                    //stringReturn = `${grupo.nombre} (${100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2)}), `
                                                                    /*stringReturn = (
                                                                            <p>{grupo.nombre} ({(100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2))})</p>
                                                                    )*/
                                                                    stringReturn = `${area.nombre} (${parseFloat(100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100)).toFixed(0)}), `
                                                                }

                                                            }
    
                                                            

                                                            return stringReturn
                                                       }) 
                                                    }</td>
                                                    <td className="align-middle roundedSinBordes backgroundYellow">{factor.nombre}</td>
                                                    <td className="align-middle backgroundGreen">{
                                                       variable.areasDeTrabajo.map( area => {

                                                            let factorEvaluado

                                                            let stringReturn

                                                            if(idsOfAreasDeTrabajoSelected.length > 0){

                                                                if(idsOfAreasDeTrabajoSelected.includes(area.id)){

                                                                    if(area.variable !== undefined){
                                                                        factorEvaluado = area.variable.factoresEvaluados.filter( factorArea => factorArea.nombre === factor.nombre)[0]
                                                                    }
        
                                                                    if(factorEvaluado !== undefined && (factorEvaluado.porcentajeOfFactorEvaluado * 100) >= 75){
                                                                        //stringReturn = `${grupo.nombre} (${(factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2)}), `
                                                                        stringReturn = `${area.nombre} (${parseFloat((factorEvaluado.porcentajeOfFactorEvaluado * 100)).toFixed(0)}), `
                                                                    }

                                                                }

                                                            }else{

                                                                if(area.variable !== undefined){
                                                                    factorEvaluado = area.variable.factoresEvaluados.filter( factorArea => factorArea.nombre === factor.nombre)[0]
                                                                }
    
                                                                if(factorEvaluado !== undefined && (factorEvaluado.porcentajeOfFactorEvaluado * 100) >= 75){
                                                                    //stringReturn = `${grupo.nombre} (${(factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2)}), `
                                                                    stringReturn = `${area.nombre} (${parseFloat((factorEvaluado.porcentajeOfFactorEvaluado * 100)).toFixed(0)}), `
                                                                }

                                                            }
    

                                                            return stringReturn
                                                       }) 
                                                    }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Pelotas