import React from 'react';

import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let toggleSideOff = (e) => {

    e.preventDefault();
    document.querySelector(".body").classList.remove("sidenav-toggled");
    document.querySelector("body").classList.remove("sidenav-toggled");

}

let LeftSideNavbar = props => (
  <ul className="navbar-nav navbar-sidenav" id="leftMenu" onClick={toggleSideOff}>

    <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Components">
      <a className="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#adminCV" data-parent="#leftMenu" onClick={toggleSideOff}>
        <FontAwesomeIcon icon="id-card" style={{color: 'rgba(0,0,0,.5)'}}/>
        <span className="nav-link-text"> Administración de CVs</span>
      </a>
      <ul className="sidenav-second-level collapse" id="adminCV">
        <li>
          <NavLink to="/admin/cv/ingresar">Ingresar CV</NavLink>
        </li>
        <li>
        <NavLink to="/admin/cv/buscar">Buscar CV</NavLink>
        </li>
        <li>
          <a href="cards.html">CVs Pendientes a Actualizar</a>
        </li>
      </ul>
    </li>

    <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Components">
      <a className="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#adminClimas" data-parent="#leftMenu" onClick={toggleSideOff}>
        <FontAwesomeIcon icon="people-arrows" style={{color: 'rgba(0,0,0,.5)'}}/>
        <span className="nav-link-text"> Administración de Climas Organizacionales</span>
      </a>
      <ul className="sidenav-second-level collapse" id="adminClimas">
        <li>
          <NavLink to="/admin/clima-organizacional/ver-climas">Ver Climas Organizacionales</NavLink>
        </li>
      </ul>
    </li>
    
    {/*<li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Dashboard">
      <a className="nav-link" href="index.html">
        <FontAwesomeIcon icon="tachometer-alt" style={{color: 'rgba(0,0,0,.5)'}}/>
        <span className="nav-link-text"> Dashboard</span>
      </a>
    </li><li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Dashboard">
      <a className="nav-link" href="index.html">
        <i className="fa fa-fw fa-dashboard"></i>
        <span className="nav-link-text">Dashboard</span>
      </a>
    </li>
    <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Charts">
      <a className="nav-link" href="charts.html">
        <i className="fa fa-fw fa-area-chart"></i>
        <span className="nav-link-text">Charts</span>
      </a>
    </li>
    <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Tables">
      <a className="nav-link" href="tables.html">
        <i className="fa fa-fw fa-table"></i>
        <span className="nav-link-text">Tables</span>
      </a>
    </li>
    <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Components">
      <a className="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#collapseComponents" data-parent="#exampleAccordion" onClick={toggleSideOff}>
        <i className="fa fa-fw fa-wrench"></i>
        <span className="nav-link-text">Components</span>
      </a>
      <ul className="sidenav-second-level collapse" id="collapseComponents">
        <li>
          <a href="navbar.html">Navbar</a>
        </li>
        <li>
          <a href="cards.html">Cards</a>
        </li>
      </ul>
    </li>
    <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Example Pages">
      <a className="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#collapseExamplePages" data-parent="#exampleAccordion" onClick={toggleSideOff}>
        <i className="fa fa-fw fa-file"></i>
        <span className="nav-link-text">Example Pages</span>
      </a>
      <ul className="sidenav-second-level collapse" id="collapseExamplePages">
        <li>
          <a href="login.html">Login Page</a>
        </li>
        <li>
          <a href="register.html">Registration Page</a>
        </li>
        <li>
          <a href="forgot-password.html">Forgot Password Page</a>
        </li>
        <li>
          <a href="blank.html">Blank Page</a>
        </li>
      </ul>
    </li>
    <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Menu Levels">
      <a className="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#collapseMulti" data-parent="#exampleAccordion" onClick={toggleSideOff}>
        <i className="fa fa-fw fa-sitemap"></i>
        <span className="nav-link-text">Menu Levels</span>
      </a>
      <ul className="sidenav-second-level collapse" id="collapseMulti">
        <li>
          <a href="#link">Second Level Item</a>
        </li>
        <li>
          <a href="#link">Second Level Item</a>
        </li>
        <li>
          <a href="#link">Second Level Item</a>
        </li>
        <li>
          <a className="nav-link-collapse collapsed" data-toggle="collapse" href="#collapseMulti2" onClick={toggleSideOff}>Third Level</a>
          <ul className="sidenav-third-level collapse" id="collapseMulti2">
            <li>
              <a href="#link">Third Level Item</a>
            </li>
            <li>
              <a href="#link">Third Level Item</a>
            </li>
            <li>
              <a href="#link">Third Level Item</a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li className="nav-item text-center text-md-left" data-toggle="tooltip" data-placement="right" title="Link">
      <a className="nav-link" href="#link">
        <i className="fa fa-fw fa-link"></i>
        <span className="nav-link-text">Link</span>
      </a>
    </li>*/}
  </ul>
)

export default LeftSideNavbar
