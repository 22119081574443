import React from 'react';

//importando Estilos
import './css/Servicios.css'

let ServiciosLayOut = props => (
	<div id="servicios" className="jarallax col-12 section">
		<img src="img/jpg/fondoinicio.jpg" alt="Imagen de Fondo" className="img-responsive jarallax-img"/>
		<h2 className="col-12 text-center">Servicios de BL Consultores</h2>
		{props.children}
	</div>
)

export default ServiciosLayOut