let consulta = (data) => {
    let string = `{
      appInfo{
        getEncuestado(idClima: "${data.idClima}", token: "${data.token}"){
          encuestaActiva
          cuestionario{
            partesCuestionario{
              id
              orden
              tipo
              preguntaGeneral
              preguntaAbierta{
                id
                orden
                pregunta
              }
              cantidadDeOpciones
              preguntas{
                id
                orden
                NoAplica
                pregunta
                positiva
                factorEvaluado
              }
            }
          }
          respuestas{
            idPregunta
            respuesta
          }
        }
      }
    }`
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;