import React from 'react';

//importando Estilos
import './css/Navbar.css'

let NavbarLayOut = props => (
	<nav id="Navbar-container" className="navbar navbar-expand-md bg-light">
		{props.children}
	</nav>
)

export default NavbarLayOut