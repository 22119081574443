import React from 'react';

import { Bar } from 'chartjs-2-react';

import { setColor, LabelsColorsGraph } from '../utils';

let SatisfaccionGeneralGeneral = props => (
    <div className="col-12">
        <h2 className="text-center">Gráfica General de Resultados de Satisfacción</h2>
        <Bar config={{
            data: {
                labels: [...props.antiguedadConRespuestasYPorentaje.map( porcentaje => porcentaje.nombre )],
                datasets: [{
                    data: [...props.antiguedadConRespuestasYPorentaje.map(porcentaje => (porcentaje.porcentajeSatisfaccionAntiguedad * 100).toFixed(2))],
                    backgroundColor: [...props.antiguedadConRespuestasYPorentaje.map( porcentaje => setColor(porcentaje.porcentajeSatisfaccionAntiguedad * 100) )],
                    borderColor: 'none'
                }],
            },
            options: {
                title: {
                    display: true,
                    text: "Porcentajes de Satisfacción %"
                },
                responsive: true,
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            callback: (value, index, values) => {
                                return value + '%'
                            },
                            beginAtZero: true,
                            max: 100
                        }
                    }]
                },
                animation: {
                    duration: 1,
                    onComplete: function() {
                        var chartInstance = this.chart,
                        ctx = chartInstance.ctx;
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';
        
                        this.data.datasets.forEach(function(dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function(bar, index) {
                            var data = dataset.data[index];
                            ctx.fillText(data + '%', bar._model.x, bar._model.y - 5);
                        });
                    });
                    }
                }
            }
        }}/>
        <LabelsColorsGraph />
    </div>
)

export default SatisfaccionGeneralGeneral