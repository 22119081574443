import logIn from './logIn';
import AppInfo from './AppInfo';
import CVs from './CVs';
import empresas from './Empresas';
import climasOrganizacionales from './ClimasOrganizacionales' ;
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  logIn,
  AppInfo,
  empresas,
  climasOrganizacionales,
  CVs
})

export default rootReducer