import React, { useState } from 'react';

import Select from 'react-select';

let PreguntasAbiertas = props => {

    let parteCuestionario = props.climaOrganizacional.cuestionario.partesCuestionario.filter( parte => parte.tipo === "preguntasAbiertas")[0]

    const [ state, setState ] = useState({
        GruposDeOpinion: [],
        idPreguntaAbierta: parteCuestionario.preguntas[0].id,
        textFilter: ''
    })

    let preguntaSelected = parteCuestionario.preguntas.filter( pregunta => pregunta.id === state.idPreguntaAbierta)[0]

    let preguntasAbiertasPorGrupoDeOpinion = props.preguntasAbiertasPorGrupoDeOpinion

    let preguntasPreguntasAbiertasConGrupoIdArr = preguntasAbiertasPorGrupoDeOpinion.map( grupo => {

        let preguntasAbiertas = grupo.preguntasAbiertas.map( pregunta => ({
            ...pregunta,
            idGrupoDeOpinion: grupo.id
        }))

        return preguntasAbiertas

    })

    let preguntasPreguntasAbiertasConGrupoId = Array.prototype.concat.apply([], preguntasPreguntasAbiertasConGrupoIdArr)

    let idsGruposDeOpinionFilter = state.GruposDeOpinion.map( grupo => grupo.id)

    let filterPreguntasFromGrupoDeOpinionState = preguntasPreguntasAbiertasConGrupoId.filter( pregunta => idsGruposDeOpinionFilter.includes(pregunta.idGrupoDeOpinion) )

    let preguntasFiltered = filterPreguntasFromGrupoDeOpinionState.length > 0 ? filterPreguntasFromGrupoDeOpinionState.filter( pregunta => pregunta.id === state.idPreguntaAbierta ) : preguntasPreguntasAbiertasConGrupoId.filter( pregunta => pregunta.id === state.idPreguntaAbierta )

    let respuestasFilteredArr =  preguntasFiltered.map( pregunta => {
        let respuestas = pregunta.respuestas.filter( respuesta => (respuesta !== null) && (respuesta.respuesta !== "") )
        return respuestas
    })

    let respuestasFiltered = Array.prototype.concat.apply([], respuestasFilteredArr)

    let textFilter = state.textFilter === '' ? respuestasFiltered : respuestasFiltered.filter( respuesta => {
        return respuesta.respuesta.includes(state.textFilter) 
    })

    let lastFilter = textFilter

    let gruposDeOpinion = props.climaOrganizacional.gruposDeOpinion,
        optionsGruposDeOpinion = gruposDeOpinion.map( grupo => ({
            label: grupo.nombre,
            value: grupo
        }))

    let handleInputChange = event => {
        setState({
            ...state,
            [event.target.id]: event.target.value
        })
    }

    let handleChangeReactSelect = (newValue, type) => {

        let newState = newValue === null ? [] : newValue.map( obj =>  obj.value )

        setState({
            ...state,
            [type]: newState
        })

    }

    let createValReactSelect = arr => {
        let newArr = arr.map( el => ({
            value: el,
            label: el.nombre
        }))
        return newArr
    }

    return(
        <div id="preguntasAbiertasContainer" className="col-12">
            <div className="form-group col-12 col-sm-6 mx-auto">
                <label>Grupos de Opinion:</label>
                <Select 
                    options={optionsGruposDeOpinion}
                    isMulti={true}
                    onChange={ newValue => handleChangeReactSelect(newValue, "GruposDeOpinion") }
                    placeholder="Filtra por Grupo de Opinion"
                    value={createValReactSelect(state.GruposDeOpinion)}
                />
            </div>
            <div className="form-group col-12 col-sm-6 mx-auto">
                <label>Pregunta Abierta:</label>
                <select id="idPreguntaAbierta" className="form-control" onChange={handleInputChange} value={state.idPreguntaAbierta}>
                    {
                        parteCuestionario.preguntas.map( pregunta => (
                            <option key={pregunta.id} value={pregunta.id}>{pregunta.pregunta}</option>
                        ))
                    }
                </select>
            </div>
            <div className="form-group col-12 col-sm-6 mx-auto">
                <label>Filtro de Texto:</label>
                <input id="textFilter" className="form-control" type="text" onChange={handleInputChange} value={state.textFilter} />
            </div>
            <div className="col-12">
                <h3 className="text-center">{preguntaSelected.pregunta}</h3>
                <table  className="table text-center table-bordered table-hover table-sm">
                    <thead className="thead-light">
                        <tr>
                            <th className="align-middle" scope="col">Respuestas</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                           lastFilter.map( respuesta => {
                               return(
                                    <tr key={respuesta.id}>
                                        <td className="align-middle">{respuesta.respuesta}</td>
                                    </tr>
                               )
                           }) 
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PreguntasAbiertas