// JavaScript Document
import GraphQL from './ConsultaGraphQLInsertRespuestas';

import url from '../../../../../ApiService/Url';

function handleError(err, This){
	console.log(`Request failed: ${err}`);
	/*This.setState({
		loading: false,
		insertInvitacion: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})*/
}

const fetchInsertNewRespuestas = (This, data) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.setState({
			loading: false,
			respuestas: [...This.state.respuestas, ...res.data.insertRespuestasEncuesta]
		})

	})
	.catch(err => handleError(err, This))
	/*.then(() =>{
		setTimeout( () => {
			This.setState({
				insertInvitacion: null
			})
		}, 3000);
	});*/

	//termina fetch
}

export default fetchInsertNewRespuestas;