import GraphQL from './ConsultaGraphQL';

import url from '../../../../../ApiService/Url.js';

// JavaScript Document
function handleError(err){
	console.log(`Request failed: ${err}`);
}

const getCVs = (This) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(This.state.filter, This.state.cursor, This.state.limit)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		This.props.dispatch({
			type: 'FETCH_CVS',
			payload: {
				dataCVs: res.data.appInfo.getCVs
			}
		})

		if(res.data.appInfo.getCVs.length < This.state.limit){
			This.setState({
				blockNext: true
			})
		}


	})
	.catch(err => handleError(err, This));

	//termina fetch
}

export default getCVs;