import React, { Component } from 'react';
//Importando Link de React Router
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

//Impotando Component
import NavbarLayOut from '../Components/NavbarLayOut.jsx';

//Importando Jquery
import $ from 'jquery';

//import 'https://fonts.googleapis.com/css?family=Montserrat:400,700';

//Importando AnimatedHeader
import AnimatedHeader from '../../../../MyModules/AnimatedHeader.js';

import { connect } from 'react-redux';

class Navbar extends Component {

  constructor(props) {
	  super(props);

	  this.state = {
		  onInicio: this.props.path === '/'
	  }
	  // Don't call this.setState() here!

		//console.log(this.state.onInicio)
		
		this.runAnimatedHeader = this.runAnimatedHeader.bind(this);


	}
	
	runAnimatedHeader(){
		AnimatedHeader('Navbar-container','navbar-default', 'fixed-top', 'fixed-bottom', 'dropdown', 'dropup')
	}

  componentDidMount() {
	//if(this.props.path === '/'){

	//console.log(document.querySelectorAll('.navbar-nav > li > a'))

	// Closes the Responsive Menu on Menu Item Click
	document.querySelectorAll('.navbar-nav > li > a').forEach(function(This){
		This.addEventListener('click', function(){
			$('.navbar-collapse').collapse('hide');
		});
	});

	if(this.state.onInicio === true){

		document.querySelector('#Navbar-container').classList.add('navbar-default', 'fixed-bottom')
		document.querySelector('.dropdown').classList.add('dropup')

		window.addEventListener('resize', this.runAnimatedHeader)

		window.addEventListener('scroll', this.runAnimatedHeader)

	}else if(this.state.onInicio === false){

		document.querySelector('#Navbar-container').classList.add('fixed-top')
		document.querySelector('#Navbar-container').classList.remove('fixed-bottom', 'navbar-default')
		document.querySelector('.dropdown').classList.remove('dropup')

		window.addEventListener('scroll', () => {
			document.querySelector('#Navbar-container').classList.add('fixed-top');
			document.querySelector('#Navbar-container').classList.remove('fixed-bottom', 'navbar-default')
			document.querySelector('.dropdown').classList.remove('dropup')
		})

		window.addEventListener('resize', () => {
			document.querySelector('#Navbar-container').classList.add('fixed-top');
			document.querySelector('#Navbar-container').classList.remove('fixed-bottom', 'navbar-default')
			document.querySelector('.dropdown').classList.remove('dropup')
		})
	}

  }

  toScroll(el) {
	  //console.log(el.id);
  	  $('html, body').stop().animate({
  	  	scrollTop: $(`#${el.id}`).offset().top
  	  }, 1500, 'easeInOutExpo');
	}
	
	componentWillUnmount(){
		window.removeEventListener("scroll", this.runAnimatedHeader);
		window.removeEventListener("resize", this.runAnimatedHeader);
	}

  render() {

    return (
		<NavbarLayOut>
			<div className="container-fluid">
				<NavHashLink exact to="/#inicio" activeClassName="active" className="nav-link active" scroll={this.toScroll} >
					<img src="img/svg/Logo.svg" style={{height: '2.5em', paddingLeft: '25px'}} alt="logoimg"/>
				</NavHashLink>

				  <button className="navbar-toggler x collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="sr-only">Toggle navigation</span>
				  </button>

				  <div className="collapse navbar-collapse justify-content-center justify-content-md-end" id="navbarSupportedContent">
					<ul className="navbar-nav">
					  <li className="nav-item text-center text-md-right">
			  	 	 	<NavHashLink exact to="/#inicio" activeClassName="active" className="nav-link" scroll={this.toScroll} >Inicio</NavHashLink>
					  </li>
					  <li className="nav-item text-center text-md-right">
					  	<NavHashLink exact to="/#quienes-somos" activeClassName="active" className="nav-link" scroll={this.toScroll} >Quienes Somos</NavHashLink>
					  </li>
					  <li className="nav-item text-center text-md-right">
						<NavHashLink exact to="/#servicios" activeClassName="active" className="nav-link" scroll={this.toScroll} >Servicios</NavHashLink>
					  </li>
					  <li className="nav-item dropdown d-none d-md-block">
						<span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{paddingLeft: '0px', cursor: 'pointer'}}>
						</span>
						<div className="dropdown-menu" aria-labelledby="navbarDropdown" style={{left: '-100px'}}>
					  	  <NavLink to="/desarrollo-organizacional" className="dropdown-item nav-link">Desarrollo Organizacional</NavLink>
						  <div className="dropdown-divider"></div>
						  <NavLink to="/formacion-capacitacion-y-adiestramiento" className="dropdown-item nav-link">Capacitación</NavLink>
						  <div className="dropdown-divider"></div>
						  <NavLink to="/proceso-de-seleccion" className="dropdown-item nav-link">Proceso de Selección</NavLink>
						</div>
					  </li>
					  <li className="nav-item text-center text-md-right">
						<NavLink exact to="/envia-cv" activeClassName="active" className="nav-link" >Envia tu CV</NavLink>
					  </li>
					  <li className="nav-item text-center text-md-right">
						<NavHashLink exact to="/#contacto" activeClassName="active" className="nav-link" scroll={this.toScroll} >Contacto</NavHashLink>
					  </li>
						{
							this.props.conexion === true ? (
								<li className="nav-item text-center text-md-right">
									<NavLink exact to="/dashboard" activeClassName="active" className="nav-link" >Dashboard</NavLink>
								</li>
							) : (
								<li className="nav-item text-center text-md-right">
									<NavLink exact to="/login" activeClassName="active" className="nav-link" >Log In</NavLink>
								</li>
							)
						}
					</ul>
				  </div>
			</div>
		</NavbarLayOut>
    );
  }
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion
  }
}

export default connect(mapStateToProps)(Navbar)
