// JavaScript Document
import GraphQL from './ConsultaGraphQLClimas';

import url from '../../../../../ApiService/Url';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchClimas = (This) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL()),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		let climasOrganizacionales = res.data.appInfo.climasOrganizacionales.map( climaOrganizacional => {
			let clima = {
				...climaOrganizacional,
				fechaInicio: new Date(parseInt(climaOrganizacional.fechaInicio))
			}
			return clima
		})

		This.props.dispatch({
			type: 'FETCH_CLIMAS_ORGANIZACIONALES',
			payload: {
				dataClimasOrganizacionales: climasOrganizacionales
			}
		})

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchClimas;