let consulta = (data) => {

    let string = `mutation{
      sendCorreoToEncuesta(idClima: "${data.idClima}", idEncuesta: "${data.idEncuesta}", correo: "${data.correo}")
    }`

    return {
      "query": string
    }
  
  }
  
  export default consulta;