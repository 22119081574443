import React, { Component } from 'react';

import AdminComponent from '../../Components/AdminComponent.jsx';

import TableClima from '../Components/TableClima';
import EditarClima from '../Components/EditarClima';
import InvitacionDeEncuesta from './InvitacionDeEncuesta';
import TableEncuestados from '../Components/TableEncuestados';
import GraficasClima from './GraficasClima';

import FetchGraphQLEmpresas from '../../../../ApiService/FetchGraphQLEmpresas';
import FetchGraphQLClimas from './FetchGetAllClimas/FetchGraphQLClimas';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

class VerClimas extends Component {

    constructor(props) {
        super(props);

        // No llames this.setState() aquí!
        this.state = { 
            display: "TableClima",
            climaSelected: null
        };
        
        this.changeDisplay = this.changeDisplay.bind(this);
    }

    componentDidMount(){
        FetchGraphQLEmpresas(this)
        FetchGraphQLClimas(this)
    }

    changeDisplay(display, climaSelected){
        if(climaSelected !== undefined){

            this.setState({
                display,
                climaSelected
            })

        }else{
            this.setState({
                display,
                climaSelected: null
            })
        }
    }

    render(){

        if(this.props.conexion === false){
            return <Redirect to='/login' />
        }else{
            return(
                <AdminComponent>
                    <div className="row mt-5 mx-3" style={{minHeight: '60vh'}} >
                        {
                            this.state.display === "TableClima" ? <TableClima climasOrganizacionales={this.props.climasOrganizacionales} empresas={this.props.empresas} changeDisplay={this.changeDisplay} /> : null
                        }
                        {
                            this.state.display === "EditarClima" ? <EditarClima climaOrganizacional={this.state.climaSelected} changeDisplay={this.changeDisplay} /> : null
                        }
                        {
                            this.state.display === "InvitacionDeEncuesta" ? <InvitacionDeEncuesta climaOrganizacional={this.state.climaSelected} changeDisplay={this.changeDisplay}/> : null
                        }
                        {
                            this.state.display === "TableEncuestados" ? <TableEncuestados climaOrganizacional={this.state.climaSelected} changeDisplay={this.changeDisplay} /> : null
                        }
                        {
                            this.state.display === "GraficasClima" ? <GraficasClima climaOrganizacional={this.state.climaSelected} changeDisplay={this.changeDisplay} /> : null
                        }
                    </div>
                </AdminComponent>
            )
            
        }
    }

}


let mapStateToProps = (state, props) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        empresas: state.empresas,
        climasOrganizacionales: state.climasOrganizacionales
    }
}
  
export default connect(mapStateToProps)(VerClimas)