import React, { useState } from 'react';

import './css/Pelotas.css';

import Select from 'react-select';

let Pelotas = props => {
    //console.log(props.areasDeTrabajoConRespuestasYPorentaje)
    //console.log(props.climaOrganizacional)

    const [ state, setState ] = useState({
        areasDeTrabajo: [],
        gruposDeOpinion: []
    })
    

    let variablesArr =  props.climaOrganizacional.indicadoresDeMedicion.map( indicador => indicador.variables )

    let variables = Array.prototype.concat.apply([], variablesArr)

    let variablesConData = variables.map( variable => {

        let gruposDeOpinionArr = props.areasDeTrabajoConRespuestasYPorentaje.map( area => area.gruposDeOpinion)

        let gruposDeOpinion = Array.prototype.concat.apply([], gruposDeOpinionArr)

        let variablesDeGrupoDeOpinion = gruposDeOpinion.map( grupo => {

            let variableOfGrupo = grupo.variables.filter( variableOfGrupo => variableOfGrupo.nombre === variable.nombre )[0]

            return {
                id: grupo.id,
                nombre: grupo.nombre,
                orden: grupo.orden,
                variable: variableOfGrupo
            }

        })

        variable.gruposDeOpinion = variablesDeGrupoDeOpinion

        return variable

    })

    let areasDeTrabajo = props.climaOrganizacional.areasDeTrabajo.map( area => {

        return ({
            idsGruposDeOpinion: area.idsGruposDeOpinion,
            nombre: area.nombre,
            })
        }
        ),
        optionsAreasDeTrabajo = areasDeTrabajo.map( area => ({
            value: area,
            label: area.nombre
        }))

    let gruposDeOpinion = props.climaOrganizacional.gruposDeOpinion.map( grupo => grupo),
        optionsGruposDeOpinion = gruposDeOpinion.map( grupo => ({
            value: grupo,
            label: grupo.nombre
        }))

    let handleChangeReactSelect = (newValue, type) => {

        let newState = newValue === null ? [] : newValue.map( obj =>  obj.value )

        setState({
            ...state,
            [type]: newState
        })

    }

    let createValReactSelect = arr => {
        let newArr = arr.map( el => ({
            value: el,
            label: el.nombre
        }))
        return newArr
    }

    let gruposDeOpinionOfAreasDeTrabajoSelectedArr  = state.areasDeTrabajo.map( area => {
        return area.idsGruposDeOpinion
    })

    let idsGruposDeOpinionOfAreasDeTrabajoSelected = Array.prototype.concat.apply([], gruposDeOpinionOfAreasDeTrabajoSelectedArr)

    let idsGruposDeOpinionSelected = state.gruposDeOpinion.map( grupo => grupo.id )

    return (
        <div id="pelotasContainer" className="col-12">
            <div className="form-group col-12 col-sm-6 mx-auto">
                <label>Áreas de Trabajo:</label>
                <Select 
                    options={optionsAreasDeTrabajo}
                    isMulti={true}
                    onChange={ newValue => handleChangeReactSelect(newValue, "areasDeTrabajo") }
                    placeholder="Filtra por Áreas de Trabajo"
                    value={createValReactSelect(state.areasDeTrabajo)}
                />
            </div>
            <div className="form-group col-12 col-sm-6 mx-auto">
                <label>GruposDeOpinion:</label>
                <Select 
                    options={optionsGruposDeOpinion}
                    isMulti={true}
                    onChange={ newValue => handleChangeReactSelect(newValue, "gruposDeOpinion") }
                    placeholder="Filtra por Grupos de Opinión"
                    value={createValReactSelect(state.gruposDeOpinion)}
                />
            </div>
            {
                variablesConData.map( (variable, i) => {
                    return(
                        <div key={i} className="mt-4">
                            <h2 className="text-center">{variable.nombre}</h2>
                            <table  className="table text-center table-bordered table-hover table-sm">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="align-middle backgroundRed" scope="col">Insatisfaccion %</th>
                                        <th className="align-middle backgroundYellow" scope="col">Factor Evaluado</th>
                                        <th className="align-middle backgroundGreen" scope="col">Satisfacción %</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <div className="mt-3" />
                                    {
                                        variable.factoresEvaluados.map( (factor, i) => {

                                            return (
                                                <tr key={i}>
                                                    <td className="align-middle backgroundRed">{
                                                       variable.gruposDeOpinion.map( grupo => {
                                                            let factorEvaluado

                                                            let stringReturn

                                                            if((idsGruposDeOpinionOfAreasDeTrabajoSelected.length > 0) || (idsGruposDeOpinionSelected.length > 0)){

                                                                if( (idsGruposDeOpinionOfAreasDeTrabajoSelected.includes(grupo.id)) || (idsGruposDeOpinionSelected.includes(grupo.id)) ){
    
                                                                    if(grupo.variable !== undefined){
                                                                        factorEvaluado = grupo.variable.factoresEvaluados.filter( factorGrupo => factorGrupo.nombre === factor.nombre)[0]
                                                                    }
        
                                                                    if(factorEvaluado !== undefined && (factorEvaluado.porcentajeOfFactorEvaluado * 100) < 75){
                                                                        //stringReturn = `${grupo.nombre} (${100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2)}), `
                                                                        /*stringReturn = (
                                                                                <p>{grupo.nombre} ({(100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2))})</p>
                                                                        )*/
                                                                        stringReturn = `G${grupo.orden} (${parseFloat(100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100)).toFixed(0)}), `
                                                                    }
    
                                                                }

                                                            }else{

                                                                if(grupo.variable !== undefined){
                                                                    factorEvaluado = grupo.variable.factoresEvaluados.filter( factorGrupo => factorGrupo.nombre === factor.nombre)[0]
                                                                }
    
                                                                if(factorEvaluado !== undefined && (factorEvaluado.porcentajeOfFactorEvaluado * 100) < 75){
                                                                    //stringReturn = `${grupo.nombre} (${100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2)}), `
                                                                    /*stringReturn = (
                                                                            <p>{grupo.nombre} ({(100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2))})</p>
                                                                    )*/
                                                                    stringReturn = `G${grupo.orden} (${parseFloat(100 - (factorEvaluado.porcentajeOfFactorEvaluado * 100)).toFixed(0)}), `
                                                                }

                                                            }
                                                            

                                                            return stringReturn
                                                       }) 
                                                    }</td>
                                                    <td className="align-middle roundedSinBordes backgroundYellow">{factor.nombre}</td>
                                                    <td className="align-middle backgroundGreen">{
                                                       variable.gruposDeOpinion.map( grupo => {
                                                            let factorEvaluado

                                                            let stringReturn

                                                            if((idsGruposDeOpinionOfAreasDeTrabajoSelected.length > 0) || (idsGruposDeOpinionSelected.length > 0)){

                                                                if((idsGruposDeOpinionOfAreasDeTrabajoSelected.includes(grupo.id)) || (idsGruposDeOpinionSelected.includes(grupo.id))){
    
                                                                    if(grupo.variable !== undefined){
                                                                        factorEvaluado = grupo.variable.factoresEvaluados.filter( factorGrupo => factorGrupo.nombre === factor.nombre)[0]
                                                                    }
        
                                                                    if(factorEvaluado !== undefined && (factorEvaluado.porcentajeOfFactorEvaluado * 100) >= 75){
                                                                        //stringReturn = `${grupo.nombre} (${(factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2)}), `
                                                                        stringReturn = `G${grupo.orden} (${parseFloat((factorEvaluado.porcentajeOfFactorEvaluado * 100)).toFixed(0)}), `
                                                                    }
    
                                                                }
                                                                
                                                            }else{

                                                                if(grupo.variable !== undefined){
                                                                    factorEvaluado = grupo.variable.factoresEvaluados.filter( factorGrupo => factorGrupo.nombre === factor.nombre)[0]
                                                                }
    
                                                                if(factorEvaluado !== undefined && (factorEvaluado.porcentajeOfFactorEvaluado * 100) >= 75){
                                                                    //stringReturn = `${grupo.nombre} (${(factorEvaluado.porcentajeOfFactorEvaluado * 100).toFixed(2)}), `
                                                                    stringReturn = `G${grupo.orden} (${parseFloat((factorEvaluado.porcentajeOfFactorEvaluado * 100)).toFixed(0)}), `
                                                                }

                                                            }



                                                            return stringReturn
                                                       }) 
                                                    }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Pelotas