import React from 'react';

import { Bar } from 'chartjs-2-react';

let GruposDeOpinion = props => {

    const dataClimaActual = props.dataProcesadaActual ? props.dataProcesadaActual.satisfaccionPorAreasDeTrabajo : props.areasDeTrabajoConRespuestasYPorentajeActual.map( areaDeTrabajo => ({
        nombre: areaDeTrabajo.nombre,
        labels: [...areaDeTrabajo.variables.map( variable => variable.nombre )],
        data: [...areaDeTrabajo.variables.map(variable => (variable.porcentajeOfVariable * 100).toFixed(2))],
    }))

    const dataClimaComparado = props.areasDeTrabajoConRespuestasYPorentajeComparado.map( areaDeTrabajo => ({
        nombre: areaDeTrabajo.nombre,
        labels: [...areaDeTrabajo.variables.map( variable => variable.nombre )],
        data: [...areaDeTrabajo.variables.map(variable => (variable.porcentajeOfVariable * 100).toFixed(2))],
    }))


    const dataCombined = dataClimaActual.map( dataActual => {
        let dataComparado = props.dataProcesadaComparado ? props.dataProcesadaComparado.satisfaccionPorAreasDeTrabajo.find(dataProcesada => dataProcesada.nombre === dataActual.nombre) : dataClimaComparado.find(dataComparado => dataComparado.nombre === dataActual.nombre)
        let dataCombined = {
            [props.nombreClimaActual]: dataActual,
            [props.nombreClimaComparado]: dataComparado
        }
        return dataCombined
    })

    return(
        <div className="col-12">
            {
               dataCombined.map( (areaDeTrabajo, i) => {

                    let dataActual = areaDeTrabajo[props.nombreClimaActual]
                    let dataComparado = areaDeTrabajo[props.nombreClimaComparado]

                    return(
                        <div key={i}>
                            <h2 className="text-center">{/*Gráfica General de Resultados de Satisfacción*/}{dataActual.nombre}</h2>
                            <Bar config={{
                                data: {
                                    labels: dataActual.labels,
                                    datasets: [{
                                        label: props.nombreClimaActual,
                                        data: dataActual.data,
                                        backgroundColor: "#EABF2D",
                                        borderColor: 'none'
                                    },{
                                        label: props.nombreClimaComparado,
                                        data: dataComparado === undefined ? [] : dataComparado.data,
                                        backgroundColor: "#E88D46",
                                        borderColor: 'none'
                                    }],
                                },
                                options: {
                                    title: {
                                        display: true,
                                        text: "Porcentajes de Satisfacción %"
                                    },
                                    responsive: true,
                                    legend: {
                                        display: true
                                    },
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                callback: (value, index, values) => {
                                                    return value + '%'
                                                },
                                                beginAtZero: true,
                                                max: 100
                                            }
                                        }]
                                    },
                                    animation: {
                                        duration: 1,
                                        onComplete: function() {
                                            var chartInstance = this.chart,
                                            ctx = chartInstance.ctx;
                                            ctx.textAlign = 'center';
                                            ctx.textBaseline = 'bottom';
                            
                                            this.data.datasets.forEach(function(dataset, i) {
                                            var meta = chartInstance.controller.getDatasetMeta(i);
                                            meta.data.forEach(function(bar, index) {
                                                var data = dataset.data[index];
                                                ctx.fillText(data + '%', bar._model.x, bar._model.y - 5);
                                            });
                                        });
                                        }
                                    }
                                }
                            }}/>
                        </div> 
                    )
                })
            }
        </div>
    )
}

export default GruposDeOpinion