import React, { useState, useEffect } from 'react';

import FetchEncuestados from '../../../Containers/FetchGetAllEncuestados/FetchGraphQLEncuestados';

import FetchGetAntiguedadSatisfaccionGeneral from '../../../Containers/FetchGetAntiguedadSatisfaccionGeneral/FetchGraphQLGetAntiguedadSatisfaccionGeneral';

import Loader from '../../../../../Components/LoaderSinLogo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EncuestadosAntiguedad from './EncuestadosAntiguedad';
import SatisfacccionGeneralAntiguedad from './SatisfaccionGeneralAntiguedad';
import SatisfaccionGeneralGeneralAntiguedad from './SatisfaccionGeneralGeneralAntiguedad';
import SatisfaccionGeneralVariablesAntiguedad from './SatisfaccionGeneralVariablesAntiguedad';

let AntiguedadComponent = props => {

    let [encuestados, setEncuestados] = useState([])

    let [antiguedadConRespuestasYPorentaje, setAntiguedadConRespuestasYPorentaje] = useState([])

    useEffect( ()=> {
        FetchEncuestados(setEncuestados, props.climaOrganizacional.id)
        FetchGetAntiguedadSatisfaccionGeneral(setAntiguedadConRespuestasYPorentaje, props.climaOrganizacional.id)
    }, [props])

    //console.log(antiguedadConRespuestasYPorentaje)

    if(antiguedadConRespuestasYPorentaje.length > 0){
        return (
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-outline-warning mx-auto mb-5" onClick={ () => {
                        props.changeDisplay("SelectGraficas")
                    }}>Regresar al Menu de Graficas <FontAwesomeIcon icon="chart-bar"/></button>
                </div>
                <EncuestadosAntiguedad antiguedadConRespuestasYPorentaje={antiguedadConRespuestasYPorentaje} encuestados={encuestados} />
                <SatisfacccionGeneralAntiguedad antiguedadConRespuestasYPorentaje={antiguedadConRespuestasYPorentaje} />
                <SatisfaccionGeneralGeneralAntiguedad antiguedadConRespuestasYPorentaje={antiguedadConRespuestasYPorentaje} />
                <SatisfaccionGeneralVariablesAntiguedad antiguedadConRespuestasYPorentaje={antiguedadConRespuestasYPorentaje} indicadoresDeMedicion={props.climaOrganizacional.indicadoresDeMedicion} />
            </div>
        )
    }else{
        return (
            <div className="row">
                <Loader />
                <div className="col-12">
                    <h3 className="text-center">Espera a que se calculen los datos.</h3>
                </div>
            </div>
        )
    }

}

export default AntiguedadComponent