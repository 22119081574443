import React, { Component } from 'react';
import LogInComponent from '../components/LogInComponent.jsx';
import { Redirect } from 'react-router';
import url from '../../../../ApiService/Url';

import { connect } from 'react-redux';

import sessionService from '../../../../sessionService/sessionService';

class LogIn extends Component{
  constructor(props) {

    super(props)

    this.OnLogIn = this.OnLogIn.bind(this);

  }

  componentDidMount() {

			if(this.props.conexion === true){
			   return <Redirect to='/dashboard' />
				//console.log(this.state.conexion)
			}else {
				//console.log(this.usuario.conexion)
			}

  	}

    OnLogIn(event){

  		event.preventDefault();

  		let pass = document.querySelector('#password').value

  		let usuarioOEmail = document.querySelector('#usuarioOEmail').value

      let usuarioData = {
        usuarioOEmail,
        pass
      }

      let sessionServiceCallback = () => { 
        if(this.props.conexion === true){
          return <Redirect to='/dashboard' />
        }else if((this.props.usuario === null) && (this.props.conexion === false)) {
          document.querySelector('#incorrect-data').style.display = 'block';
        }
      }

      sessionService.logIn(url, usuarioData, this, sessionServiceCallback)

      /*if(this.props.conexion === true){
          return <Redirect to='/dashboard' />
        //console.log(this.state.conexion)
      }*/

  	}

  render() {
    if (this.props.conexion === true) {
		  return <Redirect to='/dashboard' />
		}else{
      return (
        <LogInComponent>
          <div className="d-flex align-items-center" style={{marginTop: '80px'}}>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-7 col-lg-5 mx-auto">
                  <div className="card card-login mx-auto mt-5">
                    <div className="card-header text-center">
                      <p>Ingresa</p>
                    </div>
                    <div className="card-body">
                      <form onSubmit={this.OnLogIn}>
                        <div className="form-group">
                          <label htmlFor="email">Correo Electrónico</label>
                          <input className="form-control" id="usuarioOEmail" type="text" aria-describedby="emailHelp" placeholder="Escribe tu correo electrónico" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Contraseña</label>
                          <input className="form-control" id="password" type="password" placeholder="Escribe tu contraseña" />
                        </div>
                        {/*<div className="form-group">
                          <div className="form-check">
                            <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" /> Remember Password</label>
                          </div>
                        </div>*/}
                        <button className="btn btn-outline-light btn-block" type="submit">Ingresa</button>
                        <div id="incorrect-data" style={{'marginTop': '15px', 'display': 'none', 'transition': 'all 0.5s ease-in-out'}} className="alert alert-danger" role="alert">Ingresaste datos incorrectos, por favor prueba de Nuevo</div>
                      </form>
                      {/*<div className="text-center">
                        <a className="d-block small mt-3" href="register.html">Register an Account</a>
                        <a className="d-block small" href="forgot-password.html">Forgot Password?</a>
                      </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LogInComponent>
      )
    }
  }

}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(LogIn)
