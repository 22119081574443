import React from 'react';
import './css/admin.min.css';

import Navbar from '../navbar/Navbar.jsx';

import Footer from '../../Inicio/Footer/Components/Footer.jsx';

let AdminComponent = props => (
  <div className="body fixed-nav sticky-footer" id="page-top">
    <Navbar />
    <div className="content-wrapper">
      {props.children}
      <Footer />
    </div>
  </div>
)

export default AdminComponent
