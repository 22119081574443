import GraphQLLogIn from './ConsultaGraphQLLogIn.js';
import GraphQLGetLogIn from './ConsultaGraphQLGetLogIn.js';
import GraphQLLogOut from './ConsultaGraphQLLogOut.js';

async function sessionService(){}

function handleError(err){
	console.log(`Request failed: ${err}`);
}

sessionService.logIn = (url, usuarioData, This, callback) => {

	new Promise((resolve, reject) => {
		const options = {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(GraphQLLogIn(usuarioData.usuarioOEmail, usuarioData.pass)),
			credentials: 'include'
			//mode: 'cors',
		}
		fetch(url, options)
		.then(res => res.json())
		.then( (res) => {
			//console.log(res)
			This.props.dispatch({
				type: 'LOGIN',
				payload: {
					logIn: res.data.auth.logIn
				}
			})
			resolve(res)
		})
		.catch(err => handleError(err));
	
		//termina fetch
	}).then(res => { 
		if (res) { 
			callback()
		}
	})

}

sessionService.getLogIn = (url, This) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQLGetLogIn()),
		credentials: 'include'
		//mode: 'cors',
	}
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		//console.log(res)
		if(res.data){
			This.props.dispatch({
				type: 'LOGIN',
				payload: {
					logIn: res.data.auth.getLogIn
				}
			})
		}else{
			console.error(res.mensaje)
		}
	})
	.catch(err => handleError(err));

	//termina fetch
}

sessionService.logOut = (url, This, callback) => {

	new Promise((resolve, reject) => {
		const options = {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(GraphQLLogOut()),
			credentials: 'include'
			//mode: 'cors',
		}
		fetch(url, options)
		.then(res => res.json())
		.then( (res) => {
			//console.log(res.data.auth.logOut)
			This.props.dispatch({
				type: 'LOGIN',
				payload: {
					logIn: res.data.auth.logOut
				}
			})
			resolve(res)
		})
		.catch(err => handleError(err));
	
		//termina fetch
	}).then(res => { 
		if (res) {
			callback()
		}
	})

}

export default sessionService;
