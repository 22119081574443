import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import FetchEncuestados from '../../../Containers/FetchGetAllEncuestados/FetchGraphQLEncuestados';

import FetchGetAntiguedadSatisfaccionGeneral from '../../../Containers/FetchGetAntiguedadSatisfaccionGeneral/FetchGraphQLGetAntiguedadSatisfaccionGeneral';

import FetchGraphQLGetDataProcesada from '../../../Containers/FetchGetDataProcesada/FetchGraphQLDataProcesada';

import Loader from '../../../../../Components/LoaderSinLogo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import EncuestadosAntiguedad from './EncuestadosAntiguedad';
import SatisfacccionGeneralAntiguedad from './SatisfaccionGeneralAntiguedad';
import SatisfaccionGeneralGeneralAntiguedad from './SatisfaccionGeneralGeneralAntiguedad';

let AntiguedadComponent = props => {

    let [encuestadosClimaActual, setEncuestadosClimaActual] = useState([])
    let [antiguedadConRespuestasYPorentajeClimaActual, setAntiguedadConRespuestasYPorentajeClimaActual] = useState([])

    let [dataProcesadaActual, setDataProcesadaActual] = useState(null)
    let [dataProcesadaComparado, setDataProcesadaComparado] = useState(null)

    let [encuestadosClimaComparado, setEncuestadosClimaComparado] = useState([])
    let [antiguedadConRespuestasYPorentajeClimaComparado, setAntiguedadConRespuestasYPorentajeClimaComparado] = useState([])

    let climasOfEmpresa = props.climasOrganizacionales.filter( clima => clima.idEmpresa === props.climaOrganizacional.idEmpresa)
    let climasOfEmpresaWithoutActualClima = climasOfEmpresa.filter( clima => clima.id !== props.climaOrganizacional.id)
    let empresa = props.empresas.filter( empresa => empresa.id === props.climaOrganizacional.idEmpresa)[0]

    let [valueOfEmpresaIdComparar, setValueOfEmpresaIdComparar] = useState(climasOfEmpresaWithoutActualClima[0].id)

    let [loadingComprarativo, setLoadingComparativo] = useState(false)

    let nombreOfClimaComparado = climasOfEmpresaWithoutActualClima.filter( clima => clima.id === valueOfEmpresaIdComparar)[0].fechaInicio.getFullYear()

    useEffect( ()=> {
        const setData = async () => {
            const dataProcesada = await FetchGraphQLGetDataProcesada(props.climaOrganizacional.id)
            if(dataProcesada){
                setDataProcesadaActual(dataProcesada)
            }else{
                FetchEncuestados(setEncuestadosClimaActual, props.climaOrganizacional.id)
                FetchGetAntiguedadSatisfaccionGeneral(setAntiguedadConRespuestasYPorentajeClimaActual, props.climaOrganizacional.id)
            }
        }
        setData()
    }, [props])


    const onSubmit = async event => {
        event.preventDefault()
        const dataProcesada = await FetchGraphQLGetDataProcesada(valueOfEmpresaIdComparar)
        if(dataProcesada){
            setDataProcesadaComparado(dataProcesada)
        }else{
            setLoadingComparativo(true)
            FetchEncuestados(setEncuestadosClimaComparado, valueOfEmpresaIdComparar, loadingComprarativo, setLoadingComparativo)
            FetchGetAntiguedadSatisfaccionGeneral(setAntiguedadConRespuestasYPorentajeClimaComparado, valueOfEmpresaIdComparar, loadingComprarativo, setLoadingComparativo)
        }
    }

    if(antiguedadConRespuestasYPorentajeClimaActual.length > 0 || dataProcesadaActual){
        return (
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-outline-warning mx-auto mb-5" onClick={ () => {
                        props.changeDisplay("SelectGraficas")
                    }}>Regresar al Menu de Graficas <FontAwesomeIcon icon="chart-bar"/></button>
                </div>
                <form className="col-12" onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-12 col-md-6 mx-auto">
                                <div className="form-group">
                                    <label>Selecciona un Clima:</label>
                                    <select className="form-control" value={valueOfEmpresaIdComparar} onChange={(event)=> {
                                        setValueOfEmpresaIdComparar(event.target.value)
                                    }}>
                                        {
                                            climasOfEmpresaWithoutActualClima.map( clima => <option key={clima.id} value={clima.id}>{empresa.nombre_comercial} {clima.fechaInicio.getFullYear()}</option>)
                                        }
                                    </select>
                                </div>
                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn btn-outline-warning">Comparar</button>
                        </div>
                    </div>
                </form>
                {
                    (loadingComprarativo && antiguedadConRespuestasYPorentajeClimaComparado.length === 0) && (
                        <div className="col-12">
                            <div className="row">
                                <Loader />
                                <div className="col-12">
                                    <h3 className="text-center">Espera a que se calculen los datos.</h3>
                                </div>
                            </div>
                        </div>
                    )
                }
                <EncuestadosAntiguedad antiguedadConRespuestasYPorentajeClimaActual={antiguedadConRespuestasYPorentajeClimaActual} encuestadosClimaActual={encuestadosClimaActual} antiguedadConRespuestasYPorentajeClimaComparado={antiguedadConRespuestasYPorentajeClimaComparado} encuestadosClimaComparado={encuestadosClimaComparado} nombreClimaActual={props.climaOrganizacional.fechaInicio.getFullYear()} nombreClimaComparado={nombreOfClimaComparado} dataProcesadaActual={dataProcesadaActual} dataProcesadaComparado={dataProcesadaComparado} />
                <SatisfacccionGeneralAntiguedad antiguedadConRespuestasYPorentajeClimaActual={antiguedadConRespuestasYPorentajeClimaActual} antiguedadConRespuestasYPorentajeClimaComparado={antiguedadConRespuestasYPorentajeClimaComparado} nombreClimaActual={props.climaOrganizacional.fechaInicio.getFullYear()} nombreClimaComparado={nombreOfClimaComparado} dataProcesadaActual={dataProcesadaActual} dataProcesadaComparado={dataProcesadaComparado} />
                <SatisfaccionGeneralGeneralAntiguedad antiguedadConRespuestasYPorentajeClimaActual={antiguedadConRespuestasYPorentajeClimaActual} antiguedadConRespuestasYPorentajeClimaComparado={antiguedadConRespuestasYPorentajeClimaComparado} nombreClimaActual={props.climaOrganizacional.fechaInicio.getFullYear()} nombreClimaComparado={nombreOfClimaComparado} dataProcesadaActual={dataProcesadaActual} dataProcesadaComparado={dataProcesadaComparado} />
            </div>
        )
    }else{
        return (
            <div className="row">
                <Loader />
                <div className="col-12">
                    <h3 className="text-center">Espera a que se calculen los datos.</h3>
                </div>
            </div>
        )
    }

}

let mapStateToProps = (state, props) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        empresas: state.empresas,
        climasOrganizacionales: state.climasOrganizacionales
    }
}
  
export default connect(mapStateToProps)(AntiguedadComponent)
