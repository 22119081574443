import React, { useState, useEffect } from 'react';

import Popup from 'react-popup';

import 'react-popup/style.css';

let SeleccionMultiple = props => {

    let [respuestas, setRespuestas] = useState([])

    useEffect( ()=> {

        let respuestas = props.preguntas.map( pregunta => {

            let data

            if((pregunta.tipo !== undefined) && (pregunta.tipo === "preguntaAbierta")){
                data = {
                    idPregunta: pregunta.id,
                    respuesta: ""
                }
            }else{
                data = {
                    idPregunta: pregunta.id,
                    respuesta: false
                }
            }

            return data

        })

        setRespuestas(respuestas)

    }, [props.preguntas, props.preguntaAbierta])

    let onInputChange = (idPregunta, event) => {

        if(event.target.type === "checkbox"){

            let newRespuestas = [
                ...respuestas
            ]
    
            let respuestaFiltered = newRespuestas.filter( respuesta => respuesta.idPregunta === idPregunta )[0]
    
            respuestaFiltered.respuesta = respuestaFiltered.respuesta === false ? true : false
    
            newRespuestas.find(o => o.id === respuestaFiltered.id)

            let newRespuestasTrulyLength = newRespuestas.filter( respuesta => respuesta.respuesta === true).length

            if(newRespuestasTrulyLength <= props.cantidadDeOpciones){
                setRespuestas(newRespuestas)
            }
    

        }else{
            let newRespuestas = [
                ...respuestas
            ]
    
            let respuestaFiltered = newRespuestas.filter( respuesta => respuesta.idPregunta === idPregunta )[0]
    
            respuestaFiltered.respuesta = event.target.value
    
            newRespuestas.find(o => o.id === respuestaFiltered.id)
    
            setRespuestas(newRespuestas)
        }


    }

    //console.log(respuestas)

    return(
        <div className="row">
            <div className="col-12">
                <p className="text-justify">
                    {props.preguntaGeneral}
                </p>
            </div>

            {
                props.preguntas.length > 0 ? (
                    <div className="table-responsive text-center">
                        <table className="table text-center table-bordered table-hover table-sm">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="d-none d-md-table-cell">No.</th>
                                    <th scope="col">Selección</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.preguntas.map( pregunta => (
                                        <tr key={pregunta.id}>
                                            <td className="d-none d-md-table-cell">{pregunta.orden}</td>
                                            {
                                                pregunta.tipo === "preguntaAbierta" ? (
                                                    <td>
                                                        {pregunta.pregunta}
                                                        {
                                                          (respuestas.filter( respuesta => respuesta.idPregunta === pregunta.id)[0] !== undefined) ? (
                                                                <input className="form-control" value={respuestas.filter(respuesta => respuesta.idPregunta === pregunta.id)[0].respuesta} onChange={ (event)=> onInputChange(pregunta.id, event) } placeholder="Escribe tu respuesta" />
                                                           ) : null
                                                        } 
                                                    </td>
                                                    ) : (
                                                        <td>

                                                            {
                                                                (respuestas.filter( respuesta => respuesta.idPregunta === pregunta.id)[0] !== undefined) ? (
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" checked={respuestas.filter(respuesta => respuesta.idPregunta === pregunta.id)[0].respuesta} id={`${pregunta.id}-checkbox`} onChange={ (event)=> onInputChange(pregunta.id, event) }  />
                                                                        <label className="form-check-label" htmlFor={`${pregunta.id}-checkbox`}>
                                                                            {pregunta.pregunta}
                                                                        </label>
                                                                    </div>
                                                               ) : null
                                                            }

                                                            {/*<div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value={} id={`${pregunta.id}-checkbox`} onChange={ (event)=> onInputChange(pregunta.id, event.target.value) }  />
                                                                <label className="form-check-label" htmlFor={`${pregunta.id}-checkbox`}>
                                                                    {pregunta.pregunta}
                                                                </label>
                                                            </div>*/}

                                                        </td>
                                                    )
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <button className="btn btn-outline-info mb-5" onClick={ async () => {

                            Popup.create({
                                title: 'Guardar Respuestas',
                                content: '¿Desea Guardar las Respuestas?',
                                buttons: {
                                    left: [{
                                        text: 'Cancelar',
                                        className: 'danger',
                                        action: function () {
                                            /** Close this popup. Close will always close the current visible one, if one is visible */
                                            Popup.close();
                                        }
                                    }],
                                    right: [{
                                        text: 'Guardar',
                                        className: 'success',
                                        action: async function () {

                                            await respuestas.forEach(respuesta => {
                                                if(respuesta.respuesta === false){
                                                    respuesta.respuesta = "false"
                                                }else if(respuesta.respuesta === true){
                                                    respuesta.respuesta = "true"
                                                }
                                            });
                                            props.onSaveRespuestas(respuestas)

                                            /** Close this popup. Close will always close the current visible one, if one is visible */
                                            Popup.close();
                                        }
                                    }]
                                }
                            })

                        }}>Guardar Respuestas</button>
                        <Popup />
                    </div>
                ) : null
            }

            <div className="col-12">
                <p className="text-center">Página {props.objectPagination.currentPage} de {props.objectPagination.totalPages}</p>
            </div>

        </div>
    )

}

export default SeleccionMultiple