let consulta = (CV) => {
  
    const string =
      `mutation insertCV {
        insertCV(CV: {
          primerNombre: "${CV.primerNombre}",
          segundoNombre: "${CV.segundoNombre}",
          primerApellido: "${CV.primerApellido}",
          segundoApellido: "${CV.segundoApellido}",
          telefono: "${CV.telefono}",
          celular: "${CV.celular}",
          correo: "${CV.correo}",
          cv: "${CV.cv}",
          estado: "${CV.estado}",
          etiquetasPuestos: "${CV.etiquetasPuesto}"
        }){
          id
          mensaje
        }
      }`
  
      return {
        "query": string
      }
  
  }
  
  export default consulta