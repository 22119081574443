import React, { Component } from 'react';

//Impotando Component
import DesarrolloOrganizacionalLayOut from '../Components/DesarrolloOrganizacionalLayOut.jsx';
import InfoCard from '../../InfoCard/InfoCard.jsx';
import Card from '../../Card/Card.jsx';
import Modal from '../../Modal/Modal.jsx';

import Json from './DesarrolloOrganizacional.json';

//Importando Librerías CSS
import 'animate.css';
import '../../../../../MyModules/anicollection.css';

let InfoCardText = {
	'titulo': 'DESARROLLO ORGANIZACIONAL',
	'texto': 'El desarrollo organizacional (DO) es el factor de éxito más frecuentemente utilizado por las empresas que buscan la mejora continua dentro de un entorno altamente competitivo y en evolución constante. El DO incluye: el diseño de sistemas, herramientas y procesos para mejorar la productividad y efectividad, mediante la dinamización de los procesos y generación de climas laborales favorables. BL Consultores tiene alta experiencia en la instalación y operación de ocho herramientas indispensables para el éxito de las empresas en la optimización del capital humano:'
}

class DesarrolloOrganizacional extends Component {

	componentDidMount() {

		window.scrollTo(0, 0)
	}

	render(){
		return(
			<DesarrolloOrganizacionalLayOut path={this.props.path}>
				<InfoCard data={InfoCardText} />
				<Card data={Json}/>
				<Modal data={Json}/>
			</DesarrolloOrganizacionalLayOut>
		)
	}
}

export default DesarrolloOrganizacional
