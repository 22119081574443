import React, { useReducer, useEffect, useState } from 'react';

import arrayPaginate from 'array-paginate';

import { cuestionarioInitialState, cuestionarioReducer } from  './reducer';

import Loader from '../../../../Components/LoaderSinLogo';

import { returnActiveParteCuestionario, returnPreguntasParte, returnActivePreguntas } from './utils';

import EncuestaSmile from './EncuestaSmile';
import MaloToExelente from './MaloToExelente';
import SeleccionMultiple from './SeleccionMultiple';
import PreguntasAbiertas from './PreguntasAbiertas';
import TiempoDeLaborarEnLaEmpresa from './TiempoDeLaborarEnLaEmpresa';

let Cuestionario = props => {

    const [ state, dispatch ] = useReducer(cuestionarioReducer, cuestionarioInitialState)

    let [page, setPage] = useState(1)
    let [objectPagination, setObjectPagination] = useState({})
    let limit = 5

    //Use Effect que realiza el trabajo de ComponentDidMont
    useEffect(() => {

        let activeParteCuestionario = returnActiveParteCuestionario(props.respuestas, props.cuestionario) !== undefined ? returnActiveParteCuestionario(props.respuestas, props.cuestionario) : null,
            preguntasParteCuestionario = activeParteCuestionario !== null ? returnPreguntasParte(activeParteCuestionario.preguntas, activeParteCuestionario.preguntaAbierta) : null,
            //preguntasParteCuestionario = activeParteCuestionario.preguntas,
            preguntasActivas = preguntasParteCuestionario !== null ? returnActivePreguntas(props.respuestas, preguntasParteCuestionario, activeParteCuestionario) : null

        let preguntas = props.cuestionario.partesCuestionario.map( parte => {
            
            let preguntas = [...parte.preguntas]

            if(parte.preguntaAbierta !== null){
                preguntas.push(parte.preguntaAbierta)
            }

            return preguntas

        }).flat()

        //Pagination
        let activePage = preguntasActivas !== null ? preguntasActivas[preguntasActivas.length - 1].orden/limit : null
        setPage(activePage)
        let paginatedItems = page !== null ? arrayPaginate(preguntas, page, limit) : {}
        setObjectPagination(paginatedItems)

        //dispatch
        dispatch({
             type: 'SET_ACTIVE_PARTE_CUESTIONARIO',
             payload: {
                activeParteCuestionario
             }
        })

        dispatch({
            type: 'SET_PREGUNTAS_ACTIVAS',
            payload: {
                preguntasActivas
            }
        })

    },[props.cuestionario, props.respuestas, limit, page])

    //console.log(state)

    return(
        <div className="container">
            <div className="col-12 col-md-10 col-lg-7 mx-auto mt-5 mb-3">
                <img className="img-fluid" src="/img/svg/Logo.svg" alt="logoImg"/>
            </div>
            {
                state.activeParteCuestionario !== null ? (
                    <div className="col-12">
                        <h2 className="text-center">Instrucciones Generales:</h2>
                        <p className="text-justify">La encuesta que a continuación se le presenta nos ayuda a entender cómo se siente con respecto a la empresa y las personas con las que trabaja. Para ayudarnos por favor complete este cuestionario. <br/><br/>
                        <b>LA ENCUESTA ES ANÓNIMA</b> <br/><br/>
                        Las preguntas se le presentarán de cinco en cinco, luego presionar en el botón "Guardar Respuestas" y se le presentarán las siguientes preguntas. <br/><br/>
                        Deberá responder todas las preguntas, las respuestas no se guardarán si alguna de las cinco no se ha contestado. <br/><br/>
                        La información es manejada con total confidencialidad por la empresa BL Consultores, y por los beneficios que representa, su participación debe ser sincera, honesta y responsable.
                        </p>
                        {
                            state.activeParteCuestionario.tipo === "smile" ? (
                                <EncuestaSmile preguntas={state.preguntasActivas} onSaveRespuestas={props.onSaveRespuestas} objectPagination={objectPagination} />
                            ) : null
                        }
                        {
                            state.activeParteCuestionario.tipo === "maloToExelente" ? (
                                <MaloToExelente preguntas={state.preguntasActivas} onSaveRespuestas={props.onSaveRespuestas} preguntaGeneral={state.activeParteCuestionario.preguntaGeneral} preguntaAbierta={state.activeParteCuestionario.preguntaAbierta} objectPagination={objectPagination} />
                            ) : null
                        }
                        {
                            state.activeParteCuestionario.tipo === "seleccionMultiple" ? (
                                <SeleccionMultiple preguntas={state.preguntasActivas} onSaveRespuestas={props.onSaveRespuestas} preguntaGeneral={state.activeParteCuestionario.preguntaGeneral} preguntaAbierta={state.activeParteCuestionario.preguntaAbierta} cantidadDeOpciones={state.activeParteCuestionario.cantidadDeOpciones} objectPagination={objectPagination} />
                            ) : null
                        }
                        {
                            state.activeParteCuestionario.tipo === "preguntasAbiertas" ? (
                                <PreguntasAbiertas preguntas={state.preguntasActivas} onSaveRespuestas={props.onSaveRespuestas} preguntaGeneral={state.activeParteCuestionario.preguntaGeneral} objectPagination={objectPagination} />
                            ) : null
                        }
                        {
                            state.activeParteCuestionario.tipo === "tiempoDeLaborarEnEmpresa" ? (
                                <TiempoDeLaborarEnLaEmpresa preguntas={state.preguntasActivas} onSaveRespuestas={props.onSaveRespuestas} preguntaGeneral={state.activeParteCuestionario.preguntaGeneral} objectPagination={objectPagination} />
                            ) : null
                        }
                        {
                            props.loading === true ? <Loader /> : null
                        }
                    </div>
                ) : null
            }
            {
                state.activeParteCuestionario === null ? (
                    <div className="col-12">
                        <h1 className="text-center">Ha Finalizado la Encuesta</h1>
                    </div>
                ) : null
            }
        </div>
    )
}

export default Cuestionario