import React from 'react';
import './css/logIn.min.css';

//Importando Link de React Router
import { NavLink } from 'react-router-dom';

let LogInComponent = props => (
  <div id="LogInContainer" className="bg-dark" style={{height: '100vh'}}>
    <nav id="navLogIn" className="navbar navbar-expand">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <NavLink exact to="/" activeClassName="active" className="nav-link" >Inicio</NavLink>
          </li>
        </ul>
      </div>
    </nav>
    {props.children}
  </div>
)

export default LogInComponent
