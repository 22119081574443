import React from 'react';

import { RadialGauge } from 'chartjs-2-react';

import { setColor, LabelsColorsGraph } from '../utils';

let PorcentajeSatisfaccionGeneral = props => {

    let satisfaccionGeneral = 0

    props.indicadoresDeMedicionConRespuestasYPorentaje.forEach (function(indicador){
        satisfaccionGeneral += indicador.porcentajeIndicador;
    });

    satisfaccionGeneral = ((satisfaccionGeneral/props.indicadoresDeMedicionConRespuestasYPorentaje.length) * 100).toFixed(2)

    let porcentajeTexto  =  `${satisfaccionGeneral} %`

    return(
        <div className="col-12 col-md-8 col-lg-6 mx-auto mb-5">
            <h2>Gráficas General de Satisfacción</h2>
            <RadialGauge config={{
                data: {
                    datasets: {
                        data: satisfaccionGeneral,
                        backgroundColor: setColor(satisfaccionGeneral)
                    }
                },
                options: {
                    centerArea: {
                        text: porcentajeTexto
                    }
                }
            }}/>
            <LabelsColorsGraph />
        </div>
    )
}

export default PorcentajeSatisfaccionGeneral