import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import FetchEncuestados from '../../../Containers/FetchGetAllEncuestados/FetchGraphQLEncuestados';

import FetchGraphQLGetAreasDeTrabajoSatisfaccionGeneral from '../../../Containers/FetchGetAreasDeTrabajoSatisfaccionGeneral/FetchGraphQLGetAreasDeTrabajoSatisfaccionGeneral';

import FetchGraphQLGetDataProcesada from '../../../Containers/FetchGetDataProcesada/FetchGraphQLDataProcesada';

import Loader from '../../../../../Components/LoaderSinLogo';

import SatisfaccionGeneral from './SatisfaccionGeneralGruposDeOpinion';
import GruposDeOpinion from "./GruposDeOpinion";
// import EncuestadosGrupoDeOpinion from './EncuestadosGruposDeOpinion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let AreasDeAnalisisComponent = props => {

    let [encuestadosActual, setEncuestadosActual] = useState([])
    let [areasDeTrabajoConRespuestasYPorentajeActual, setAreasDeTrabajoConRespuestasYPorentajeActual] = useState([])

    let [dataProcesadaActual, setDataProcesadaActual] = useState(null)
    let [dataProcesadaComparado, setDataProcesadaComparado] = useState(null)

    let [encuestadosComparado, setEncuestadosComparado] = useState([])
    let [areasDeTrabajoConRespuestasYPorentajeComparado, setAreasDeTrabajoConRespuestasYPorentajeClimaComparado] = useState([])

    let climasOfEmpresa = props.climasOrganizacionales.filter( clima => clima.idEmpresa === props.climaOrganizacional.idEmpresa)
    let climasOfEmpresaWithoutActualClima = climasOfEmpresa.filter( clima => clima.id !== props.climaOrganizacional.id)
    let empresa = props.empresas.filter( empresa => empresa.id === props.climaOrganizacional.idEmpresa)[0]

    let [valueOfEmpresaIdComparar, setValueOfEmpresaIdComparar] = useState(climasOfEmpresaWithoutActualClima[0].id)

    let [loadingComprarativo, setLoadingComparativo] = useState(false)

    let nombreOfClimaComparado = climasOfEmpresaWithoutActualClima.filter( clima => clima.id === valueOfEmpresaIdComparar)[0].fechaInicio.getFullYear()

    useEffect( ()=> {
        const setData = async () => {
            const dataProcesada = await FetchGraphQLGetDataProcesada(props.climaOrganizacional.id)
            if(dataProcesada){
                setDataProcesadaActual(dataProcesada)
            }else{
                FetchEncuestados(setEncuestadosActual, props.climaOrganizacional.id)
                FetchGraphQLGetAreasDeTrabajoSatisfaccionGeneral(setAreasDeTrabajoConRespuestasYPorentajeActual, props.climaOrganizacional.id)
            }
        }
        setData()
    }, [props])

    const onSubmit = async event => {
        event.preventDefault()
        const dataProcesada = await FetchGraphQLGetDataProcesada(valueOfEmpresaIdComparar)
        if(dataProcesada){
            setDataProcesadaComparado(dataProcesada)
        }else{
            setLoadingComparativo(true)
            FetchEncuestados(setEncuestadosComparado, valueOfEmpresaIdComparar, loadingComprarativo, setLoadingComparativo)
            FetchGraphQLGetAreasDeTrabajoSatisfaccionGeneral(setAreasDeTrabajoConRespuestasYPorentajeClimaComparado, valueOfEmpresaIdComparar, loadingComprarativo, setLoadingComparativo)
        }
    }

    if(areasDeTrabajoConRespuestasYPorentajeActual.length > 0 || dataProcesadaActual){
        return (
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-outline-warning mx-auto mb-5" onClick={ () => {
                        props.changeDisplay("SelectGraficas")
                    }}>Regresar al Menu de Graficas <FontAwesomeIcon icon="chart-bar"/></button>
                </div>
                <form className="col-12" onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-12 col-md-6 mx-auto">
                                <div className="form-group">
                                    <label>Selecciona un Clima:</label>
                                    <select className="form-control" value={valueOfEmpresaIdComparar} onChange={(event)=> {
                                        setValueOfEmpresaIdComparar(event.target.value)
                                    }}>
                                        {
                                            climasOfEmpresaWithoutActualClima.map( clima => <option key={clima.id} value={clima.id}>{empresa.nombre_comercial} {clima.fechaInicio.getFullYear()}</option>)
                                        }
                                    </select>
                                </div>
                        </div>
                        <div className="col-12">
                            <button type="submit" className="btn btn-outline-warning">Comparar</button>
                        </div>
                    </div>
                </form>
                {
                    (loadingComprarativo && areasDeTrabajoConRespuestasYPorentajeComparado.length === 0) && (
                        <div className="col-12">
                            <div className="row">
                                <Loader />
                                <div className="col-12">
                                    <h3 className="text-center">Espera a que se calculen los datos.</h3>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/*<EncuestadosGrupoDeOpinion areasDeTrabajoConRespuestasYPorentajeActual={areasDeTrabajoConRespuestasYPorentajeActual} encuestadosActual={encuestadosActual} areasDeTrabajoConRespuestasYPorentajeComparado={areasDeTrabajoConRespuestasYPorentajeComparado} encuestadosComparado={encuestadosComparado} nombreClimaActual={props.climaOrganizacional.fechaInicio.getFullYear()} nombreClimaComparado={nombreOfClimaComparado} />*/}
                <SatisfaccionGeneral areasDeTrabajoConRespuestasYPorentajeActual={areasDeTrabajoConRespuestasYPorentajeActual} encuestadosActual={encuestadosActual} areasDeTrabajoConRespuestasYPorentajeComparado={areasDeTrabajoConRespuestasYPorentajeComparado} encuestadosComparado={encuestadosComparado} nombreClimaActual={props.climaOrganizacional.fechaInicio.getFullYear()} nombreClimaComparado={nombreOfClimaComparado} dataProcesadaActual={dataProcesadaActual} dataProcesadaComparado={dataProcesadaComparado}/>
                <GruposDeOpinion areasDeTrabajoConRespuestasYPorentajeActual={areasDeTrabajoConRespuestasYPorentajeActual} encuestadosActual={encuestadosActual} areasDeTrabajoConRespuestasYPorentajeComparado={areasDeTrabajoConRespuestasYPorentajeComparado} encuestadosComparado={encuestadosComparado} nombreClimaActual={props.climaOrganizacional.fechaInicio.getFullYear()} nombreClimaComparado={nombreOfClimaComparado} dataProcesadaActual={dataProcesadaActual} dataProcesadaComparado={dataProcesadaComparado} />
            </div>
        )
    }else{
        return (
            <div className="row">
                <Loader />
                <div className="col-12">
                    <h3 className="text-center">Espera a que se calculen los datos.</h3>
                </div>
            </div>
        )
    }

}

let mapStateToProps = (state, props) => {
    return {
        conexion: state.logIn.conexion,
        usuario: state.logIn.usuario,
        empresas: state.empresas,
        climasOrganizacionales: state.climasOrganizacionales
    }
}
  
export default connect(mapStateToProps)(AreasDeAnalisisComponent)