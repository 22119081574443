let consulta = (idClima) => {
    let string = `{
  appInfo{
    getAntiguedadSatisfaccionGeneral(idClima: "${idClima}"){
      id
      nombre
      cantidadEncuestadosAntiguedad
      porcentajeSatisfaccionAntiguedad
      indicadoresDeMedicion{
        nombre
        nombreAntiguedad
         variables{
          nombre
          descripcion
          VariableNoAplica
          porcentajeOfVariable
          factoresEvaluados{
            nombre
            porcentajeOfFactorEvaluado
            positiva
            factorEvaluadoNoAplica
            respuestas{
              id
              factorEvaluado
              idPreguntaAntiguedad
              idPregunta
              respuesta
            }
            respuestasNoAplica{
              id
              factorEvaluado
              idPreguntaAntiguedad
              idPregunta
              respuesta
            }
          }
        }
        porcentajeIndicador
      }
    }
  }
}`
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;