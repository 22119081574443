import React from 'react';
import './css/Footer.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let Footer = props => (
<footer className="container-fluid">
        <div className="row">
            <div className="col-lg-offset-0 col-lg-6 col-md-6">
                <ul className="list-inline social-buttons">
                    <li>
                    	<a href="https://www.facebook.com/blconsultoresgt"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
                    </li>
                    <li>
                    	<a href="https://www.linkedin.com/pub/bl-consultores-en-recursos-humanos/95/18/3a8/en"><FontAwesomeIcon icon={['fab', 'linkedin-in']} /></a>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6 col-md-6"><span className="copyright">Copyright &copy; <img src="/img/svg/logoP-lao.svg" width="85px" alt="LogoP-lao"/> 2018</span></div>
        </div>
</footer>
)

export default Footer