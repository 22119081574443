// JavaScript Document
import GraphQL from './ConsultaGraphQLGetAreasDeTrabajoSatisfaccionGeneral';

import url from '../../../../../ApiService/Url';

function handleError(err){
	console.log(`Request failed: ${err}`);
}

const fetchClimas = (setAreasDeTrabajoConRespuestasYPorentaje, idClima) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(idClima)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		setAreasDeTrabajoConRespuestasYPorentaje(res.data.appInfo.getAreasDeTrabajoSatisfaccionGeneral)

	})
	.catch(err => handleError(err));

	//termina fetch
}

export default fetchClimas;