import React, { Component } from 'react';

import AdminComponent from '../../Components/AdminComponent.jsx';

import { Redirect } from 'react-router';

import { connect } from 'react-redux';

//import sessionService from '../../../../sessionService/sessionService';

class AdminCV extends Component{

	/*constructor(props) {
    super(props);
	}*/

	componentDidMount() {

			if(this.props.conexion === false){
			   return <Redirect to='/login' />
				//console.log(this.state.conexion)
			}else {
				//console.log(this.usuario.conexion)
			}

  	}

	render(){
		if (this.props.conexion === false) {
			return <Redirect to='/login' />
		}else{
			return(
				<AdminComponent>
					<div className="container-fluid" style={{minHeight: '78vh'}}>
                        <h1>Este es AdminCV</h1>
                    </div>
				</AdminComponent>
			)
		}
	}
}

let mapStateToProps = (state, props) => {
  return {
    conexion: state.logIn.conexion,
    usuario: state.logIn.usuario
  }
}

export default connect(mapStateToProps)(AdminCV)
