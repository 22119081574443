import React, { useState, useEffect } from 'react';

import FetchEncuestados from '../../../Containers/FetchGetAllEncuestados/FetchGraphQLEncuestados';

import Loader from '../../../../../Components/LoaderSinLogo';

import AreasDeAnalisis from "./AreasDeAnalisis";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let AreasDeAnalisisComponent = props => {

    let [encuestados, setEncuestados] = useState([])

    let [display, setDisplay] = useState("menuGraficas")

    useEffect( ()=> {
        FetchEncuestados(setEncuestados, props.climaOrganizacional.id)
    }, [props])

    let parteCuestionario = props.climaOrganizacional.cuestionario.partesCuestionario.filter( parteCuestionario => parteCuestionario.tipo === 'smile')[0],
    preguntas = parteCuestionario.preguntas,
    idsPreguntas = preguntas.map( pregunta => pregunta.id),
    respuestasArray = encuestados.map( encuestado => {

        let respuestasReturn = encuestado.respuestas.map( respuesta => {

            let dataRespuesta = {
                ...respuesta,
                idGrupoDeOpinion: encuestado.idGrupoDeOpinion
            }
            return dataRespuesta

        })
        return respuestasReturn
    }),
    respuestas = Array.prototype.concat.apply([], respuestasArray),
    respuestasParteCuestionario = respuestas.filter( respuesta => {

        let preguntaOfRespuesta = preguntas.filter( pregunta => pregunta.id === respuesta.idPregunta)[0]

        if(preguntaOfRespuesta !== undefined){
            respuesta.factorEvaluado = preguntaOfRespuesta.factorEvaluado
        }

        return idsPreguntas.includes(respuesta.idPregunta)
    }),
    respuestasSatisfaccion = respuestasParteCuestionario.filter( respuesta => (respuesta.respuesta === "0") || (respuesta.respuesta === "1")),
    //respuestasInsatisfaccion = respuestasParteCuestionario.filter( respuesta => (respuesta.respuesta === "2") || (respuesta.respuesta === "3")),
    indicadoresDeMedicion = props.climaOrganizacional.indicadoresDeMedicion,
    areasDeTrabajoConRespuestasYPorentaje

    if(encuestados.length > 0){// respuestasParteCuestionario.length === (respuestasSatisfaccion.length + respuestasInsatisfaccion.length) &&

        areasDeTrabajoConRespuestasYPorentaje =  props.climaOrganizacional.areasDeTrabajo.map( areaDeTrabajo => {

            let gruposDeOpinionOfAreaDeTrabajo = props.climaOrganizacional.gruposDeOpinion.filter( grupoDeOpinion => {
    
                let encuestadosGrupoDeOpinion  = encuestados.filter( encuestado => encuestado.idGrupoDeOpinion ===  grupoDeOpinion.id)

                grupoDeOpinion.candidadEncuestadosGrupoDeOpinion = encuestadosGrupoDeOpinion.length

                let respuestasGrupoDeOpinion = respuestasSatisfaccion.filter( respuesta => respuesta.idGrupoDeOpinion === grupoDeOpinion.id)

                let indicadores = indicadoresDeMedicion.map( indicadorDeMedicion => {

                    let variables = indicadorDeMedicion.variables.map( variable => {

                        let factoresEvaluadosNombre = variable.factoresEvaluados.map( factorEvaluado => factorEvaluado.nombre)

                        let preguntasOfVariable = preguntas.filter(pregunta => factoresEvaluadosNombre.includes(pregunta.factorEvaluado))

                        let respuestasOfVariable = preguntasOfVariable.map( pregunta => {
                            let respuestas = respuestasGrupoDeOpinion.filter(respuesta => respuesta.idPregunta === pregunta.id)
                            return respuestas
                        })

                        let preguntasOfVariableConRespuestas = preguntasOfVariable.map( pregunta => {
                            let arrOfRespuestas = Array.prototype.concat.apply([], respuestasOfVariable)

                            let respuestas = arrOfRespuestas.filter(respuesta => {
                                return respuesta.idPregunta === pregunta.id
                            })
                            
                            return {
                                ...pregunta,
                                respuestas,
                                porcentajePregunta: respuestas.length/encuestadosGrupoDeOpinion.length
                            }

                        })
            
                        let preguntasOfVariablePorcentaje = preguntasOfVariableConRespuestas.map( pregunta => pregunta.porcentajePregunta )
            
                        var sumaOfVariablesPorcentaje = 0;
                            preguntasOfVariablePorcentaje.forEach (function(numero){
                                sumaOfVariablesPorcentaje += numero;
                            });
            
                        let porcentajeVariable = sumaOfVariablesPorcentaje/preguntasOfVariablePorcentaje.length
            
                        return {
                            ...variable,
                            preguntas: preguntasOfVariableConRespuestas,
                            porcentajeVariable
                        }
            
                    })

                    let porcentajesVariables = variables.map( variable=> variable.porcentajeVariable )

                    var sumaOfIndicadorPorcentaje = 0;
                        porcentajesVariables.forEach (function(numero){
                            sumaOfIndicadorPorcentaje += numero;
                        });

                    let porcentajeIndicador = sumaOfIndicadorPorcentaje/variables.length

                    return {
                        ...indicadorDeMedicion,
                        variables,
                        porcentajeIndicador
                    }
                })

                grupoDeOpinion.indicadorDeMedicion = indicadores
    
                //return grupoDeOpinion
                return areaDeTrabajo.idsGruposDeOpinion.includes(grupoDeOpinion.id)
    
            }),

            idsGruposDeOpinionOfAreaDeTrabajo = gruposDeOpinionOfAreaDeTrabajo.map( grupoDeOpinion => grupoDeOpinion.id),
            encuestadosAreaDeTrabajo = encuestados.filter( encuestado => idsGruposDeOpinionOfAreaDeTrabajo.includes(encuestado.idGrupoDeOpinion))

            let respuestasAreasDeTrabajo = respuestasSatisfaccion.filter( respuesta => idsGruposDeOpinionOfAreaDeTrabajo.includes(respuesta.idGrupoDeOpinion))

            

            let indicadores = indicadoresDeMedicion.map( indicadorDeMedicion => {

                let variables = indicadorDeMedicion.variables.map( variable => {

                    let factoresEvaluadosNombre = variable.factoresEvaluados.map( factorEvaluado => factorEvaluado.nombre)

                    let preguntasOfVariable = preguntas.filter(pregunta => factoresEvaluadosNombre.includes(pregunta.factorEvaluado))

                    let respuestasOfVariable = preguntasOfVariable.map( pregunta => {
                        let respuestas = respuestasAreasDeTrabajo.filter(respuesta => respuesta.idPregunta === pregunta.id)
                        return respuestas
                    })

                    let preguntasOfVariableConRespuestas = preguntasOfVariable.map( pregunta => {
                            let arrOfRespuestas = Array.prototype.concat.apply([], respuestasOfVariable)

                            let respuestas = arrOfRespuestas.filter(respuesta => {
                                return respuesta.idPregunta === pregunta.id
                            })
                            
                            return {
                                ...pregunta,
                                respuestas,
                                porcentajePregunta: respuestas.length/encuestadosAreaDeTrabajo.length
                            }

                        })
            
                        let preguntasOfVariablePorcentaje = preguntasOfVariableConRespuestas.map( pregunta => pregunta.porcentajePregunta )
            
                        var sumaOfVariablesPorcentaje = 0;
                            preguntasOfVariablePorcentaje.forEach (function(numero){
                                sumaOfVariablesPorcentaje += numero;
                            });
            
                        let porcentajeVariable = sumaOfVariablesPorcentaje/preguntasOfVariablePorcentaje.length
            
                        return {
                            ...variable,
                            preguntas: preguntasOfVariableConRespuestas,
                            porcentajeVariable
                        }
            
                    })

                let porcentajesVariables = variables.map( variable=> variable.porcentajeVariable )

                var sumaOfIndicadorPorcentaje = 0;
                    porcentajesVariables.forEach (function(numero){
                        sumaOfIndicadorPorcentaje += numero;
                    });

                let porcentajeIndicador = sumaOfIndicadorPorcentaje/variables.length

                return {
                    ...indicadorDeMedicion,
                    variables,
                    porcentajeIndicador
                }
            })

            areaDeTrabajo.gruposDeOpinion = gruposDeOpinionOfAreaDeTrabajo
            areaDeTrabajo.indicadorDeMedicion = indicadores

            return areaDeTrabajo  

        })

    }

    if(areasDeTrabajoConRespuestasYPorentaje !== undefined){
        if(display === "menuGraficas"){
            return (
                <div className="row">
                    <div className="col-12">
                        <button type="button" className="btn btn-outline-warning mx-auto mb-5" onClick={ () => {
                            props.changeDisplay("SelectGraficas")
                        }}>Regresar al Menu de Graficas <FontAwesomeIcon icon="chart-bar"/></button>
                    </div>
                    <div className="col-12">
                        <h2 className="text-center mb-5">Escoge un Área de Trabajo para ver Gráficas</h2>
                        <div className="row mt-4">
                            {
                                areasDeTrabajoConRespuestasYPorentaje.map( (areaDeTrabajo, i) => (
                                    <div className="col-12 col-md-6 col-lg-4" key={i}>
                                        <button type="button" className="btn btn-outline-warning mx-auto mb-5" onClick={ () => {
                                            setDisplay(areaDeTrabajo.nombre)
                                        }}>{areaDeTrabajo.nombre} <FontAwesomeIcon icon="chart-bar"/></button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            )
        }else{
            let areaDeTrabajoData = areasDeTrabajoConRespuestasYPorentaje.filter( AreasDeTrabajo => AreasDeTrabajo.nombre === display)[0]
            return (
                <div className="row">
                    <div className="col-12">
                        <button type="button" className="btn btn-outline-warning mx-auto mb-5" onClick={ () => {
                            props.changeDisplay("SelectGraficas")
                        }}>Regresar al Menu de Graficas <FontAwesomeIcon icon="chart-bar"/></button>
                    </div>
                    <div className="col-12">
                        <button type="button" className="btn btn-outline-warning mx-auto mb-5" onClick={ () => {
                            setDisplay("menuGraficas")
                        }}>Regresar al Menu de Graficas de Áreas de Análisis y Áreas de Trabajo <FontAwesomeIcon icon="chart-bar"/></button>
                    </div>
                    <AreasDeAnalisis areaDeTrabajo={areaDeTrabajoData} />
                </div>
            ) 
        }     
    }else{
        return (
            <div className="row">
                <Loader />
            </div>
        )
    }

}

export default AreasDeAnalisisComponent