// JavaScript Document
import GraphQL from './ConsultaGraphQLChangeEncuestaActivaClima';

import url from '../../../../../ApiService/Url';

function handleError(err, setMensajeEncuestaActiva){

	console.log(`Request failed: ${err}`);
	setMensajeEncuestaActiva({
		response: 'failure',
		mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
	})

}

const setEncuestaActiva = (setEncuestaActiva, setMensajeEncuestaActiva, data) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(data)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {
		setEncuestaActiva(res.data.changeEncuestaActivaClima.encuestaActiva)
		setMensajeEncuestaActiva({
			response: 'success',
			mensaje: res.data.changeEncuestaActivaClima.encuestaActiva === true ? 'Se Activó la encuesta.' : 'Se Desactivó la encuesta.'
		})
	})
	.then( () =>{
		setTimeout( () => {
			setMensajeEncuestaActiva(null)
		}, 3000);
	})
	.catch(err => handleError(err, setMensajeEncuestaActiva))
	.then(() =>{
		setTimeout( () => {
			setMensajeEncuestaActiva(null)
		}, 3000);
	});
	//termina fetch
	
}

export default setEncuestaActiva;