import React from 'react';

import { Bar } from 'chartjs-2-react';

import { setRandomColorFromListOfColors } from '../utils';

let SatisfaccionGeneralGeneral = props => {

    let indicadoresArr  = props.antiguedadConRespuestasYPorentaje.map( antiguedad => {
        antiguedad.indicadoresDeMedicion.map( indicador => {
            indicador.nombreAntiguedad = antiguedad.nombre
            return indicador
        })
        return antiguedad.indicadoresDeMedicion
    })

    let indicadoresOneArr = Array.prototype.concat.apply([], indicadoresArr)

    let indicadores = props.indicadoresDeMedicion.map( indicador => {
        let antiguedadFilter = indicadoresOneArr.filter( antiguedad => antiguedad.nombre === indicador.nombre)
        indicador.antiguedad = antiguedadFilter
        return indicador
    })

    let listOfRandomColors = ['#993300', '#FF9900', '#525252', '#FFCF0D', '#E8710C']

    return(
        <div className="col-12">
            {
                indicadores.map( indicador => (
                    <div key={indicador.id}>
                        <h3 className="text-center">{indicador.nombre}</h3>
                        <Bar config={{
                            data: {
                                labels: [...indicador.variables.map(variable => variable.nombre)],
                                datasets: [...indicador.antiguedad.map( antiguedad => ( { 
                                    data: antiguedad.variables.map( variable => (variable.porcentajeOfVariable * 100).toFixed(2) ),
                                    label: antiguedad.nombreAntiguedad,
                                    backgroundColor: setRandomColorFromListOfColors(listOfRandomColors),
                                    borderColor: 'none'
                                }))]
                            },
                            options: {
                                title: {
                                    display: true,
                                    text: "Porcentajes de Satisfacción %"
                                },
                                responsive: true,
                                legend: {
                                    display: true,
                                    position: 'bottom'
                                },
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            callback: (value, index, values) => {
                                                return value + '%'
                                            },
                                            beginAtZero: true,
                                            max: 100
                                        }
                                    }]
                                },
                                animation: {
                                    duration: 1,
                                    onComplete: function() {
                                        var chartInstance = this.chart,
                                        ctx = chartInstance.ctx;
                                        ctx.textAlign = 'center';
                                        ctx.textBaseline = 'bottom';
                        
                                        this.data.datasets.forEach(function(dataset, i) {
                                        var meta = chartInstance.controller.getDatasetMeta(i);
                                        meta.data.forEach(function(bar, index) {
                                            var data = dataset.data[index];
                                            ctx.fillText(data + '%', bar._model.x, bar._model.y - 5);
                                        });
                                    });
                                    }
                                }
                            }
                        }} />
                    </div>
                ))
            }
            {/*<h2 className="text-center">Gráfica General de Resultados de Satisfacción</h2>
                <div >
                    <h2 className="text-center">{antiguedad.nombre}</h2>
                    <Bar config={{
                        data: {
                            labels: [...antiguedad.indicadoresDeMedicion.map( porcentaje => porcentaje.nombre )],
                            datasets: [{
                                data: [...antiguedad.indicadoresDeMedicion.map(porcentaje => (porcentaje.porcentajeIndicador * 100).toFixed(2))],
                                backgroundColor: [...antiguedad.indicadoresDeMedicion.map( porcentaje => setColor(porcentaje.porcentajeIndicador * 100) )],
                                borderColor: 'none'
                            }],
                        },
                        options: {
                            title: {
                                display: true,
                                text: "Porcentajes de Satisfacción %"
                            },
                            responsive: true,
                            legend: {
                                display: false
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        callback: (value, index, values) => {
                                            return value + '%'
                                        },
                                        beginAtZero: true,
                                        max: 100
                                    }
                                }]
                            },
                            animation: {
                                duration: 1,
                                onComplete: function() {
                                    var chartInstance = this.chart,
                                    ctx = chartInstance.ctx;
                                    ctx.textAlign = 'center';
                                    ctx.textBaseline = 'bottom';
                    
                                    this.data.datasets.forEach(function(dataset, i) {
                                    var meta = chartInstance.controller.getDatasetMeta(i);
                                    meta.data.forEach(function(bar, index) {
                                        var data = dataset.data[index];
                                        ctx.fillText(data + '%', bar._model.x, bar._model.y - 5);
                                    });
                                });
                                }
                            }
                        }
                    }}/>
                </div>*/}
                {/*<ul className="list-inline mt-3">
                    <li className="list-inline-item"><div style={{width: '15px', height: '15px', backgroundColor: '#066A08', float: 'left', marginRight: '5px', marginTop: '5px'}} /> (90%-100%)</li>
                    <li className="list-inline-item"><div style={{width: '15px', height: '15px', backgroundColor: '#09A30D', float: 'left', marginRight: '5px', marginTop: '5px'}} /> (80%-89%)</li>
                    <li className="list-inline-item"><div style={{width: '15px', height: '15px', backgroundColor: '#5CC422', float: 'left', marginRight: '5px', marginTop: '5px'}} /> (75%-79%)</li>
                   
                </ul>*/}
        </div>
    )
}

export default SatisfaccionGeneralGeneral