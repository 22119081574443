const initialState = {
  conexion: false,
  usuario: null
}

let logIn = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN': {

      let logIn = action.payload.logIn

      if(logIn === null){
        return state
      }else{
        return logIn
      }

    }
      //break;
    default:
      return state
  }
}

export default logIn
