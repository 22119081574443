import GraphQL from './ConsultaGraphQL';

import url from '../../../../../ApiService/Url.js';

// JavaScript Document
function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		isLoadingCV: false,
		insertCVMensaje: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const newCV = (This, CV) => {
	//console.log(usuarioJSON)
	const options = {
		method: 'POST',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(CV)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		This.setState({
			isLoading: false,
			isLoadingCV: false,
            value: [],
            cv: {
                primerNombre: "",
                segundoNombre: "",
                primerApellido: "",
                segundoApellido: "",
                telefono: "",
                celular: "",
                correo: "",
                cv: "",
                estado: "Validado",
                etiquetasPuesto: []
            },
			insertCVMensaje: {
				response: 'success',
				mensaje: `${res.data.insertCV.mensaje} con id: ${res.data.insertCV.id}`
			}
		})
		
	})
	.then( () =>{
		setTimeout( () => {
			This.setState({
				insertCVMensaje: null
			})
		}, 3000);
	})
	.catch(err => handleError(err, This))
	.then(() =>{
		setTimeout( () => {
			This.setState({
				insertCVMensaje: null
			})
		}, 5000);
	});

	//termina fetch
}

export default newCV;