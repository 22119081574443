let consulta = (filter, cursor, limit) => {

  if(filter === ""){
    filter = null
  }else{
    filter = `"${filter}"`
  }
  
    const string =
      `{
        appInfo{
          getCVs(filtro: ${filter}, limit: ${limit}, cursor: ${cursor}){
            id
            primerNombre
            segundoNombre
            primerApellido
            segundoApellido
            celular
            telefono
            correo
            cv
            estado
            etiquetasPuestos
          }
        }
      }`
  
      return {
        "query": string
      }
  
  }
  
  export default consulta