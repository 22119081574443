import React from 'react';

import { Bar } from 'chartjs-2-react';

let EncuestadosGruposDeOpinion = props => {

    let gruposDeOpinionArr = props.areasDeTrabajoConRespuestasYPorentaje.map( areaDeTrabajo => areaDeTrabajo.gruposDeOpinion)

    let gruposDeOpinion = Array.prototype.concat.apply([], gruposDeOpinionArr)

    return(
        <div className="col-12 my-5">
            <h2 className="text-center">Cantidad de Encuestados Por Grupos de Opinión</h2>
            <Bar config={{
                data: {
                    labels: [...gruposDeOpinion.map( grupoDeOpinion => grupoDeOpinion.nombre )],
                    datasets: [{
                        data: [...gruposDeOpinion.map(grupoDeOpinion => ((grupoDeOpinion.cantidadEncuestadosGrupoDeOpinion / props.encuestados.length) * 100).toFixed(2))],
                        backgroundColor: "#EABF2D",
                        borderColor: 'none'
                    }],
                },
                options: {
                    title: {
                        display: true,
                        text: "Porcentajes de Satisfacción %"
                    },
                    responsive: true,
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                callback: (value, index, values) => {
                                    return value + '%'
                                },
                                beginAtZero: true,
                                //max: 100
                            }
                        }]
                    },
                    animation: {
                        duration: 1,
                        onComplete: function() {
                            var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';
            
                            this.data.datasets.forEach(function(dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function(bar, index) {
                                var data = dataset.data[index];
                                ctx.fillText(data + '%', bar._model.x, bar._model.y - 5);
                            });
                        });
                        }
                    }
                }
            }}/>
        </div>
    )
}

export default EncuestadosGruposDeOpinion