let consulta = (idClima) => {
    let string = `{
      appInfo{
        getEncuestados(idClima: "${idClima}"){
          id
          idGrupoDeOpinion
          correo{
            sendMail
            correo
          }
          respuestas{
            id
            idPregunta
            respuesta
          }
        }
      }
    }`
  
    return {
      "query": string
    }
  
  }
  
  export default consulta;