import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let TableClima = props => (
    <div className="col-12">
        {
            props.climasOrganizacionales.length > 0 ? (
                
                <div className="table-responsive">
                    <table className="table text-center table-bordered table-hover table-sm">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" className="d-none d-md-table-cell" >Id de Clima Organizacional</th>
                                <th scope="col">Empresa del Clima Organizacional</th>
                                <th scope="col" >Fecha del Clima Organizacional</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.climasOrganizacionales.map( climaOrganizacional => {

                                    let empresaClima = props.empresas.filter( empresa => empresa.id === climaOrganizacional.idEmpresa)[0]

                                    let fecha = climaOrganizacional.fechaInicio,
                                        year = fecha.getFullYear()
                                    let mes
                                    (fecha.getMonth()+1) < 10 ? mes = `0${fecha.getMonth()+1}` : mes = `${fecha.getMonth()+1}`
                                    let dia
                                    fecha.getDate() < 10 ? dia = `0${fecha.getDate()}` : dia = `${fecha.getDate()}`

                                    return (
                                        <tr key={climaOrganizacional.id}>
                                            <td className="d-none d-md-table-cell">{climaOrganizacional.id}</td>
                                            <td>{empresaClima.nombre_comercial}</td>
                                            <td>{dia}/{mes}/{year}</td>
                                            <td>
                                                {/*<button type="button" onClick={() => {
                                                    
                                                }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#EABF2D'}}>
                                                    <FontAwesomeIcon icon="eye" />
                                                </button>*/}
                                
                                                <button type="button" onClick={() => {
                                                    props.changeDisplay("EditarClima", climaOrganizacional)
                                                }} style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#EABF2D'}}>
                                                    <FontAwesomeIcon icon="edit" />
                                                </button>
                                
                                                {/*<button onClick={() => {
                                                        
                                                }} type="button" style={{'textDecoration': 'none', 'border': 'none', 'backgroundColor': 'transparent', 'cursor': 'pointer', 'color': '#EABF2D'}}>
                                                    <FontAwesomeIcon icon="trash-alt" />
                                                </button>*/}
                                
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {/*
                        this.props.climasOrganizacionales.map( climaOrganizacional => {
                            let empresaClima = this.props.empresas.filter( empresa => empresa.id === climaOrganizacional.idEmpresa)[0]
                            return <li key={climaOrganizacional.id}>{empresaClima.nombre_comercial}</li>
                        })*/
                    }
                </div>
            ) : (
                <div className="col-12">
                    <h1 className="text-center">No hay Climas Organizacionales que mostrar</h1>
                </div>
            )
        }
    </div>
)

export default TableClima