// JavaScript Document
import GraphQL from './ConsultaGraphQLInsertEncuesta';

import url from '../../../../../ApiService/Url';

function handleError(err, This){
	console.log(`Request failed: ${err}`);
	This.setState({
		loading: false,
		insertInvitacion: {
			response: 'failure',
			mensaje: `Hubo un error, el error fué: ${err}, intenta de nuevo`
		}
	})
}

const fetchInsertNewEncuesta = (This, newEncuesta) => {

	const options = {
		method: 'post',
		headers: {
			"Content-type": "application/json"
		},
		body: JSON.stringify(GraphQL(newEncuesta)),
		credentials: 'include'
	}

	//llamada de datos con Fetch
	fetch(url, options)
	.then(res => res.json())
	.then( (res) => {

		//console.log(res)

		This.setState({
			idGrupoDeOpinion: This.props.climaOrganizacional.gruposDeOpinion[0].id,
            correos: "",
			loading: false,
			insertInvitacion: {
				response: 'success',
				mensaje: `${res.data.insertNewEncuesta}`
			}
		})

		/*This.props.dispatch({
			type: 'FETCH_CLIMAS_ORGANIZACIONALES',
			payload: {
				dataClimasOrganizacionales: climasOrganizacionales
			}
		})*/

	})
	.then( () =>{
		setTimeout( () => {
			This.setState({
				insertInvitacion: null
			})
		}, 3000);
	})
	.catch(err => handleError(err, This))
	.then(() =>{
		setTimeout( () => {
			This.setState({
				insertInvitacion: null
			})
		}, 3000);
	});

	//termina fetch
}

export default fetchInsertNewEncuesta;