import React from 'react';

//importando Estilos
import './css/EnviaCV.css';

import Navbar from '../../Navbar/Containers/Navbar.jsx';
import Footer from '../../Footer/Components/Footer.jsx';

let DesarrolloOrganizacionalLayOut = props => (
	<div>
		<div id="envia-cv" className="container-fluid">
			<Navbar path={props.path}/>
			<div id="EnviaCVContainer" className="container">
				{props.children}
			</div>
		</div>
		<Footer />
	</div>
)

export default DesarrolloOrganizacionalLayOut