let consulta = (usuarioOEmail, password) => {
  let string = `{ auth {
    logIn( usuarioOEmail: "${usuarioOEmail}" password: "${password}") {
      conexion
      usuario {
        id
        usuario
      }
    }
 } }`

  return {
    "query": string
  }

}

export default consulta;
